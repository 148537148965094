import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class CurrencyAliaManagementService {
    baseModuleUrl = "admin/currency-equivalent";
    POPrefixbaseModuleUrl = "/admin/po-prefix";
    public http: HttpClient
    constructor(_http: HttpClient) {
        this.http = _http
    }

    getAllCurrencyAliasList(body: {},isExport:boolean=false) {
        if(!isExport){
        return this.http.post(environment.baseApiUrl + this.baseModuleUrl + '/list',body );
        }else{
            return this.http.post(environment.baseApiUrl + this.baseModuleUrl + '/list',body,{observe:'response',responseType:'blob'});
        }
    }
    getAllPOPrefixList(body: {},isExport:boolean) {
        if(!isExport){
        return this.http.post(environment.baseApiUrl + this.POPrefixbaseModuleUrl + '/list',body );
        }
        else{
            return this.http.post(environment.baseApiUrl + this.POPrefixbaseModuleUrl + '/list',body,{observe: 'response', responseType: 'blob' } );
        }
    }
    addUpdateVendor(body: {}) {
        return this.http.post(environment.baseApiUrl + this.baseModuleUrl , body);
    }
    getAllCurrencyList(body: {}) {
        return this.http.post(environment.baseApiUrl + 'master/currency' + '/list',{ "orderBy": 'code',
        "orderType": 'asc',
        "page": 1,
        "pageSize": 1000} );
    }
    addCurrencyAlias(body: {}) {
        return this.http.post(environment.baseApiUrl + 'admin/currency-equivalent' ,body );
    }
    getDocStatusActionList(body){
        return this.http.get(environment.baseApiUrl+'master/document-status?page='+ body.page +'&'+ 'page_size='+body.pageSize);
    }
    getDocStatusMasterList(){
        return this.http.get(environment.baseApiUrl+'master/document-status?page='+ 1 +'&'+ 'page_size='+200);
    }
    getDocActionMasterList(){
        return this.http.get(environment.baseApiUrl+'master/action/list?page='+ 1 +'&'+ 'page_size='+200);
    }
    updateCurrencyAlias(body){
        return this.http.post(environment.baseApiUrl + 'admin/currency-equivalent' ,body );
    }
    getZipAliasList(body,isExport:boolean=false){
        if(!isExport){
        return this.http.post(environment.baseApiUrl + 'admin/zip-alias/list' ,body );
        }else{
            return this.http.post(environment.baseApiUrl + 'admin/zip-alias/list' ,body,{observe:'response',responseType:'blob'} );
        }
    }
    addDocAction(body){
        return this.http.post(environment.baseApiUrl + 'admin/status-action/association' ,body );
    }
    addPoPrefix(body){
        return this.http.post(environment.baseApiUrl + 'admin/po-prefix' ,body );
    }
    updatePOPrefix(body){
        return this.http.post(environment.baseApiUrl + 'admin/po-prefix' ,body );
    }
    addZipAlias(body){
        return this.http.post(environment.baseApiUrl + 'admin/zip-alias' ,body );
    }
    updateZipAlias(body){
        return this.http.post(environment.baseApiUrl + 'admin/zip-alias' ,body );
    }
    getErrorCodeList(body,isExport){
        if(!isExport){
        return this.http.post(environment.baseApiUrl + 'admin/error-code/list' ,body );
        }
        else{
            return this.http.post(environment.baseApiUrl + 'admin/error-code/list' ,body,{observe: 'response', responseType: 'blob' });
        }
    }
    getSortErrorCodeList(){
        return this.http.get(environment.baseApiUrl + 'admin/error-code/short-info');
    }
    AddErrorCode(body){
        return this.http.post(environment.baseApiUrl + 'admin/error-code' ,body );
    }

    updateErrorCode(body){
        return this.http.post(environment.baseApiUrl + 'admin/error-code' ,body );
    }
    DeleteErrorCode(id){
        return this.http.delete(environment.baseApiUrl + 'admin/error-code/' + id );
    }
    getLanguageIntentList(body:{},isExport:boolean){
        if(!isExport){
        return this.http.post(environment.baseApiUrl + 'admin/document-identifier/list' ,body );
        }
        else{
            return this.http.post(environment.baseApiUrl + 'admin/document-identifier/list' ,body ,{observe: 'response', responseType: 'blob' });
        }
    }
    getDocumentType(){
        return this.http.get(environment.baseApiUrl + 'master/document-types' );
    }
    addLanguageIntent(body){
        return this.http.post(environment.baseApiUrl + 'admin/document-identifier' ,body );
    }
    updateLanguageIntent(body){        
        return this.http.post(environment.baseApiUrl + 'admin/document-identifier' ,body );
    }

    onDeleteLanguageIntent(id){
        return this.http.delete(environment.baseApiUrl + 'admin/document-identifier/' + id );
    }
    DeleteZipAlias(id){
        return this.http.delete(environment.baseApiUrl + 'admin/zip-alias/' + id );
    }
    DeletePOPrefix(id){
        return this.http.delete(environment.baseApiUrl + 'admin/po-prefix/' + id );
    }
    DeleteCurrencyAlias(id){
        return this.http.delete(environment.baseApiUrl + 'admin/currency-equivalent/' + id );
    }
    getCustomProfileList(body,isExport:boolean){
        if(!isExport){
        return this.http.post(environment.baseApiUrl + 'admin/custom-profile/list',body );
        }
        else{
            return this.http.post(environment.baseApiUrl + 'admin/custom-profile/list',body,{observe: 'response', responseType: 'blob' } );
        }
    }
    updateCustomProfile(body){
        return this.http.post(environment.baseApiUrl + 'admin/custom-profile',body );
    }
    DeleteCustomProfile(id){
        return this.http.delete(environment.baseApiUrl + 'admin/custom-profile/' + id);
    }
    
}
