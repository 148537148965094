import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { PrimengModule } from '../../modules/primeng/primeng.module';
import {AppFooterComponent, AppMainComponent, AppMenuComponent, AppTopBarComponent, AppMenuitemComponent, FilterPanelComponent
} from './component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import {
  AppBreadcrumbService, MenuService, AuthService
} from './service';
import { MessageService } from 'primeng/api';
import { ApiErrorInterceptor, ApiRequestInterceptor, ApiResponseInterceptor, ApiEmptyResponseInterceptor } from './interceptor';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { GridconfigComponent } from './component/gridconfig/gridconfig.component';
import { DatePipe } from './pipe/date.pipe';
import { ChatBotComponent } from './component/chat-bot/chat-bot.component';
import { HighlightDirective } from './lib/highlight.directive';
import { SafeUrlPipe } from './pipe/safeUrl/safe-url.pipe';
import { HighchartsChartModule } from 'highcharts-angular';
import { HighlightPipe } from './pipe/highlight/highlight.pipe';
@NgModule({
  declarations: [ AppFooterComponent, AppMainComponent
    , AppMenuComponent, AppTopBarComponent, AppMenuitemComponent, FilterPanelComponent, GridconfigComponent,HighlightPipe, DatePipe,SafeUrlPipe, ChatBotComponent, HighlightDirective, HighlightPipe
  ],
  imports: [
    PrimengModule,
    CommonModule,
    RouterModule,
    FormsModule,
    FontAwesomeModule,
    ReactiveFormsModule,
    HighchartsChartModule
  ],
  providers: [
    MessageService,
    AppBreadcrumbService, MenuService, AuthService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiRequestInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiResponseInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiErrorInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiEmptyResponseInterceptor,
      multi: true
    }
  ],
  exports: [PrimengModule, FilterPanelComponent,DatePipe,HighlightDirective,HighlightPipe,SafeUrlPipe]
})
export class AppCommonModule { }
