// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `angular-cli.json`.

import { Settings } from "src/app/modules/common/lib/app.constant";

export const environment = {
  production: false,
  isCozentusEnvironment : true,
  sourceEmail:'poc1@cozentus.com',
  environmentType:Settings.EnvironmentType.DEV,
  baseApiUrl:'https://cozentus-cozmos-portal-service-dev.azurewebsites.net/portal/v1/',
  // baseApiUrl:'https://cozentus-cozmos-portal-service-test.azurewebsites.net/portal/v1/',
  cozGptUrl:'https://cozentus-cozmosgpt.azurewebsites.net/api/cozmo-gpt',
  appInsights: {
    instrumentationKey: null
  },
  sso:{
    adClientId:'16a19388-ddd5-4017-a04d-d2f15bf6215d',
    adTenantId : 'cd3779f6-2af9-4aee-be9a-45e131119f0b'
  },
  msalLogInRequest : ['api://16a19388-ddd5-4017-a04d-d2f15bf6215d/api-access']
};
