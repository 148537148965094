import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Router, Event, NavigationEnd, NavigationStart, NavigationCancel } from '@angular/router';
import { LoggingService } from '../appinsight/logging.service';
import { environment } from 'src/environments/environment';
import { Meta, Title } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  private currentNav: INavigationData = null;
  private currentNavPromise = new BehaviorSubject<INavigationData>(this.currentNav);
  public $currentNav = this.currentNavPromise.asObservable();

  constructor(private titleService:Title,private metaService:Meta,private router: Router, private loggingService: LoggingService) {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        let url = event.url;
        if(environment?.appInsights?.instrumentationKey){
        this.loggingService.logPageView(url)
        }
        if (url) {
          const navData: INavigationData = {
            route: url.replace('/', ''),
            routeType: 1
          }
          switch (navData.route) {
            case "user/dashboard": {
              navData.routeType = 0;
              this.titleService.setTitle('Dashboard');
              this.metaService.updateTag({ name: 'description', content: 'To View All Insightful Charts and Reports.' });
              break;
            }
            case "user/document": {
              navData.routeType = 1;
              this.titleService.setTitle('Doc-Tracker');
              this.metaService.updateTag({ name: 'description', content: 'To View All the Documents Received.' });
              break;
            }
            case "user/profile/overview": {
              navData.routeType = 3;
              this.titleService.setTitle('User Personal Profile');
              this.metaService.updateTag({ name: 'description', content: 'To View My Profile.' });
              break;
            }
            case "user/email-tracker": {
              navData.routeType = 4;
              this.titleService.setTitle('E-Tracker');
              this.metaService.updateTag({ name: 'description', content: 'To View All the Mail Logs' });
              break;
            }
            case "user/administration/user-management": {
              this.titleService.setTitle('Users : User Management');
              this.metaService.updateTag({ name: 'description', content: 'To View, Add & Delete User details.' });
              navData.routeType = 6;
              break;
            }
            case "user/administration/role-management": {
              navData.routeType = 7;
              this.titleService.setTitle('Roles: Role Management');
              this.metaService.updateTag({ name: 'description', content: 'To View, Add & Delete Role details.' });
              break;
            }
            case "user/master-data/le-management": {
              this.titleService.setTitle('LE MDM : Legal Entity Master Data');
              this.metaService.updateTag({ name: 'description', content: 'To View, Add & Delete Legal Entity details.' });
              navData.routeType = 8;
              break;
            }
            case "user/master-data/vendor-management": {
              navData.routeType = 9;
              this.titleService.setTitle('Vendor MDM : Vendor Master Data');
              this.metaService.updateTag({ name: 'description', content: 'To View, Add & Delete Vendor details.' });
              break;
            }
            case "user/master-data/vat-alias": {
              navData.routeType = 10;
              this.titleService.setTitle('VAT Alias');
              this.metaService.updateTag({ name: 'description', content: 'To View, Add & Delete VAT Lookup & Replace values.' });
              break;
            }
            case "user/master-data/name-alias": {
              navData.routeType = 11;
              this.titleService.setTitle('Name Alias');
              this.metaService.updateTag({ name: 'description', content: 'To View, Add & Delete Name Lookup & Replace values.' });
              break;
            }
            case "user/master-data/city-alias": {
              navData.routeType = 12;
              this.titleService.setTitle('City Alias');
              this.metaService.updateTag({ name: 'description', content: 'To View, Add & Delete City Lookup & Replace values.' });
              break;
            }
            case "user/administration/email-template": {
              navData.routeType = 13;
              this.titleService.setTitle('Auto Email Template');
              this.metaService.updateTag({ name: 'description', content: 'To View, Add & Delete Auto Email Template.' });
              break;
            }
            case "user/administration/activity-log":{
              navData.routeType=14;
              this.titleService.setTitle('User Activity Logs: User Activity Log');
              this.metaService.updateTag({ name: 'description', content: 'To View User Activity Logs.' });
              break;
            }
            case "user/master-data/ap-invoice-export":{
              navData.routeType=15;
              this.titleService.setTitle('AP Invoices');
              this.metaService.updateTag({ name: 'description', content: 'To View All the Existing AP Invoices.' });
              break;
            }
            case "user/administration/announcement":{
              navData.routeType=16;
              this.titleService.setTitle('Announcement');
              this.metaService.updateTag({ name: 'description', content: 'To View, Add & Delete Announcements details.' });
              break;
            }
            case "user/administration/app-config": {
              navData.routeType = 17;
              this.titleService.setTitle('App Configuration : Application Configuration');
              this.metaService.updateTag({ name: 'description', content: 'To View, Update Application Configuration detail.' });
              break;
            }
            case "user/master-data/po-management": {
              navData.routeType = 51;
              this.titleService.setTitle('PO MDM : Purchase Order Master Data');
              this.metaService.updateTag({ name: 'description', content: 'To View, Add & Delete Purchase Order details.' });
              break;
            }
            case "user/master-data/currency-management": {
              navData.routeType = 52;
              this.titleService.setTitle('Currency MDM : Currency  Master Data');
              this.metaService.updateTag({ name: 'description', content: 'To View, Add & Delete Currency details.' });
              break;
            }
            case "user/master-data/currencyalias-management": {
              navData.routeType = 53;
              this.titleService.setTitle('Currency Alias');
              this.metaService.updateTag({ name: 'description', content: 'To View, Add & Delete Currency Lookup & Replace values.' });
              break;
            }
            case "user/administration/poprefix-management": {
              navData.routeType = 54;
              this.titleService.setTitle('PO Prefix');
              this.metaService.updateTag({ name: 'description', content: 'To View, Add & Delete PO Prefix details.' })
              break;
            }
            case "user/edi/trading-partner-setup": {
              navData.routeType = 62;
              this.titleService.setTitle('Trading Partner Setup');
              this.metaService.updateTag({ name: 'description', content: 'To View, Add & Delete Trading Partner details.' });
              break;
            }
            case "user/edi/code-list": {
              navData.routeType = 64;
              this.titleService.setTitle('Code List Setup');
              this.metaService.updateTag({ name: 'description', content: 'To View, Add & Delete Code List details.' });
              break;
            }
            case "user/connectivity":{
              navData.routeType = 65;
              this.titleService.setTitle('Connectivity');
              this.metaService.updateTag({ name: 'description', content: 'To View, Add & Delete Connectivity details.' });
              break;
            }
            case "user/administration/data-change-logger":{
              navData.routeType = 67;
              this.titleService.setTitle('Data Change Logger');
              this.metaService.updateTag({ name: 'description', content: 'To View Changes in Data Elements.' });
              break;
            }
            case "user/administration/cron-job-logger":{
              navData.routeType = 76;
              this.titleService.setTitle('Cron Job Logger');
              this.metaService.updateTag({ name: 'description', content: 'To View Changes in Cron Job.' });
              break;
            }
            case "user/administration/document-type":{
              navData.routeType = 69;
              this.titleService.setTitle('Document Type Management');
              this.metaService.updateTag({ name: 'description', content: 'To View, Add & Update Document Types.' });
              break;
            }
            case "user/administration/blacklist-email":{
              navData.routeType = 71;
              this.titleService.setTitle('Blacklist Email Config');
              this.metaService.updateTag({ name: 'description', content: 'To View, Add & Update Blacklist Emails.' });
              break;
            }
            case "user/xml":{
              navData.routeType = 72;
              this.titleService.setTitle('E-XML Management');
              this.metaService.updateTag({ name: 'description', content: 'To View, Add & Update XML Setup.' });
              break;
            }
            case "user/administration/manual-email-template":{
              navData.routeType = 74;
              this.titleService.setTitle('Manual Email Template');
              this.metaService.updateTag({ name: 'description', content: 'To View, Add & Update Manual Email Template.' });
              break;
            }
            default: {
              navData.routeType = 10000000;
              this.titleService.setTitle('COZMOS Portal');
              this.metaService.updateTag({ name: 'description', content: 'Welcome to our Portal! Explore our features and services.' });
            }
          }
          if(navData.route.includes("user/document?id")){
            navData.routeType=1;
          }
          if(navData.route.includes("user/document/view")){
            navData.routeType=2;
          }
          if(navData.route.includes("user/edi/rules-setup")){
            navData.routeType = 63;
          }
          if(navData.route.includes("user/administration/emailtemplate-controller")){
              navData.routeType = 68;
              this.titleService.setTitle('Email Template Controller');
              this.metaService.updateTag({ name: 'description', content: 'To View, Add & Delete Email Template Controller.' });
          }
          if (navData.route.includes("user/email-tracker/view")) {
            navData.routeType = 5;
          }
          if (navData.route.includes("user/email-tracker/email-log")) {
            navData.routeType = 70;
            this.titleService.setTitle('Email Logs');
            this.metaService.updateTag({ name: 'description', content: 'To View Email Logs.' });
          }
          if (navData.route.includes("user/master-data/zipcode-alias")) {
            navData.routeType = 57;
            this.titleService.setTitle('Zip Code Alias');
            this.metaService.updateTag({ name: 'description', content: 'To View, Add & Delete Zip Code Lookup & Replace values.' });
          }
          if (navData.route.includes("user/administration/docstatus-management")) {
            navData.routeType = 58;
            this.titleService.setTitle('Document Actions');
            this.metaService.updateTag({ name: 'description', content: 'To View, Add & Delete Document Action details.' });
          }
          if (navData.route.includes("user/administration/errocodelist")) {
            navData.routeType = 59;
            this.titleService.setTitle('Error Code List');
            this.metaService.updateTag({ name: 'description', content: 'To View, Add & Delete Error Codes details.' });
          }
          if (navData.route.includes("user/administration/languageintent-alias")) {
            navData.routeType = 60;
            this.titleService.setTitle('Language Intent');
            this.metaService.updateTag({ name: 'description', content: 'To View, Add & Delete Language Intents details.' });
          }
          if (navData.route.includes("user/administration/customprofile-list")) {
            navData.routeType = 61;
            this.titleService.setTitle('Custom Profile List');
            this.metaService.updateTag({ name: 'description', content: 'To View, Add & Delete Custom Profiles details.' });
          }
          if (navData.route.includes("user/edi/code-list/library/")) {
            navData.routeType = 66;
          }
          if (navData.route.includes("user/xml/xml-rules")) {
            navData.routeType = 73;
          }
          if (navData.route.includes("user/administration/usage-count")) {
            navData.routeType = 75;
          }
          this.currentNav = navData;
          this.currentNavPromise.next(navData);
        }
      }

    });
  }

}

export interface INavigationData {
  route: string;
  routeType: number;
}
