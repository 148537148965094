import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class RoleManagementService {
    baseModuleUrl = "admin/role";
    public http: HttpClient
    constructor(_http: HttpClient) {
        this.http = _http
    }

    getAllRoleList(body: {}) {
        return this.http.post(environment.baseApiUrl + this.baseModuleUrl + '/list', body);
    }
    getAllPrivilegeList(body: {}) {
        return this.http.post(environment.baseApiUrl + 'admin/privilege/list', body);
    }
    addNewRole(body: {}) {
        return this.http.post(environment.baseApiUrl + this.baseModuleUrl, body);
    }
    updateSingleRole(body: {}, roleId) {
        return this.http.put(environment.baseApiUrl + this.baseModuleUrl + '/' + roleId, body);
    }
    deleteSingleRole(roleId) {
        return this.http.delete(environment.baseApiUrl + this.baseModuleUrl + '/' + roleId);
    }
}