import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class CurrencyManagementService {
    baseModuleUrl = "master/currency";
    public http: HttpClient
    constructor(_http: HttpClient) {
        this.http = _http
    }

    getAllCurrencyList(body: {},isExport:boolean=false) {
        if(!isExport){
        return this.http.post(environment.baseApiUrl + this.baseModuleUrl + '/list',body );
        }else{
            return this.http.post(environment.baseApiUrl + this.baseModuleUrl + '/list',body,{observe:'response',responseType:'blob'} );
        }
    }
    addUpdateCurrency(body: {}) {
        return this.http.post(environment.baseApiUrl + this.baseModuleUrl , body);
    }
}