import { Directive, ElementRef, HostBinding, Input, SecurityContext, SimpleChanges } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Directive({
  selector: '[appHighlight]'
})
export class HighlightDirective {
  @Input() value: {} = null;
  @Input("appHighlight") searchTerm: string;
  @Input() caseSensitive = false;
  @Input() customClasses = "";
  public query: string;

  @HostBinding("innerHtml")
  content: string;
  constructor(private sanitizer: DomSanitizer) {}
  ngOnChanges() {
      this.markStringMatches();
  }
  public markStringMatches() { 
      const text = JSON.stringify(this.value,null, 2)
      if (this.searchTerm === "") {
        this.content = text;
      } else {
        let regex = new RegExp(
          this.searchTerm,
          this.caseSensitive ? "g" : "gi"
        );
        let newText = text.replace(regex, (match: string) => {
          return `<mark class="highlight ${this.customClasses}">${match}</mark>`;
        });
  
        const sanitized = this.sanitizer.sanitize(
          SecurityContext.HTML,
          newText
        );
        this.content = sanitized;
      }

  }
  

  ngAfterViewInit() {
      this.markStringMatches();
  }
}

