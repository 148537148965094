<div class="exception-body">
    <div class="exception-bottom">
        <div class="exception-wrapper">
            <svg width="320" height="250" viewBox="0 0 320 250" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path opacity="0.2"
                    d="M303.715 141.613C302.645 128.309 287.85 109.631 272.813 104.498C253.608 97.9096 241.435 108.352 223.856 106.441C176.324 101.268 163.652 67.6704 144.81 37.2234C103.038 -30.259 -12.5188 -1.37112 1.1102 86.7158C9.3085 139.686 65.3932 219.97 149.243 167.311C185.447 144.54 174.457 131.331 212.898 151.776C228.072 159.835 308.575 201.868 303.715 141.613Z"
                    fill="#44B5FA" />
                <path
                    d="M157.015 250C243.167 250 313.008 245.948 313.008 240.949C313.008 235.951 243.167 231.898 157.015 231.898C70.8621 231.898 1.0217 235.951 1.0217 240.949C1.0217 245.948 70.8621 250 157.015 250Z"
                    fill="#F5F5F5" />
                <path
                    d="M66.761 24.7815C60.2763 31.2659 71.2986 41.3323 64.0175 45.9537C69.963 48.9201 90.8007 52.686 96.1992 41.0205C92.265 37.9822 95.0004 30.5143 91.3719 23.3423C85.9413 12.7083 60.2763 17.4176 66.761 24.7815Z"
                    fill="#263238" />
                <path
                    d="M67.3483 24.6144C65.3289 24.2786 58.4983 27.6527 63.583 36.7117C67.6781 44.0356 64.2669 44.5553 64.2669 44.5553C64.2669 44.5553 68.2896 44.6673 65.8759 39.2223C63.3175 33.4095 62.1187 27.3809 67.3483 24.6144Z"
                    fill="#263238" />
                <path
                    d="M115.178 82.1341C114.93 81.6815 114.512 81.3442 114.015 81.1946C113.519 81.0451 112.983 81.0953 112.523 81.3345L112.049 81.5824C111.896 81.6623 111.606 81.8542 111.373 81.9901C111.14 82.1261 110.866 82.278 110.609 82.4059C110.101 82.6596 109.577 82.8787 109.04 83.0616C108.045 83.4196 107 83.6167 105.942 83.6452C104.971 83.6565 104.005 83.5024 103.086 83.1895C102.502 82.9742 101.94 82.7066 101.405 82.3899C100.713 80.8708 100.053 79.3356 99.4898 77.7525C98.8059 75.9055 98.2025 74.0186 97.6715 72.1077C97.1405 70.1967 96.6739 68.2618 96.2877 66.3189C96.0866 65.3514 95.9096 64.376 95.7487 63.4085L95.5314 61.9613C95.459 61.4976 95.3947 60.9699 95.3544 60.6021V60.4422C95.2477 59.3819 94.7216 58.4072 93.8917 57.7324C93.0619 57.0577 91.9963 56.7381 90.9294 56.8442C89.8625 56.9502 88.8817 57.4731 88.2028 58.2978C87.5238 59.1225 87.2023 60.1814 87.309 61.2417C87.3653 61.9213 87.4136 62.4411 87.486 63.0087C87.5584 63.5764 87.6388 64.1201 87.7193 64.6718C87.8963 65.7752 88.0974 66.8626 88.3227 67.95C88.77 70.1107 89.334 72.2459 90.0123 74.3464C90.326 75.4018 90.7122 76.4412 91.0742 77.4887C91.4363 78.5361 91.8788 79.5595 92.3374 80.5749C93.2154 82.6266 94.212 84.6262 95.3223 86.5636L95.4188 86.7395C95.7076 87.2599 96.1403 87.6872 96.6658 87.9708C98.1866 88.7945 99.8297 89.3721 101.533 89.6819C103.283 89.989 105.076 89.951 106.811 89.5699C109.26 89.0055 111.509 87.7925 113.32 86.0599C113.618 85.788 113.899 85.5082 114.124 85.2124C114.35 84.9165 114.623 84.6367 114.929 84.2209C115.165 83.9321 115.313 83.5827 115.358 83.2134C115.402 82.844 115.34 82.4698 115.178 82.1341Z"
                    fill="#FF8B7B" />
                <path
                    d="M120.529 74.6496H120.295C119.525 74.6333 118.762 74.7936 118.064 75.1181C117.366 75.4425 116.753 75.9224 116.273 76.5205L112.387 81.3658V84.676C115.508 85.9073 119.467 82.5891 119.467 82.5891L121.47 82.0854C122.144 81.9182 122.732 81.5085 123.12 80.9355C123.508 80.3624 123.668 79.6668 123.57 78.9831L123.28 77.0402C123.186 76.3803 122.856 75.7759 122.351 75.3372C121.846 74.8984 121.199 74.6544 120.529 74.6496Z"
                    fill="#FF8B7B" />
                <path
                    d="M95.2498 55.964C97.8485 58.6185 98.0094 66.5421 98.0094 66.5421L87.6147 74.8575C87.6147 74.8575 83.3426 63.104 85.7079 58.9544C87.7112 55.4363 91.9351 52.5819 95.2498 55.964Z"
                    fill="#EF9517" />
                <g opacity="0.2">
                    <path opacity="0.2"
                        d="M95.2498 55.964C97.8485 58.6185 98.0094 66.5421 98.0094 66.5421L87.6147 74.8575C87.6147 74.8575 83.3426 63.104 85.7079 58.9544C87.7112 55.4363 91.9351 52.5819 95.2498 55.964Z"
                        fill="white" />
                </g>
                <path d="M78.0487 234.097L83.7931 234.72L84.5816 219.209L78.8372 218.577L78.0487 234.097Z"
                    fill="#FF8B7B" />
                <path
                    d="M84.7344 230.994H77.9843C77.8483 230.99 77.7158 231.038 77.6152 231.13C77.5145 231.221 77.4536 231.347 77.4453 231.482L76.9947 237.823C76.9946 238.154 77.125 238.473 77.358 238.71C77.5909 238.948 77.9081 239.086 78.2418 239.094C81.0898 239.094 81.122 239.094 84.6781 239.094H94.2602C97.2049 239.094 97.2129 236.128 95.9417 235.856C90.2214 234.656 87.9928 232.993 85.9734 231.41C85.6186 231.138 85.1825 230.992 84.7344 230.994Z"
                    fill="#263238" />
                <path opacity="0.2" d="M84.5816 219.217L84.1713 227.212H78.3947L78.8372 218.585L84.5816 219.217Z"
                    fill="black" />
                <path
                    d="M87.6791 95.4541C87.6791 95.4541 92.6512 136.815 92.6512 155.205C88.733 180.791 85.4103 225.694 85.4103 225.694L77.4614 225.486C77.4614 225.486 73.849 176.585 77.1959 155.469C72.9076 136.551 67.6138 92.8955 67.6138 92.8955L87.6791 95.4541Z"
                    fill="#002B45" />
                <path opacity="0.2"
                    d="M72.3525 106.968C71.4051 107.382 70.486 107.857 69.601 108.391C70.6952 116.554 72.1353 126.829 73.6639 136.311C76.2706 128.508 74.8627 111.349 72.3525 106.968Z"
                    fill="black" />
                <path d="M15.8414 219.697L20.5801 222.983L28.7543 209.742L24.0155 206.456L15.8414 219.697Z"
                    fill="#FF8B7B" />
                <path
                    d="M21.7789 221.248L17.7562 215.867C17.6796 215.757 17.5625 215.681 17.4301 215.656C17.2977 215.63 17.1606 215.658 17.0482 215.731L11.6578 219.17C11.3888 219.366 11.2086 219.66 11.1559 219.988C11.1032 220.315 11.1823 220.65 11.3762 220.921C13.0577 223.199 13.0898 223.215 15.2058 226.046C16.5172 227.781 19.132 231.275 20.9341 233.681C22.7363 236.088 25.0936 234.265 24.5465 233.082C22.1329 227.813 22.1329 225.086 22.1812 222.496C22.1894 222.047 22.0479 221.609 21.7789 221.248Z"
                    fill="#263238" />
                <path opacity="0.2" d="M28.7463 209.742L24.5385 216.57L19.4618 213.82L24.0155 206.456L28.7463 209.742Z"
                    fill="black" />
                <path
                    d="M44.9095 225.246C40.7983 225.246 32.238 225.246 32.0932 219.233C31.9805 214.516 37.8537 212.557 42.9223 212.317C43.4839 212.29 44.0453 212.373 44.5745 212.562C45.1036 212.751 45.5902 213.041 46.0063 213.417C46.4225 213.793 46.7601 214.246 46.9998 214.752C47.2396 215.257 47.3768 215.804 47.4036 216.363V216.682C53.1481 216.411 56.8651 215.163 58.2247 213.036C61.29 208.239 55.4169 195.854 49.7448 183.885C43.0671 169.804 35.5044 153.853 35.3596 138.574C35.3543 138.015 35.4598 137.461 35.67 136.943C35.8802 136.425 36.191 135.953 36.5847 135.554C36.9785 135.156 37.4473 134.838 37.9646 134.619C38.4819 134.401 39.0375 134.285 39.5995 134.28C40.7271 134.282 41.8086 134.726 42.6097 135.514C43.4107 136.303 43.867 137.373 43.8797 138.494C44.0084 151.91 51.1206 166.966 57.4443 180.255C64.6852 195.59 71.0009 208.839 65.4093 217.594C62.167 222.743 55.4491 225.246 44.9095 225.246Z"
                    fill="#0976CB" />
                <path
                    d="M81.3151 94.6394C81.3151 94.6394 67.5896 141.909 60.8878 159.051C47.9024 181.487 26.3568 215.82 26.3568 215.82L19.4779 211.822C19.4779 211.822 35.5688 172.164 46.3899 153.694C49.2943 134.505 60.2763 91.9209 60.2763 91.9209L81.3151 94.6394Z"
                    fill="#002B45" />
                <path
                    d="M23.0179 225.11C23.2909 225.559 23.7115 225.901 24.2086 226.078C24.3542 226.113 24.5066 226.106 24.6487 226.06C24.7908 226.013 24.917 225.928 25.0132 225.814C25.0801 225.753 25.1311 225.677 25.1619 225.592C25.1928 225.507 25.2024 225.416 25.1902 225.326C25.0936 224.367 22.5995 223.119 22.1007 222.88C22.0716 222.868 22.0392 222.867 22.0096 222.877C21.98 222.888 21.9552 222.908 21.9398 222.936C21.9204 222.965 21.9101 223 21.9101 223.035C21.9101 223.071 21.9204 223.106 21.9398 223.135C22.2353 223.826 22.5962 224.488 23.0179 225.11ZM24.7316 225.11C24.8024 225.198 24.8495 225.303 24.8684 225.414C24.8722 225.445 24.8684 225.477 24.8572 225.506C24.846 225.535 24.8277 225.561 24.804 225.582C24.7391 225.655 24.6557 225.71 24.5624 225.741C24.4691 225.772 24.3692 225.778 24.273 225.758C23.742 225.654 23.042 224.798 22.3662 223.447C23.2425 223.853 24.0424 224.405 24.7316 225.078V225.11Z"
                    fill="#0976CB" />
                <path
                    d="M21.8996 223.168L21.9479 223.208C22.7524 223.648 24.8442 224.008 25.3028 223.344C25.3798 223.222 25.4172 223.08 25.41 222.937C25.4028 222.794 25.3513 222.657 25.2626 222.544C24.458 221.128 22.0444 222.792 21.9398 222.864C21.9158 222.883 21.8967 222.907 21.8842 222.935C21.8716 222.963 21.8658 222.993 21.8674 223.024C21.8642 223.074 21.8753 223.124 21.8996 223.168ZM24.9569 222.704C24.9803 222.731 25.0018 222.761 25.0212 222.792C25.1097 222.944 25.1258 223.056 25.0775 223.12C24.812 223.504 23.2673 223.368 22.3662 223.016C22.9696 222.656 24.3776 221.928 24.9569 222.704Z"
                    fill="#0976CB" />
                <path
                    d="M88.6284 232.315C89.1558 232.362 89.6837 232.23 90.1249 231.939C90.2367 231.841 90.3193 231.715 90.3633 231.574C90.4073 231.432 90.4111 231.282 90.3743 231.139C90.3628 231.048 90.3307 230.962 90.2805 230.885C90.2304 230.809 90.1634 230.745 90.0847 230.699C89.2801 230.211 86.7619 231.451 86.2711 231.707C86.2435 231.722 86.2219 231.746 86.2102 231.775C86.1985 231.804 86.1973 231.837 86.2068 231.867C86.219 231.9 86.2394 231.93 86.266 231.954C86.2927 231.977 86.3248 231.994 86.3596 232.003C87.1032 232.183 87.8634 232.288 88.6284 232.315ZM89.6261 230.931C89.7406 230.923 89.855 230.948 89.9559 231.003C89.9962 231.003 90.0364 231.059 90.0525 231.155C90.0735 231.25 90.0682 231.35 90.037 231.443C90.0059 231.536 89.9501 231.619 89.8755 231.683C89.4813 232.043 88.371 232.091 86.8745 231.827C87.7201 231.376 88.6372 231.073 89.5858 230.931H89.6261Z"
                    fill="#0976CB" />
                <path
                    d="M86.3596 232.042H86.4159C87.2205 231.674 88.7893 230.211 88.5319 229.452C88.4782 229.326 88.3886 229.219 88.2742 229.144C88.1597 229.068 88.0255 229.028 87.8882 229.028C86.2792 228.844 86.1665 231.738 86.1585 231.858C86.1587 231.89 86.1669 231.922 86.1824 231.949C86.1978 231.977 86.22 232.001 86.247 232.018C86.2812 232.037 86.3205 232.046 86.3596 232.042ZM87.8078 229.324H87.9204C88.0894 229.324 88.194 229.396 88.2181 229.476C88.371 229.915 87.3412 231.075 86.5125 231.579C86.5849 230.883 86.8423 229.324 87.8239 229.324H87.8078Z"
                    fill="#0976CB" />
                <path
                    d="M60.2763 91.9606L87.6791 95.4466C93.858 74.7701 95.8935 64.7837 96.4406 59.9784C96.5853 58.7706 96.3012 57.5504 95.6373 56.5286C94.9734 55.5068 93.9713 54.7474 92.804 54.3815C91.1949 53.8698 89.1192 53.2621 87.0596 52.7824C86.3596 52.5985 85.6436 52.4306 84.9436 52.2707C80.8412 51.4435 76.6845 50.9091 72.5054 50.6716C71.8859 50.6156 71.2744 50.5836 70.6549 50.5516C69.4401 50.4877 68.2413 50.4717 67.1471 50.4717C66.3259 50.4707 65.5134 50.6397 64.7616 50.968C64.0097 51.2963 63.3349 51.7766 62.7801 52.3783C62.2253 52.9801 61.8027 53.6901 61.5392 54.4631C61.2758 55.2361 61.1772 56.0552 61.2498 56.8681C61.4671 59.2668 61.636 61.4975 61.7647 63.5444C62.0705 68.3417 62.167 72.1796 62.0946 75.5377C61.9301 81.0514 61.3221 86.5432 60.2763 91.9606Z"
                    fill="#ED8B00" />
                <g opacity="0.3">
                    <path opacity="0.3"
                        d="M60.2763 91.9606L87.6791 95.4466C93.858 74.7701 95.8935 64.7837 96.4406 59.9784C96.5853 58.7706 96.3012 57.5504 95.6373 56.5286C94.9734 55.5068 93.9713 54.7474 92.804 54.3815C91.1949 53.8698 89.1192 53.2621 87.0596 52.7824C86.3596 52.5985 85.6436 52.4306 84.9436 52.2707C80.8412 51.4435 76.6845 50.9091 72.5054 50.6716C71.8859 50.6156 71.2744 50.5836 70.6549 50.5516C69.4401 50.4877 68.2413 50.4717 67.1471 50.4717C66.3259 50.4707 65.5134 50.6397 64.7616 50.968C64.0097 51.2963 63.3349 51.7766 62.7801 52.3783C62.2253 52.9801 61.8027 53.6901 61.5392 54.4631C61.2758 55.2361 61.1772 56.0552 61.2498 56.8681C61.4671 59.2668 61.636 61.4975 61.7647 63.5444C62.0705 68.3417 62.167 72.1796 62.0946 75.5377C61.9301 81.0514 61.3221 86.5432 60.2763 91.9606Z"
                        fill="white" />
                </g>
                <path opacity="0.3"
                    d="M62.0785 75.5056C65.6989 70.8522 67.0104 65.5271 65.8357 62.5928L61.7486 63.5043C62.0785 68.2856 62.167 72.1395 62.0785 75.5056Z"
                    fill="#2D74FF" />
                <path
                    d="M87.0596 52.7336C86.3516 52.5497 85.6436 52.3818 84.9436 52.2219C80.8379 51.3986 76.6788 50.8642 72.4973 50.6228C71.8778 50.5748 71.2664 50.5428 70.6549 50.5109L69.5849 50.4629L70.3894 51.8621C70.5021 52.054 73.2375 56.4995 80.044 61.2809L81.5002 62.2883L82.9001 61.2249C84.2482 60.2638 85.3498 59.0008 86.1157 57.5382C86.8817 56.0756 87.2904 54.4543 87.309 52.8056L87.0596 52.7336Z"
                    fill="white" />
                <path
                    d="M75.5224 42.4039C75.265 45.77 74.4685 49.2001 72.4973 50.6393C72.4973 50.6393 75.0075 54.789 81.5082 59.3225C85.5792 56.2282 84.9436 52.2224 84.9436 52.2224C80.6152 50.6233 80.9209 47.641 82.7472 42.8836L79.3681 41.0766C79.0766 40.7573 78.697 40.5303 78.2767 40.4237C77.8563 40.3171 77.4137 40.3357 77.0039 40.4772C76.5941 40.6186 76.2352 40.8766 75.9718 41.2192C75.7084 41.5618 75.5521 41.9737 75.5224 42.4039Z"
                    fill="#FF8B7B" />
                <path opacity="0.2"
                    d="M79.3682 41.0767C79.0944 40.7761 78.7423 40.5566 78.3508 40.4425C77.9593 40.3283 77.5437 40.3239 77.1499 40.4299C76.7561 40.5358 76.3994 40.7478 76.1193 41.0426C75.8392 41.3374 75.6465 41.7033 75.5627 42.1001C76.8462 44.6721 78.9163 46.7741 81.476 48.1048C81.5677 46.3016 81.996 44.5312 82.7392 42.8837L79.3682 41.0767Z"
                    fill="black" />
                <path
                    d="M70.2607 31.8493C71.9422 38.3497 72.5134 41.1321 76.512 43.8426C82.5219 47.9523 90.1892 44.4823 90.4869 37.6221C90.7685 31.4415 87.9767 21.8469 80.9933 20.4716C79.4517 20.1584 77.855 20.2429 76.3555 20.717C74.8561 21.191 73.5038 22.0389 72.4276 23.1798C71.3515 24.3206 70.5873 25.7164 70.2079 27.2341C69.8286 28.7517 69.8468 30.3406 70.2607 31.8493Z"
                    fill="#FF8B7B" />
                <path
                    d="M86.9067 21.815C82.9673 25.2952 78.294 27.8551 73.2295 29.3068C73.2295 29.3068 78.2257 39.869 68.8769 37.2064C66.1656 28.2114 68.8769 23.5421 71.2905 21.3433C78.7245 18.9446 83.9541 18.1451 86.9067 21.815Z"
                    fill="#263238" />
                <path
                    d="M69.9469 38.2542C70.4951 39.4319 71.4902 40.346 72.7146 40.7967C74.3237 41.3724 75.2811 39.9972 74.9351 38.3981C74.6213 36.9669 73.3984 34.936 71.7169 35.04C71.3532 35.0708 71.0029 35.1906 70.6971 35.3887C70.3913 35.5868 70.1395 35.857 69.9643 36.1752C69.7891 36.4934 69.6958 36.8498 69.6927 37.2125C69.6897 37.5752 69.777 37.9331 69.9469 38.2542Z"
                    fill="#FF8B7B" />
                <path
                    d="M60.0752 90.3137L58.5224 92.5925C58.4017 92.7684 58.6029 92.9923 58.9166 93.0322L87.6549 96.6942C87.9043 96.6942 88.1216 96.6302 88.1618 96.4783L88.725 94.0796C88.725 93.9117 88.5641 93.7438 88.2986 93.7039L60.5418 90.1698C60.4582 90.1504 60.3709 90.1535 60.2888 90.1788C60.2068 90.2041 60.133 90.2507 60.0752 90.3137Z"
                    fill="#0976CB" />
                <path
                    d="M63.398 93.8706L62.6578 93.7746C62.513 93.7746 62.4084 93.6627 62.4325 93.5747L63.1888 90.4485C63.2175 90.3958 63.2635 90.3545 63.3191 90.3312C63.3746 90.308 63.4365 90.3042 63.4945 90.3206L64.2427 90.4165C64.3875 90.4165 64.4921 90.5204 64.468 90.6164L63.7117 93.7346C63.6876 93.8306 63.5508 93.8866 63.398 93.8706Z"
                    fill="#263238" />
                <path
                    d="M85.5309 96.6863L84.7908 96.5983C84.6459 96.5983 84.5413 96.4864 84.5655 96.3904L85.3218 93.2722C85.3218 93.1842 85.4827 93.1203 85.6275 93.1362L86.3757 93.2322C86.5205 93.2322 86.6251 93.3441 86.601 93.4321L85.8447 96.5583C85.8206 96.6463 85.6838 96.7103 85.5309 96.6863Z"
                    fill="#263238" />
                <path
                    d="M74.4685 95.2793L73.7283 95.1833C73.5754 95.1833 73.4708 95.0794 73.495 94.9834L74.2513 91.8652C74.2513 91.7692 74.4122 91.7133 74.565 91.7293L75.3052 91.8252C75.45 91.8252 75.5546 91.9372 75.5385 92.0251L74.7742 95.1513C74.7581 95.2393 74.6133 95.2953 74.4685 95.2793Z"
                    fill="#263238" />
                <path
                    d="M81.468 31.5707C81.5645 32.1064 81.9346 32.4902 82.2725 32.4182C82.6105 32.3462 82.8277 31.8665 82.7231 31.3308C82.6185 30.7951 82.2484 30.4113 81.9185 30.4833C81.5887 30.5552 81.3875 31.035 81.468 31.5707Z"
                    fill="#263238" />
                <path
                    d="M87.5504 30.4101C87.655 30.9458 88.025 31.3216 88.3549 31.2576C88.6848 31.1936 88.91 30.6979 88.8054 30.1622C88.7009 29.6265 88.3308 29.2507 88.0009 29.3147C87.671 29.3787 87.4538 29.8744 87.5504 30.4101Z"
                    fill="#263238" />
                <path d="M87.8239 29.3799L89.0468 28.7559C89.0468 28.7559 88.6284 29.8919 87.8239 29.3799Z"
                    fill="#263238" />
                <path
                    d="M85.9413 31.6104C86.6602 32.7367 87.5289 33.7614 88.5238 34.6566C88.2881 34.9056 88.0001 35.0998 87.6803 35.2256C87.3605 35.3513 87.0167 35.4055 86.6734 35.3842L85.9413 31.6104Z"
                    fill="#FF5652" />
                <path
                    d="M82.1438 37.5434C82.1196 37.5713 82.1058 37.6068 82.1048 37.6437C82.1038 37.6806 82.1157 37.7167 82.1384 37.7459C82.1611 37.7751 82.1933 37.7956 82.2295 37.8039C82.2657 37.8122 82.3036 37.8077 82.3369 37.7914C82.9448 37.483 83.6176 37.3223 84.3 37.3223C84.9824 37.3223 85.6552 37.483 86.2631 37.7914C86.2995 37.812 86.3426 37.8177 86.3831 37.8072C86.4237 37.7968 86.4586 37.771 86.4803 37.7354C86.5023 37.6989 86.5095 37.6555 86.5005 37.6139C86.4916 37.5724 86.4671 37.5357 86.432 37.5114C85.7759 37.1772 85.0493 37.0029 84.3121 37.0029C83.5749 37.0029 82.8482 37.1772 82.1921 37.5114C82.1921 37.5114 82.1921 37.5274 82.1438 37.5434Z"
                    fill="#263238" />
                <path
                    d="M79.3681 29.9232C79.4178 29.8928 79.457 29.8482 79.4808 29.7953C79.6484 29.4309 79.9007 29.1112 80.2167 28.8624C80.5328 28.6136 80.9037 28.4428 81.299 28.3641C81.3414 28.3591 81.3824 28.3459 81.4196 28.3251C81.4568 28.3042 81.4894 28.2763 81.5157 28.2428C81.5419 28.2093 81.5611 28.171 81.5723 28.13C81.5834 28.0891 81.5863 28.0463 81.5806 28.0043C81.5767 27.9625 81.5643 27.9219 81.5442 27.885C81.524 27.8481 81.4966 27.8156 81.4636 27.7895C81.4305 27.7633 81.3925 27.7441 81.3518 27.7329C81.3111 27.7218 81.2685 27.7189 81.2266 27.7244C80.7237 27.8178 80.2505 28.029 79.8461 28.3405C79.4417 28.6519 79.1179 29.0547 78.9015 29.5154C78.8703 29.5785 78.8608 29.6501 78.8746 29.719C78.8883 29.788 78.9246 29.8505 78.9777 29.8969C79.0308 29.9433 79.0979 29.971 79.1684 29.9758C79.239 29.9806 79.3092 29.9621 79.3681 29.9232Z"
                    fill="#263238" />
                <path
                    d="M89.3606 25.87L89.4008 25.83C89.4254 25.7954 89.4427 25.7563 89.452 25.715C89.4612 25.6737 89.4621 25.6309 89.4546 25.5893C89.4472 25.5476 89.4314 25.5078 89.4084 25.4722C89.3853 25.4366 89.3554 25.406 89.3204 25.382C88.9045 25.0847 88.4233 24.8904 87.9167 24.8153C87.4102 24.7402 86.8929 24.7863 86.4079 24.95C86.3304 24.9835 86.2688 25.0454 86.2359 25.1228C86.203 25.2002 86.2012 25.2873 86.2309 25.366C86.263 25.4444 86.3251 25.5069 86.4035 25.5399C86.4819 25.5729 86.5703 25.5736 86.6493 25.542C87.0345 25.4164 87.4444 25.3843 87.8447 25.4483C88.245 25.5124 88.624 25.6706 88.9503 25.91C89.012 25.9537 89.0875 25.9737 89.163 25.9664C89.2384 25.959 89.3086 25.9248 89.3606 25.87Z"
                    fill="#263238" />
                <path d="M81.7657 30.5389L82.9886 29.9229C82.9886 29.9229 82.5139 31.0508 81.7657 30.5389Z"
                    fill="#263238" />
                <path
                    d="M39.6398 142.788C38.5115 142.788 37.4289 142.345 36.6274 141.556C35.8258 140.767 35.3702 139.695 35.3596 138.574C35.247 127.22 39.0525 117.634 46.3658 110.854C55.3686 102.506 69.4079 98.6283 85.9171 99.9236C97.4945 100.835 104.92 98.0367 113.513 94.7825C114.929 94.2548 116.353 93.7111 117.817 93.1834C118.884 92.7974 120.062 92.8485 121.091 93.3253C122.12 93.8021 122.916 94.6657 123.304 95.726C123.693 96.7862 123.641 97.9564 123.161 98.979C122.682 100.002 121.813 100.793 120.746 101.179C119.322 101.691 117.938 102.218 116.562 102.738C107.801 106.048 98.7496 109.462 85.2413 108.407C71.1618 107.296 59.4316 110.374 52.2068 117.074C46.5749 122.255 43.8315 129.459 43.92 138.494C43.9253 139.053 43.8198 139.607 43.6096 140.125C43.3994 140.643 43.0885 141.115 42.6948 141.514C42.3011 141.912 41.8322 142.23 41.3149 142.449C40.7976 142.667 40.2421 142.782 39.68 142.788H39.6398Z"
                    fill="#0976CB" />
                <path
                    d="M138.627 107.186L121.41 113.454C119.974 113.977 118.387 113.911 116.999 113.271C115.611 112.632 114.536 111.47 114.009 110.043L106.9 90.7556C106.373 89.328 106.439 87.7511 107.083 86.3717C107.727 84.9924 108.895 83.9237 110.332 83.4007L127.548 77.1326L138.619 107.163L138.627 107.186Z"
                    fill="#2D74FF" />
                <path opacity="0.7"
                    d="M138.627 107.186L121.41 113.454C119.974 113.977 118.387 113.911 116.999 113.271C115.611 112.632 114.536 111.47 114.009 110.043L106.9 90.7556C106.373 89.328 106.439 87.7511 107.083 86.3717C107.727 84.9924 108.895 83.9237 110.332 83.4007L127.548 77.1326L138.619 107.163L138.627 107.186Z"
                    fill="white" />
                <path opacity="0.2"
                    d="M119.877 79.9193L127.56 77.1289L138.631 107.176L130.947 109.975C130.436 109.483 130.045 108.881 129.805 108.216L120.15 81.9982C119.898 81.3358 119.804 80.624 119.877 79.9193Z"
                    fill="#2D74FF" />
                <path d="M144.815 84.6136L144.059 84.8887L145.902 89.8887L146.658 89.6137L144.815 84.6136Z"
                    fill="#2D74FF" />
                <path
                    d="M142.895 81.7739L140.184 74.402C139.718 73.152 138.879 72.0735 137.779 71.3116C136.679 70.5497 135.37 70.141 134.029 70.1404C133.266 70.1382 132.508 70.2709 131.792 70.5322L126.901 72.3152L126.619 71.5636L131.519 69.7806C132.323 69.4877 133.173 69.3388 134.029 69.3408C135.536 69.3444 137.005 69.8059 138.241 70.6634C139.476 71.5209 140.418 72.7334 140.94 74.1382L143.651 81.5101L142.895 81.7739Z"
                    fill="#2D74FF" />
                <path
                    d="M138.761 109.339L145.945 106.756C148.292 105.913 149.507 103.337 148.658 101.005L138.906 74.2108C138.057 71.878 135.466 70.6709 133.119 71.5147L125.934 74.0972C123.587 74.941 122.372 77.5161 123.221 79.8489L132.973 106.643C133.822 108.976 136.413 110.183 138.761 109.339Z"
                    fill="#0976CB" />
                <path
                    d="M134.648 82.3414C134.812 82.7872 134.84 83.2714 134.727 83.7326C134.614 84.1939 134.366 84.6114 134.014 84.9324C133.662 85.2534 133.222 85.4634 132.75 85.5358C132.278 85.6083 131.795 85.5399 131.362 85.3393C130.929 85.1388 130.566 84.8151 130.318 84.4092C130.07 84.0033 129.949 83.5335 129.97 83.0593C129.991 82.5851 130.154 82.1277 130.436 81.7451C130.719 81.3626 131.11 81.072 131.559 80.9102C132.16 80.6938 132.822 80.7229 133.401 80.9912C133.98 81.2595 134.429 81.745 134.648 82.3414Z"
                    fill="#263238" />
                <path
                    d="M140.658 98.6448C140.822 99.0904 140.848 99.5741 140.735 100.035C140.622 100.496 140.374 100.913 140.023 101.233C139.671 101.554 139.232 101.764 138.76 101.836C138.288 101.909 137.806 101.841 137.373 101.641C136.94 101.441 136.577 101.118 136.329 100.713C136.081 100.308 135.959 99.8388 135.979 99.365C135.999 98.8912 136.16 98.4339 136.442 98.051C136.723 97.668 137.113 97.3766 137.561 97.2136C137.859 97.1052 138.175 97.0562 138.492 97.0695C138.809 97.0828 139.12 97.158 139.408 97.291C139.696 97.4239 139.954 97.6119 140.169 97.8442C140.383 98.0765 140.55 98.3485 140.658 98.6448Z"
                    fill="#263238" />
                <path
                    d="M70.1239 96.7177L70.0596 96.6458C68.8045 95.3985 67.477 94.0392 66.2782 92.648C65.0795 91.2568 63.9048 89.8655 62.8348 88.4263C61.7857 87.0223 60.8315 85.5505 59.9786 84.0208C59.3683 82.9267 58.849 81.7849 58.4259 80.6067C58.9086 79.0076 59.4235 77.4085 60.035 75.8733C60.7349 74.0264 61.5234 72.2114 62.3842 70.4204C63.2451 68.6294 64.1623 66.8704 65.1519 65.1513C65.6346 64.2878 66.1414 63.4323 66.6644 62.5927L67.4689 61.3614C67.7184 60.9617 68.016 60.5619 68.2252 60.2181L68.3137 60.0901C68.8856 59.2238 69.0967 58.1706 68.9026 57.1526C68.7084 56.1346 68.124 55.2313 67.2727 54.633C66.4215 54.0348 65.3697 53.7884 64.3392 53.9458C63.3087 54.1032 62.3799 54.6521 61.7486 55.4767C61.3464 56.0204 61.0406 56.4522 60.7188 56.9239C60.397 57.3956 60.1074 57.8674 59.8016 58.3391C59.2143 59.2826 58.6431 60.2341 58.096 61.2015C57.0076 63.1316 56.022 65.1173 55.1433 67.1502C54.6928 68.1576 54.3388 69.1891 53.8802 70.2205C53.4216 71.2519 53.1159 72.2993 52.7941 73.3707C52.1112 75.4936 51.5498 77.6532 51.1126 79.8391V80.023C50.9877 80.6065 51.0295 81.213 51.2333 81.7741C52.0128 83.8241 53.048 85.7688 54.3147 87.5628C55.5074 89.2656 56.8366 90.8699 58.2891 92.3601C59.7041 93.7958 61.206 95.1443 62.7865 96.3979C64.3663 97.6496 66.0243 98.8006 67.7505 99.844C68.1697 100.088 68.6639 100.171 69.1404 100.078C69.617 99.9859 70.0433 99.7238 70.3393 99.3413C70.6354 98.9588 70.7809 98.4821 70.7487 98.0006C70.7164 97.5191 70.5086 97.0658 70.1642 96.7257L70.1239 96.7177Z"
                    fill="#FF8B7B" />
                <path
                    d="M79.4727 100.692L79.3682 100.484C79.0342 99.7911 78.5427 99.1848 77.9325 98.713C77.3224 98.2412 76.6102 97.9169 75.8523 97.7655L69.7458 96.5342L66.761 98.0134C67.0828 101.339 71.8618 103.33 71.8618 103.33L73.2214 104.874C73.6799 105.391 74.3144 105.722 75.0033 105.803C75.6922 105.884 76.387 105.71 76.9545 105.313L78.5636 104.17C79.1044 103.791 79.4925 103.233 79.6589 102.596C79.8253 101.96 79.7594 101.285 79.4727 100.692Z"
                    fill="#FF8B7B" />
                <path
                    d="M64.645 50.9828C61.2579 52.5259 57.6535 57.8669 57.6535 57.8669L64.8944 71.3394C64.8944 71.3394 73.1249 61.9127 72.433 57.1953C71.8698 53.1895 68.9574 49.0159 64.645 50.9828Z"
                    fill="#EF9517" />
                <g opacity="0.3">
                    <path opacity="0.3"
                        d="M64.645 50.9828C61.2579 52.5259 57.6535 57.8669 57.6535 57.8669L64.8944 71.3394C64.8944 71.3394 73.1249 61.9127 72.433 57.1953C71.8698 53.1895 68.9574 49.0159 64.645 50.9828Z"
                        fill="white" />
                </g>
                <path
                    d="M296.434 227.509C289.346 227.509 282.379 226.061 279 221.68C263.005 200.891 263.15 159.075 263.287 118.609C263.367 96.8215 265.097 76.233 262.659 60.2419C261.227 50.8151 256.223 44.3467 251.17 41.0046C243.672 36.0793 233.374 38.7259 228.973 39.8532C228.425 40.0054 227.852 40.0475 227.287 39.9768C226.722 39.9062 226.177 39.7244 225.684 39.4419C225.191 39.1595 224.759 38.7821 224.415 38.3319C224.07 37.8818 223.819 37.3678 223.677 36.8201C223.535 36.2724 223.504 35.702 223.586 35.1423C223.668 34.5826 223.862 34.0448 224.156 33.5604C224.45 33.0759 224.838 32.6546 225.297 32.3211C225.757 31.9875 226.279 31.7484 226.833 31.6178C232.328 30.2026 245.217 26.9004 255.885 33.9045C263.086 38.6299 269.313 47.0652 271.123 58.9706C273.657 75.6013 271.928 96.4937 271.847 118.609C271.71 157.652 271.574 198.021 285.798 216.483C286.948 217.97 291.43 219.305 298.872 218.921C299.532 218.921 300.175 218.849 300.811 218.794C300.523 218.214 300.374 217.577 300.376 216.931C300.376 215.802 300.827 214.72 301.63 213.923C302.433 213.125 303.521 212.677 304.656 212.677C314.013 212.677 318.937 213.476 319.886 217.73C320.16 218.953 320.305 222.024 315.783 224.127C312.404 225.702 304.343 227.509 296.434 227.509Z"
                    fill="#0976CB" />
                <path d="M202.769 231.179L194.04 227.765L198.642 212.726L207.379 216.132L202.769 231.179Z"
                    fill="#FFC3BD" />
                <path
                    d="M193.831 226.845L203.678 230.683C203.803 230.734 203.907 230.824 203.974 230.94C204.041 231.056 204.067 231.19 204.048 231.323L203.083 237.415C203.058 237.563 203 237.702 202.913 237.825C202.826 237.947 202.713 238.048 202.582 238.121C202.451 238.193 202.305 238.236 202.155 238.246C202.005 238.255 201.855 238.231 201.715 238.175C199.245 237.16 195.375 235.48 192.246 234.257C190.323 233.506 186.662 232.306 183.999 231.267C181.336 230.227 182.028 227.477 183.195 227.669C188.496 228.516 190.524 227.613 192.64 226.829C193.024 226.683 193.45 226.689 193.831 226.845Z"
                    fill="#263238" />
                <path opacity="0.2" d="M207.379 216.14L198.642 212.726L196.268 220.481L205.006 223.895L207.379 216.14Z"
                    fill="black" />
                <path
                    d="M218.297 99.7236C218.297 99.7236 210.396 138.534 207.379 157.675C203.984 179.127 195.013 218.314 195.013 218.314L206.623 222.847C206.623 222.847 223.204 182.725 225.514 163.296C232.473 147.505 246.311 101.035 246.311 101.035L218.297 99.7236Z"
                    fill="#002B45" />
                <path d="M194.45 218.378L207.194 223.359L209.374 219.521L195.126 213.652L194.45 218.378Z"
                    fill="#263238" />
                <path
                    d="M191.328 227.014C190.884 226.876 190.499 226.595 190.234 226.215C190.161 226.074 190.123 225.917 190.123 225.759C190.123 225.6 190.161 225.444 190.234 225.303C190.267 225.216 190.323 225.14 190.397 225.084C190.471 225.027 190.56 224.992 190.653 224.983C191.497 224.895 193.066 226.942 193.219 227.182C193.236 227.208 193.246 227.239 193.246 227.27C193.246 227.301 193.236 227.332 193.219 227.358C193.202 227.384 193.178 227.405 193.149 227.418C193.121 227.431 193.089 227.435 193.058 227.43C192.468 227.354 191.888 227.215 191.328 227.014ZM190.902 225.343C190.837 225.311 190.765 225.297 190.693 225.303C190.645 225.303 190.588 225.303 190.532 225.439C190.478 225.534 190.45 225.642 190.45 225.751C190.45 225.86 190.478 225.967 190.532 226.063C190.765 226.486 191.57 226.862 192.736 227.054C192.246 226.367 191.623 225.786 190.902 225.343Z"
                    fill="#0976CB" />
                <path
                    d="M193.042 227.421C193.016 227.412 192.994 227.396 192.978 227.373C192.463 226.766 191.739 224.887 192.173 224.239C192.24 224.139 192.34 224.064 192.456 224.028C192.571 223.991 192.696 223.995 192.809 224.039C192.928 224.069 193.04 224.124 193.137 224.2C193.234 224.276 193.314 224.371 193.372 224.479C193.871 225.366 193.308 227.237 193.284 227.317C193.274 227.346 193.257 227.371 193.234 227.391C193.212 227.41 193.184 227.424 193.155 227.429C193.137 227.438 193.117 227.442 193.097 227.44C193.078 227.439 193.059 227.432 193.042 227.421ZM192.769 224.367H192.704C192.495 224.319 192.447 224.367 192.423 224.439C192.181 224.823 192.576 226.182 193.034 226.925C193.264 226.186 193.264 225.394 193.034 224.655C192.983 224.53 192.889 224.428 192.769 224.367Z"
                    fill="#0976CB" />
                <path opacity="0.2"
                    d="M235.329 123.224C230.952 128.613 229.222 142.349 229.239 153.799C232.191 145.715 235.578 135.449 238.555 126.126L235.329 123.224Z"
                    fill="black" />
                <path d="M255.306 232.626H245.925L244.678 216.955H254.059L255.306 232.626Z" fill="#FFC3BD" />
                <path
                    d="M245.394 231.843H255.974C256.109 231.842 256.241 231.887 256.346 231.972C256.452 232.056 256.525 232.175 256.553 232.307L257.896 238.319C257.928 238.466 257.926 238.618 257.89 238.764C257.854 238.91 257.786 239.045 257.69 239.161C257.594 239.277 257.473 239.37 257.336 239.434C257.199 239.497 257.05 239.529 256.899 239.526C254.228 239.479 250.02 239.319 246.657 239.319C244.581 239.319 240.735 239.535 237.879 239.535C235.023 239.535 234.661 236.728 235.812 236.472C241.065 235.329 242.626 233.762 244.3 232.259C244.601 231.992 244.99 231.844 245.394 231.843Z"
                    fill="#263238" />
                <path opacity="0.2" d="M254.059 216.955H244.67L245.321 225.039H254.71L254.059 216.955Z" fill="black" />
                <path
                    d="M229.657 100.212C229.657 100.212 234.854 143.46 235.948 159.027C237.469 180.695 243.35 223.471 243.35 223.471H255.821C255.821 223.471 257.156 179.784 254.903 160.346C258.009 143.388 257.027 101.515 257.027 101.515L229.657 100.212Z"
                    fill="#002B45" />
                <path d="M242.851 223.735H256.545L257.164 219.378L241.741 219.09L242.851 223.735Z" fill="#263238" />
                <path
                    d="M243.101 232.907C242.637 232.942 242.175 232.821 241.789 232.563C241.67 232.459 241.576 232.327 241.518 232.18C241.459 232.032 241.436 231.873 241.452 231.715C241.452 231.621 241.478 231.528 241.528 231.448C241.577 231.367 241.648 231.302 241.733 231.259C242.489 230.875 244.686 232.219 244.951 232.371C244.979 232.387 245.002 232.41 245.016 232.439C245.03 232.467 245.036 232.5 245.032 232.531C245.024 232.563 245.009 232.593 244.986 232.617C244.963 232.641 244.935 232.658 244.903 232.667C244.313 232.815 243.709 232.895 243.101 232.907ZM242.087 231.507C242.015 231.503 241.943 231.516 241.878 231.547C241.838 231.547 241.789 231.611 241.781 231.731C241.765 231.839 241.776 231.949 241.814 232.051C241.852 232.153 241.915 232.244 241.999 232.315C242.369 232.627 243.262 232.667 244.412 232.435C243.712 231.982 242.931 231.667 242.111 231.507H242.087Z"
                    fill="#0976CB" />
                <path
                    d="M244.871 232.666H244.79C244.09 232.282 242.723 230.803 242.868 230.043C242.892 229.926 242.956 229.82 243.05 229.744C243.144 229.668 243.261 229.627 243.382 229.628C243.504 229.61 243.628 229.619 243.746 229.654C243.864 229.688 243.973 229.748 244.066 229.827C244.871 230.475 245.024 232.426 245.032 232.506C245.033 232.535 245.028 232.565 245.015 232.591C245.002 232.618 244.983 232.641 244.959 232.658C244.931 232.668 244.901 232.67 244.871 232.666ZM243.495 229.923H243.423C243.213 229.923 243.197 230.051 243.189 230.091C243.101 230.531 243.994 231.69 244.67 232.186C244.621 231.413 244.341 230.673 243.865 230.059C243.761 229.974 243.63 229.926 243.495 229.923Z"
                    fill="#0976CB" />
                <path
                    d="M233.961 40.5729L226.447 40.4769C223.921 40.4769 221.41 40.397 218.924 40.357C218.168 40.357 217.428 40.309 216.68 40.277C216.728 40.277 216.76 40.1891 216.8 40.1491C217.331 39.5894 217.862 39.0137 218.409 38.462C218.683 38.1742 218.951 37.8944 219.214 37.6225C219.463 37.3507 219.785 37.0468 219.954 36.823L220.638 35.9594C221.177 35.2761 221.486 34.4414 221.521 33.5738C221.556 32.7062 221.315 31.8497 220.832 31.1259C220.349 30.402 219.65 29.8476 218.832 29.5412C218.015 29.2349 217.121 29.1921 216.277 29.4191L214.789 29.8189C214.33 29.9548 213.872 30.1067 213.413 30.2586C212.512 30.5704 211.627 30.9143 210.75 31.29C208.983 32.0655 207.275 32.9687 205.641 33.9925C203.949 35.0677 202.348 36.2791 200.854 37.6145C199.274 39.0426 197.842 40.6258 196.582 42.3399C196.264 42.7676 195.994 43.2288 195.778 43.7151C195.03 45.4169 194.994 47.344 195.676 49.0727C196.357 50.8014 197.702 52.1902 199.414 52.934L200.146 53.2538C201.799 53.9607 203.512 54.5225 205.263 54.9329C206.792 55.3246 208.24 55.5805 209.664 55.8204C211.088 56.0602 212.48 56.2521 213.864 56.42C215.248 56.5879 216.615 56.7159 217.983 56.8278C220.71 57.0677 223.414 57.1876 226.109 57.2676C228.804 57.3475 231.443 57.3475 234.243 57.2196C236.426 57.127 238.487 56.1909 239.985 54.6107C241.484 53.0305 242.303 50.9312 242.268 48.7598C242.232 46.5885 241.345 44.5168 239.795 42.9861C238.245 41.4554 236.154 40.5866 233.969 40.5649L233.961 40.5729Z"
                    fill="#FFC3BD" />
                <path
                    d="M238.338 55.1648C231.845 62.5207 216.221 59.7702 216.221 59.7702L217.082 41.4444C222.438 40.2731 227.899 39.6436 233.382 39.5654C242.554 39.5495 243.535 49.2641 238.338 55.1648Z"
                    fill="#EF9517" />
                <path
                    d="M221.008 49.5273C220.622 52.4856 220.204 56.4674 219.954 61.8244C216.567 78.8549 215.304 90.9202 218.297 99.7233C220.662 102.626 251.484 102.066 257.028 101.506C260.246 93.5907 260.584 70.8674 260.962 51.766C261.009 49.1339 260.106 46.572 258.416 44.5448C256.726 42.5176 254.361 41.1593 251.75 40.7162C244.814 39.4943 237.738 39.2574 230.735 40.0126C228.277 40.2905 225.984 41.3785 224.221 43.1031C222.458 44.8276 221.327 47.0893 221.008 49.5273Z"
                    fill="#EF9517" />
                <path opacity="0.2"
                    d="M250.913 61.1533C251.018 66.9741 255.266 76.2729 260.045 80.9903C260.334 76.073 260.519 70.74 260.664 65.359L250.913 61.1533Z"
                    fill="black" />
                <path
                    d="M277.769 72.4679L277.495 71.8283C276.37 69.1613 275.056 66.5775 273.561 64.0966C272.129 61.6979 270.584 59.4352 268.983 57.2524C267.382 55.0696 265.684 52.9828 263.947 50.9519C263.062 49.9445 262.185 48.937 261.259 47.9616L259.868 46.5064L258.387 45.0192C256.836 43.4976 254.751 42.6358 252.571 42.6165C250.392 42.5973 248.291 43.4221 246.714 44.9161C245.136 46.4102 244.206 48.456 244.12 50.62C244.034 52.7841 244.8 54.8962 246.255 56.5088L246.496 56.7727L251.219 61.9937C252.828 63.7687 254.364 65.5757 255.893 67.3747C256.633 68.2782 257.398 69.1817 258.089 70.1012C258.781 71.0207 259.514 71.9082 260.205 72.8197C261.203 74.2029 262.169 75.5782 263.029 76.9294C262.898 77.6117 262.731 78.2868 262.531 78.9523C261.952 80.8891 261.273 82.7946 260.495 84.6611C259.691 86.636 258.838 88.6589 257.953 90.6498C257.068 92.6407 256.118 94.7195 255.274 96.7184L255.193 96.8943C254.822 97.7779 254.766 98.7607 255.034 99.6803C255.301 100.6 255.876 101.401 256.664 101.952C257.452 102.503 258.405 102.77 259.367 102.71C260.328 102.649 261.24 102.265 261.951 101.62C263.726 99.9847 265.41 98.2551 266.996 96.4385C268.605 94.6422 270.115 92.7499 271.525 90.7617C272.241 89.7623 272.925 88.7228 273.601 87.6434C274.303 86.5459 274.945 85.4116 275.524 84.2453C276.175 82.9733 276.74 81.659 277.213 80.3115C277.831 78.5861 278.178 76.7767 278.243 74.9465C278.264 74.0962 278.102 73.2512 277.769 72.4679Z"
                    fill="#FFC3BD" />
                <path
                    d="M252.297 40.8212C263.15 42.6682 270.222 57.268 270.222 57.268L257.14 71.2522C257.14 71.2522 249.602 63.6245 245.072 56.5564C240.188 48.8647 244.525 39.5099 252.297 40.8212Z"
                    fill="#EF9517" />
                <path
                    d="M232.473 17.802C230.655 14.7717 230.059 10.606 233.39 6.36035C232.741 6.4412 232.082 6.33315 231.494 6.0493C230.906 5.76545 230.413 5.318 230.075 4.76124C233.723 1.7958 238.309 0.209565 243.02 0.283738C252.755 0.283738 253.479 4.01766 253.15 6.56823C259.811 6.992 264.671 24.2064 247.783 27.2527C238.121 17.1383 232.473 17.802 232.473 17.802Z"
                    fill="#263238" />
                <path
                    d="M252.538 6.88033C252.538 6.88033 255.249 4.12986 251.878 0.803711C252.634 1.00966 253.323 1.4066 253.879 1.95586C254.434 2.50512 254.837 3.18799 255.048 3.93797C255.523 5.87289 254.212 7.42403 252.538 6.88033Z"
                    fill="#263238" />
                <path
                    d="M248.805 25.1504C248.548 30.4514 248.065 36.4801 248.958 40.7097C247.574 42.8765 244.034 45.3711 237.694 45.9308C235.442 44.6595 232.465 41.5493 233.503 39.9501C236.198 38.2311 237.34 33.026 236.568 29.1322L248.805 25.1504Z"
                    fill="#FFC3BD" />
                <path opacity="0.2"
                    d="M245.716 26.1494L236.552 29.1318C236.863 30.79 236.877 32.49 236.592 34.153C239.328 34.065 243.117 32.0181 244.541 30.0992C245.005 29.5597 245.349 28.9292 245.552 28.2486C245.754 27.5681 245.81 26.8528 245.716 26.1494Z"
                    fill="black" />
                <path
                    d="M251.476 16.7542C249.779 23.2545 249.119 27.1484 245.112 29.8749C239.086 33.9766 231.507 29.3791 231.21 22.511C230.952 16.3224 233.768 6.72776 240.768 5.36052C242.309 5.05335 243.904 5.14289 245.4 5.62061C246.897 6.09833 248.246 6.94838 249.319 8.09001C250.392 9.23165 251.153 10.6269 251.531 12.1434C251.908 13.6598 251.889 15.247 251.476 16.7542Z"
                    fill="#FFC3BD" />
                <path
                    d="M250.125 7.44824C247.252 9.47911 243.688 18.0823 250.929 21.5364C257.43 15.8596 255.748 7.44824 250.125 7.44824Z"
                    fill="#263238" />
                <path
                    d="M251.782 8.47904C253.013 2.81819 238.636 -0.108184 234.082 6.50414C232.537 10.4859 250.913 14.004 251.782 8.47904Z"
                    fill="#263238" />
                <path
                    d="M241.057 23.6549C239.563 22.9833 237.962 22.5798 236.327 22.4629C236.304 22.4629 236.281 22.4675 236.26 22.4764C236.239 22.4853 236.22 22.4984 236.204 22.5149C236.188 22.5314 236.176 22.5508 236.168 22.5721C236.16 22.5935 236.157 22.6162 236.158 22.6389C236.167 22.6906 236.193 22.7378 236.233 22.7726C236.272 22.8075 236.322 22.828 236.375 22.8309C237.942 22.9436 239.478 23.328 240.912 23.9669C240.957 23.9866 241.008 23.9884 241.055 23.9721C241.101 23.9557 241.139 23.9224 241.162 23.8789C241.171 23.8574 241.175 23.8344 241.175 23.8112C241.174 23.788 241.169 23.7652 241.159 23.7441C241.149 23.7231 241.135 23.7043 241.118 23.6889C241.1 23.6736 241.08 23.662 241.057 23.6549Z"
                    fill="#263238" />
                <path
                    d="M253.045 23.862C251.879 25.0918 250.271 25.8133 248.572 25.8689C246.319 25.9168 245.748 23.782 246.834 21.9591C247.815 20.3599 250.165 18.3131 252.208 19.1286C254.252 19.9442 254.461 22.3348 253.045 23.862Z"
                    fill="#FFC3BD" />
                <path
                    d="M241.073 16.6424C240.969 17.1781 240.599 17.5619 240.269 17.4899C239.939 17.418 239.722 16.9302 239.826 16.3945C239.931 15.8588 240.301 15.4831 240.631 15.595C240.961 15.7069 241.178 16.0827 241.073 16.6424Z"
                    fill="#263238" />
                <path
                    d="M234.983 15.4673C234.878 16.003 234.508 16.3788 234.178 16.3149C233.849 16.2509 233.623 15.7552 233.728 15.2195C233.833 14.6838 234.203 14.3 234.532 14.372C234.862 14.4439 235.055 14.9316 234.983 15.4673Z"
                    fill="#263238" />
                <path
                    d="M236.866 16.083C235.9 17.5963 234.731 18.971 233.39 20.1687C234.195 21.2321 235.973 21.0242 235.973 21.0242L236.866 16.083Z"
                    fill="#ED847E" />
                <path
                    d="M240.196 13.5314C240.254 13.5189 240.314 13.5211 240.37 13.5379C240.426 13.5547 240.477 13.5855 240.518 13.6274C240.777 13.9379 241.105 14.1831 241.477 14.343C241.849 14.5029 242.254 14.573 242.658 14.5474C242.744 14.5359 242.83 14.5578 242.899 14.6086C242.968 14.6594 243.015 14.7351 243.028 14.8194C243.035 14.861 243.033 14.9036 243.023 14.9445C243.013 14.9855 242.995 15.0239 242.969 15.0576C242.943 15.0912 242.911 15.1194 242.875 15.1403C242.838 15.1612 242.797 15.1745 242.755 15.1794C242.244 15.2203 241.731 15.1392 241.258 14.943C240.785 14.7468 240.366 14.4412 240.036 14.0514C240.005 14.0223 239.981 13.9875 239.965 13.949C239.949 13.9106 239.94 13.8692 239.94 13.8274C239.94 13.7856 239.949 13.7443 239.965 13.7058C239.981 13.6673 240.005 13.6325 240.036 13.6034C240.083 13.5681 240.138 13.5435 240.196 13.5314Z"
                    fill="#263238" />
                <path
                    d="M233.1 11.7576C233.033 11.7438 232.974 11.707 232.931 11.6536C232.904 11.6202 232.884 11.5818 232.873 11.5406C232.861 11.4994 232.857 11.4562 232.863 11.4137C232.868 11.3712 232.882 11.3301 232.903 11.293C232.925 11.2559 232.953 11.2234 232.988 11.1976C233.383 10.8755 233.85 10.6517 234.349 10.5446C234.848 10.4375 235.366 10.4501 235.86 10.5816C235.941 10.609 236.009 10.667 236.048 10.7432C236.087 10.8195 236.095 10.9079 236.069 10.9896C236.037 11.0668 235.977 11.1297 235.902 11.1665C235.826 11.2033 235.74 11.2115 235.659 11.1896C235.266 11.0898 234.854 11.0843 234.458 11.1733C234.063 11.2623 233.694 11.4433 233.382 11.7016C233.344 11.7352 233.298 11.7581 233.249 11.7679C233.199 11.7778 233.148 11.7742 233.1 11.7576Z"
                    fill="#263238" />
                <path
                    d="M259.071 96.4864L252.16 96.4385L255.732 104.322C255.732 104.322 261.629 103.067 261.308 99.8846L259.071 96.4864Z"
                    fill="#FFC3BD" />
                <path
                    d="M248.644 101.851L249.779 104.074C250.084 104.666 250.6 105.124 251.226 105.357C251.853 105.591 252.544 105.584 253.166 105.338L255.732 104.322L252.16 96.4385L249.417 98.5173C248.914 98.8943 248.561 99.4351 248.42 100.045C248.279 100.654 248.358 101.294 248.644 101.851Z"
                    fill="#FFC3BD" />
                <path
                    d="M206.462 90.6809C205.627 90.6723 204.813 90.4217 204.119 89.9598C203.426 89.498 202.883 88.8449 202.557 88.0808C202.232 87.3167 202.138 86.4748 202.287 85.6584C202.436 84.8419 202.822 84.0865 203.397 83.4849C206.47 80.3346 209.246 77.888 211.691 75.7532C217.597 70.5801 219.632 68.5572 219.439 64.6793C219.15 62.2867 218.639 59.9259 217.911 57.6273C216.149 51.1909 214.153 43.9069 217.573 38.0622C219.407 34.9199 222.521 32.7691 226.833 31.6658C227.923 31.4086 229.072 31.5866 230.031 32.1616C230.991 32.7365 231.685 33.6626 231.966 34.7408C232.246 35.819 232.089 36.9635 231.53 37.9283C230.97 38.8932 230.052 39.6015 228.973 39.9012C225.94 40.7007 225.208 41.924 224.95 42.3398C223.341 45.0983 224.894 50.8071 226.149 55.3885C227.058 58.2776 227.667 61.2514 227.967 64.2636C228.37 72.459 223.502 76.7286 217.331 82.1336C215.006 84.1805 212.359 86.4992 209.511 89.4096C209.114 89.8129 208.64 90.1332 208.116 90.3515C207.592 90.5699 207.03 90.6818 206.462 90.6809Z"
                    fill="#0976CB" />
                <path
                    d="M215.851 29.5956L222.545 27.8926L220.984 36.3998C220.984 36.3998 214.958 36.5837 214.507 33.4255L215.851 29.5956Z"
                    fill="#FFC3BD" />
                <path
                    d="M227.243 32.4021L226.728 34.6248C226.57 35.3038 226.162 35.8994 225.585 36.2944C225.007 36.6895 224.301 36.8555 223.607 36.7596L220.984 36.3998L222.545 27.8926L225.618 29.2118C226.222 29.4703 226.715 29.9313 227.012 30.5143C227.309 31.0973 227.391 31.7653 227.243 32.4021Z"
                    fill="#FFC3BD" />
                <path
                    d="M204.069 76.6908L203.887 76.51C203.073 75.7014 201.754 75.7016 200.94 76.5105L177.215 100.096C176.402 100.905 176.402 102.216 177.216 103.025L177.398 103.206C178.212 104.014 179.531 104.014 180.345 103.205L204.069 79.6195C204.883 78.8106 204.883 77.4994 204.069 76.6908Z"
                    fill="#263238" />
                <path
                    d="M216.434 88.9636L216.252 88.7827C215.438 87.974 214.119 87.974 213.305 88.7827L189.576 112.364C188.762 113.173 188.762 114.484 189.576 115.293L189.758 115.474C190.572 116.283 191.891 116.283 192.705 115.474L216.434 91.8922C217.248 91.0835 217.248 89.7723 216.434 88.9636Z"
                    fill="#263238" />
                <path
                    d="M184.701 85.4707L201.421 68.8545C201.956 68.3221 202.592 67.8998 203.292 67.6117C203.992 67.3236 204.742 67.1754 205.5 67.1754C206.257 67.1754 207.007 67.3237 207.707 67.6118C208.407 67.8999 209.043 68.3221 209.579 68.8545L224.194 83.3788C224.729 83.9112 225.154 84.5431 225.444 85.2387C225.734 85.9342 225.883 86.6797 225.883 87.4325C225.883 88.1854 225.734 88.9308 225.444 89.6264C225.154 90.3219 224.729 90.9539 224.194 91.4862L207.474 108.102L184.701 85.4707Z"
                    fill="#2D74FF" />
                <path opacity="0.7"
                    d="M184.701 85.4707L201.421 68.8545C201.956 68.3221 202.592 67.8998 203.292 67.6117C203.992 67.3236 204.742 67.1754 205.5 67.1754C206.257 67.1754 207.007 67.3237 207.707 67.6118C208.407 67.8999 209.043 68.3221 209.579 68.8545L224.194 83.3788C224.729 83.9112 225.154 84.5431 225.444 85.2387C225.734 85.9342 225.883 86.6797 225.883 87.4325C225.883 88.1854 225.734 88.9308 225.444 89.6264C225.154 90.3219 224.729 90.9539 224.194 91.4862L207.474 108.102L184.701 85.4707Z"
                    fill="white" />
                <path opacity="0.2"
                    d="M190.468 79.6953L184.675 85.4441L207.452 108.079L213.236 102.323C213.006 101.653 212.623 101.046 212.118 100.548L192.254 80.8467C191.757 80.3299 191.145 79.9356 190.468 79.6953Z"
                    fill="#0976CB" />
                <path
                    d="M183.698 82.5456L183.283 82.9584C181.517 84.7132 181.517 87.5583 183.283 89.3131L203.564 109.469C205.33 111.223 208.193 111.223 209.959 109.469L210.374 109.056C212.14 107.301 212.14 104.456 210.374 102.701L190.093 82.5456C188.327 80.7908 185.464 80.7908 183.698 82.5456Z"
                    fill="#0976CB" />
            </svg>
            <h1 class="header">404 (Page Not Found)</h1>
            <h3 class="subHeader">We are experiencing technical problems.</h3>
            <p class="content">Please try again at a later time or for further information, <br>please connect with
                Admin.</p>
                <button pButton pRipple type="button" label="GO BACK TO HOME PAGE" class="cozmos-secondary-button"
                [routerLink]="['/user/document']"></button>
        </div>
    </div>
</div>