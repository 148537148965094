<div>
    <img id="bdpChatBot" src="./../../../../../assets/layout/images/nav-gpt-header.png" (click)="showDialog()" pTooltip="Cozmo"
    tooltipPosition="left"  alt="chatBot">
</div>
<p-dialog id="chatBotDialog" (onHide)="onChatHide()" (onShow)="onChatShow()" [(visible)]="showChatWindows" [draggable]="false"
    [resizable]="false" position="bottom-right" [style]="{width: '50vw'}" styleClass="bdp-collacible-dialog">
    <ng-template pTemplate="header">
        <div class="flex align-items-center">
            <img id="bdpChatBotHeader" class="mr-3" src="./../../../../../assets/layout/images/nav-gpt-header.png"
                alt="bot-header">
            <h5 class="m-0">Cozmo</h5>
            <i *ngIf="collapsed" class="pi pi-minus collapsableIcon" (click)="collapseChat()"></i>
            <i *ngIf="!collapsed" class="pi pi-plus collapsableIcon" (click)="collapseChat()"></i>
            <img src="./../../../../../assets/layout/images/clear-chat.png" (click)="clearChat()" class="clearIcon"
                alt="clear-chat">
        </div>
    </ng-template>
    <ng-template pTemplate="content">
        <div  >
            <div class="today">Today</div>
            <div *ngFor="let item of chatList">
                <div *ngIf="item.type == 'BOT' ;else userReponse">
                    <div class="grid chatBotResponseStyle">
                        <div class="col-1 px-1 p-text-center chatBotStyle flex align-items-end">
                            <img id="bdpChatBotContent" class="mr-2"
                                src="./../../../../../assets/layout/images/nav-gpt-header.png" alt="">
                        </div>
                        <div class="col-9 pl-1">
                            <span class="messageStyleChatBot type p-mt-2"
                                style="--n:{{responseMsgLen}}">{{item.message}}</span>
                        </div>

                    </div>
                </div>
                <ng-template #userReponse>
                    <div class="grid p-mr-0 p-my-1">
                        <div class="col-1"></div>
                        <div class="col-11 p-pr-0 msgPosition ">
                            <span class="messageStyleUser user p-mt-2">{{item.message}}</span>
                        </div>
                    </div>
                </ng-template>
            </div>
            <!-- <span *ngIf="chatList.length == 2 && showSuggestionButton">
                <button pButton pRipple type="button" label="Shipment Queries"
                    (click)="shipmentQueriesClicked('Shipment Queries');showSuggestionButton=false"
                    styleClass="suggestionButton " class="p-button-outlined p-button-rounded ml-4"></button>
                <button pButton pRipple type="button" label="Manual Queries"
                    (click)="manualQueriesClicked('Manual Queries');showSuggestionButton=false"
                    styleClass="suggestionButton " class="p-button-outlined p-button-rounded ml-3"></button>
            </span> -->
            <div *ngIf="showTyping" class="grid chatBotResponseStyle">
                <div class="col-1 px-1 p-text-center chatBotStyle flex align-items-end">
                    <img id="bdpChatBotContent" class="mr-2"
                        src="./../../../../../assets/layout/images/nav-gpt-header.png" alt="">
                </div>
                <div class="col-11 pl-1 pb-2">
                    <span class="messageStyleChatBot type p-mt-2" style="--n:{{responseMsgLen}}">
                        <div class="typing">
                            <div class="dot"></div>
                            <div class="dot"></div>
                            <div class="dot"></div>
                        </div>
                    </span>
                    <p class="mt-1" style="font-size: small;">Thinking...</p>
                </div>
            </div>
        </div>
    </ng-template>
    <ng-template pTemplate="footer">
        <span class="p-input-icon-right ">
            <i class="pi pi-send sendIcon" (click)="sendUserInput(userInput)" ></i>
            <input class="pr-6" type="text"  placeholder="Send a message..." (keyup.enter)="onEnter()" pInputText
                [(ngModel)]="userInput" />
        </span>
        <!--  -->
        <!--   placeholder="Under Maintenance, Cannot send message"  -->
    </ng-template>
</p-dialog>