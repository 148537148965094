/**
 * This file contains authentication parameters. Contents of this file
 * is roughly the same across other MSAL.js libraries. These parameters
 * are used to initialize Angular and MSAL Angular configurations in
 * in app.module.ts file.
 */


import { LogLevel, Configuration, BrowserCacheLocation } from '@azure/msal-browser';
import { environment } from 'src/environments/environment';

const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;

export const msalConfig: Configuration = {
    auth: {
        clientId: environment.sso.adClientId,
        authority: 'https://login.microsoftonline.com/'+environment.sso.adTenantId,
        redirectUri: '/user/document/',
        postLogoutRedirectUri:'/login',
        navigateToLoginRequestUrl: false
    },
    cache: {
        cacheLocation: BrowserCacheLocation.LocalStorage
    },
    system: {
        loggerOptions: {
            loggerCallback: (level, message, containsPii) => {
            },
            logLevel: LogLevel.Verbose
        }
    }
};

/**
 * Add here the endpoints and scopes when obtaining an access token for protected web APIs. For more information, see:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/resources-and-scopes.md
 */
export const protectedResources = new Map(
[
    ["cozentus-cozmos-portal-service-dev.azurewebsites.net",["16a19388-ddd5-4017-a04d-d2f15bf6215d/api-access"]],
    ["cozentus-cozmos-portal-service-test.azurewebsites.net",["16a19388-ddd5-4017-a04d-d2f15bf6215d/api-access"]],
    ["cozentus-cozmos-portal-service-uat.azurewebsites.net",["dfe8881d-0d23-44f5-8012-3da82cb2092b/api-access"]],
    ["training-cozmos-portal-service-trn.azurewebsites.net",["7aa60911-6ae7-4dcf-9f2c-e27c58e1e776/api-access"]],
    ["cozentus-cozmos-portal-service-dr.azurewebsites.net",["dfe8881d-0d23-44f5-8012-3da82cb2092b/api-access"]],

    (environment.isCozentusEnvironment)?
    ["cozentus-cozmos-portal-service-prod.azurewebsites.net",["16a19388-ddd5-4017-a04d-d2f15bf6215d/api-access"]]:
	["cozentus-cozmos-portal-service-prod.azurewebsites.net",["ab4e3223-bfdd-4528-9fc0-66cc863f9740/api-access"]],
    
    ["https://graph.microsoft.com/v1.0/me",["https://graph.microsoft.com/User.Read"]]
]);

/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes, visit: 
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
export const loginRequest = {
    scopes: environment.msalLogInRequest
};