<div class="exception-body">
    <div class="exception-bottom">
        <div class="exception-wrapper">
            <svg width="312" height="265" viewBox="0 0 312 265" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path opacity="0.9"
                    d="M118.681 38.0227C163.468 42.6498 183.124 6.78547 227.511 11.4814C267.121 15.6699 294.44 41.7382 285.194 76.8457C276.982 108.014 237.884 117.793 223.982 146.992C209.242 177.954 197.817 211.316 153.31 222.092C65.9237 243.249 23.4738 143.93 17.5239 86.8825C15.2427 64.9855 11.2575 12.6339 42.208 5.85661C73.3541 -0.972237 91.241 35.1845 118.681 38.0227Z"
                    fill="#D2ECFC" />
                <path
                    d="M155.993 264.102C242.145 264.102 311.986 260.05 311.986 255.051C311.986 250.052 242.145 246 155.993 246C69.8404 246 0 250.052 0 255.051C0 260.05 69.8404 264.102 155.993 264.102Z"
                    fill="#F5F5F5" />
                <path
                    d="M34.0743 101.179C33.9902 101.179 33.909 101.148 33.8468 101.092C33.7845 101.035 33.7456 100.958 33.7378 100.874L32.0004 79.9789C31.996 79.8943 32.0245 79.8112 32.0799 79.747C32.1353 79.6828 32.2134 79.6425 32.2978 79.6346C32.3859 79.6281 32.4729 79.6564 32.5403 79.7135C32.6076 79.7706 32.6498 79.8519 32.6578 79.9398L34.3951 100.835C34.4017 100.923 34.3733 101.01 34.3162 101.077C34.2591 101.145 34.1779 101.187 34.0899 101.195L34.0743 101.179Z"
                    fill="#0976CB" />
                <path
                    d="M35.1073 113.591C35.0232 113.591 34.942 113.56 34.8797 113.504C34.8175 113.447 34.7786 113.369 34.7708 113.286L34.2464 106.962C34.2421 106.919 34.2467 106.874 34.2598 106.832C34.273 106.79 34.2944 106.751 34.3228 106.718C34.3513 106.684 34.3862 106.657 34.4255 106.637C34.4648 106.617 34.5077 106.605 34.5516 106.602C34.5955 106.598 34.6397 106.603 34.6817 106.616C34.7237 106.629 34.7627 106.65 34.7962 106.679C34.8298 106.707 34.8573 106.742 34.8771 106.781C34.897 106.821 34.9087 106.864 34.9116 106.908L35.436 113.231C35.4425 113.319 35.4141 113.406 35.357 113.473C35.3 113.541 35.2187 113.583 35.1308 113.591H35.1073Z"
                    fill="#0976CB" />
                <path
                    d="M211.165 173.051H49.5772C47.5414 173.011 45.5909 172.226 44.0952 170.845C42.5995 169.463 41.6626 167.581 41.4618 165.554L32.3604 56.1572C32.2632 55.2017 32.3692 54.2364 32.6715 53.3248C32.9738 52.4131 33.4656 51.5758 34.1146 50.8677C34.7635 50.1596 35.5549 49.5968 36.4368 49.2164C37.3187 48.8359 38.2711 48.6463 39.2315 48.6601H200.811C202.848 48.6998 204.799 49.4847 206.296 50.8663C207.793 52.2478 208.732 54.1302 208.934 56.1572L218.036 165.539C218.134 166.495 218.029 167.462 217.727 168.375C217.425 169.288 216.934 170.126 216.285 170.836C215.636 171.545 214.844 172.11 213.962 172.492C213.079 172.873 212.126 173.064 211.165 173.051Z"
                    fill="white" />
                <path
                    d="M211.165 173.364H49.5772C47.462 173.33 45.4335 172.518 43.8789 171.083C42.3243 169.648 41.3524 167.691 41.1488 165.586L32.0474 56.1964C31.9555 55.1983 32.073 54.192 32.3924 53.242C32.7118 52.292 33.226 51.4191 33.9021 50.6792C34.5749 49.9344 35.3974 49.3403 36.3159 48.9357C37.2343 48.5311 38.2279 48.3251 39.2315 48.3314H200.811C202.935 48.3652 204.971 49.183 206.529 50.6277C208.086 52.0723 209.054 54.0418 209.247 56.1572L218.341 165.539C218.441 166.536 218.33 167.544 218.014 168.495C217.697 169.446 217.184 170.32 216.506 171.059C215.828 171.798 215.002 172.386 214.082 172.783C213.161 173.18 212.167 173.378 211.165 173.364ZM39.2315 48.9888C38.3129 48.9739 37.4016 49.154 36.5577 49.5173C35.7139 49.8805 34.9567 50.4187 34.3362 51.0961C33.7156 51.7736 33.2459 52.575 32.9579 53.4475C32.67 54.3199 32.5704 55.2435 32.6657 56.1572L41.7671 165.531C41.9607 167.483 42.8649 169.296 44.3077 170.625C45.7506 171.953 47.6316 172.706 49.5929 172.738H211.165C212.081 172.752 212.99 172.571 213.832 172.209C214.673 171.846 215.429 171.309 216.048 170.633C216.668 169.958 217.137 169.159 217.426 168.289C217.714 167.419 217.815 166.497 217.723 165.586L208.621 56.1964C208.429 54.2437 207.526 52.4299 206.082 51.1007C204.639 49.7715 202.757 49.0198 200.795 48.9888H39.2315Z"
                    fill="#0976CB" />
                <path
                    d="M200.811 48.6757H39.2316C38.2727 48.6622 37.3218 48.8514 36.4411 49.2309C35.5604 49.6105 34.7698 50.1718 34.1211 50.8781C33.4724 51.5844 32.9802 52.4198 32.6768 53.3295C32.3734 54.2393 32.2657 55.2029 32.3606 56.1572H208.934C208.728 54.133 207.788 52.2544 206.291 50.876C204.795 49.4976 202.845 48.7149 200.811 48.6757Z"
                    fill="#0976CB" />
                <path
                    d="M43.0113 52.4242C43.0335 52.6305 43.0117 52.8391 42.9471 53.0362C42.8826 53.2333 42.7769 53.4145 42.637 53.5677C42.4972 53.7209 42.3263 53.8426 42.1359 53.9247C41.9454 54.0069 41.7397 54.0476 41.5323 54.0442C41.0926 54.0349 40.6716 53.865 40.3486 53.5666C40.0257 53.2681 39.8231 52.8618 39.7793 52.4242C39.7583 52.2182 39.7811 52.0101 39.8461 51.8136C39.9111 51.617 40.0169 51.4363 40.1565 51.2834C40.2961 51.1305 40.4664 51.0088 40.6563 50.9262C40.8462 50.8437 41.0513 50.8021 41.2583 50.8043C41.699 50.8101 42.1219 50.9789 42.4456 51.278C42.7693 51.5771 42.9708 51.9854 43.0113 52.4242Z"
                    fill="white" />
                <path
                    d="M49.585 52.4242C49.6072 52.6305 49.5853 52.8391 49.5208 53.0362C49.4563 53.2333 49.3506 53.4145 49.2107 53.5677C49.0709 53.7209 48.9 53.8426 48.7096 53.9247C48.5191 54.0069 48.3133 54.0476 48.1059 54.0442C47.6718 54.0274 47.2583 53.8542 46.9419 53.5564C46.6254 53.2587 46.4274 52.8566 46.3843 52.4242C46.3633 52.2182 46.3861 52.0101 46.4511 51.8136C46.5161 51.617 46.6219 51.4363 46.7615 51.2834C46.9011 51.1305 47.0714 51.0088 47.2613 50.9262C47.4511 50.8437 47.6563 50.8021 47.8633 50.8043C48.2985 50.8177 48.7138 50.9898 49.0309 51.2882C49.348 51.5866 49.5451 51.9906 49.585 52.4242Z"
                    fill="white" />
                <path
                    d="M56.1585 52.4242C56.1808 52.6305 56.1589 52.8391 56.0943 53.0362C56.0298 53.2333 55.9241 53.4145 55.7843 53.5677C55.6444 53.7209 55.4736 53.8426 55.2831 53.9247C55.0926 54.0069 54.8869 54.0476 54.6795 54.0442C54.2398 54.0349 53.8188 53.865 53.4958 53.5666C53.1729 53.2681 52.9704 52.8618 52.9265 52.4242C52.9056 52.2182 52.9283 52.0101 52.9933 51.8136C53.0583 51.617 53.1641 51.4363 53.3037 51.2834C53.4433 51.1305 53.6136 51.0088 53.8035 50.9262C53.9934 50.8437 54.1985 50.8021 54.4056 50.8043C54.8462 50.8101 55.2691 50.9789 55.5928 51.278C55.9165 51.5771 56.118 51.9854 56.1585 52.4242Z"
                    fill="white" />
                <path
                    d="M197.141 158.715H61.2533C59.6347 158.684 58.0834 158.061 56.8935 156.964C55.7036 155.866 54.9576 154.37 54.797 152.759L48.4111 76.5044C48.3348 75.7439 48.4203 74.9758 48.6621 74.2507C48.9038 73.5257 49.2963 72.8599 49.8138 72.2974C50.3312 71.7349 50.9619 71.2882 51.6643 70.9869C52.3667 70.6855 53.1249 70.5363 53.8892 70.5489H189.8C191.419 70.5793 192.97 71.2023 194.16 72.2999C195.35 73.3975 196.096 74.8935 196.256 76.5044L202.603 152.798C202.673 153.554 202.584 154.317 202.34 155.036C202.097 155.755 201.705 156.415 201.19 156.973C200.675 157.531 200.048 157.974 199.35 158.274C198.653 158.574 197.9 158.724 197.141 158.715Z"
                    fill="#F0F0F0" />
                <path opacity="0.1"
                    d="M155.1 83.8685L112.997 134.564C111.674 136.156 110.783 138.062 110.411 140.098C110.039 142.135 110.198 144.233 110.873 146.19C111.548 148.147 112.716 149.897 114.264 151.271C115.812 152.645 117.689 153.597 119.712 154.035L188.031 168.802C190.168 169.265 192.391 169.139 194.461 168.435C196.531 167.731 198.37 166.477 199.782 164.807C201.193 163.138 202.124 161.115 202.473 158.957C202.823 156.798 202.578 154.585 201.765 152.556L175.572 87.0849C174.799 85.1422 173.532 83.4345 171.898 82.1297C170.264 80.8249 168.319 79.9681 166.253 79.6436C164.187 79.319 162.072 79.5379 160.117 80.2787C158.161 81.0195 156.432 82.2567 155.1 83.8685Z"
                    fill="#263238" fill-opacity="0.2" />
                <path opacity="0.1"
                    d="M61.3786 90.9743L46.7131 130.502C46.2739 131.709 46.1473 133.008 46.3453 134.277C46.5433 135.545 47.0593 136.744 47.8453 137.759C48.6312 138.775 49.6616 139.575 50.8402 140.085C52.0188 140.595 53.3074 140.798 54.5858 140.676L97.4165 136.474C98.7795 136.34 100.081 135.842 101.184 135.03C102.287 134.219 103.151 133.125 103.684 131.863C104.217 130.602 104.4 129.22 104.212 127.863C104.025 126.507 103.475 125.226 102.621 124.156L74.4477 88.8613C73.6061 87.8108 72.5007 87.0024 71.2445 86.5188C69.9882 86.0351 68.6262 85.8934 67.2973 86.1083C65.9684 86.3231 64.7204 86.8868 63.6805 87.7417C62.6407 88.5965 61.8464 89.7121 61.3786 90.9743Z"
                    fill="#263238" fill-opacity="0.2" />
                <path
                    d="M169.625 71.1045L130.261 128.233C129.032 130.017 128.281 132.087 128.08 134.245C127.88 136.403 128.236 138.576 129.116 140.556C129.995 142.537 131.368 144.258 133.104 145.556C134.839 146.854 136.878 147.685 139.026 147.97L211.384 157.603C213.659 157.906 215.975 157.585 218.082 156.674C220.189 155.762 222.009 154.295 223.346 152.43C224.684 150.564 225.489 148.369 225.676 146.081C225.862 143.793 225.424 141.497 224.406 139.439L191.412 72.654C190.433 70.6755 188.953 68.9874 187.12 67.7572C185.287 66.5269 183.165 65.7973 180.962 65.6407C178.76 65.4841 176.556 65.906 174.567 66.8644C172.578 67.8228 170.875 69.2845 169.625 71.1045Z"
                    fill="#0976CB" />
                <path
                    d="M140.74 142.867C139.433 142.69 138.191 142.191 137.123 141.417C136.055 140.643 135.196 139.617 134.62 138.43C134.027 137.308 133.749 136.047 133.813 134.779C133.878 133.512 134.284 132.286 134.988 131.23L174.454 73.9297C175.167 72.9075 176.154 72.107 177.302 71.6199C178.449 71.1327 179.71 70.9785 180.941 71.175C182.249 71.3513 183.492 71.8489 184.56 72.6233C185.628 73.3976 186.487 74.4245 187.061 75.6122L220.117 142.562C220.71 143.684 220.989 144.945 220.924 146.213C220.86 147.48 220.454 148.706 219.75 149.762C219.038 150.786 218.051 151.589 216.904 152.077C215.756 152.566 214.494 152.721 213.262 152.524L140.74 142.867Z"
                    fill="white" />
                <path
                    d="M177.584 91.4282L182.193 92.0386C183.634 92.2418 184.95 92.9684 185.89 94.0798C186.829 95.1912 187.327 96.6096 187.288 98.0644L186.975 104.075C186.967 104.293 186.944 104.51 186.904 104.724L183.625 122.841C183.391 124.09 182.678 125.197 181.638 125.928C180.599 126.659 179.316 126.955 178.061 126.754C176.73 126.565 175.501 125.93 174.576 124.954C173.651 123.978 173.084 122.717 172.967 121.378L171.644 102.658C171.628 102.442 171.628 102.225 171.644 102.009L171.949 95.9906C171.982 95.3069 172.159 94.6379 172.469 94.0275C172.778 93.417 173.213 92.8787 173.745 92.448C174.277 92.0173 174.894 91.7038 175.556 91.528C176.217 91.3523 176.908 91.3182 177.584 91.4282ZM177.216 131.778L178.468 131.942C179.758 132.124 180.936 132.775 181.777 133.769C182.618 134.763 183.064 136.032 183.031 137.334C183.003 137.948 182.846 138.55 182.57 139.099C182.294 139.648 181.904 140.132 181.428 140.52C180.951 140.908 180.398 141.191 179.804 141.35C179.21 141.509 178.59 141.541 177.983 141.443L176.731 141.271C175.442 141.09 174.264 140.442 173.423 139.449C172.581 138.456 172.135 137.188 172.168 135.887C172.195 135.272 172.351 134.67 172.627 134.121C172.903 133.571 173.292 133.086 173.769 132.698C174.246 132.31 174.8 132.027 175.394 131.868C175.988 131.71 176.609 131.679 177.216 131.778Z"
                    fill="#0976CB" />
                <path
                    d="M76.5135 79.8381L65.7061 120.822C65.3802 122.067 65.3756 123.375 65.6929 124.623C66.0102 125.87 66.6389 127.017 67.5202 127.955C68.4015 128.894 69.5065 129.593 70.7317 129.988C71.957 130.383 73.2624 130.46 74.5258 130.213L116.449 122.035C117.779 121.77 119.014 121.153 120.026 120.25C121.038 119.347 121.79 118.19 122.204 116.898C122.618 115.607 122.68 114.228 122.382 112.905C122.084 111.582 121.438 110.362 120.51 109.373L89.4261 76.5434C88.4938 75.5528 87.3132 74.83 86.0069 74.4502C84.7007 74.0704 83.3166 74.0475 81.9985 74.3838C80.6804 74.7201 79.4764 75.4034 78.5118 76.3626C77.5472 77.3218 76.8572 78.5219 76.5135 79.8381Z"
                    fill="#0976CB" />
                <path
                    d="M74.4397 126.91C73.67 127.05 72.8775 126.992 72.1359 126.743C71.3944 126.494 70.7278 126.062 70.1981 125.486C69.6517 124.932 69.2568 124.246 69.0512 123.495C68.8457 122.744 68.8364 121.953 69.0242 121.198L79.8552 80.1043C80.043 79.3862 80.4311 78.7364 80.9743 78.2306C81.5175 77.7249 82.1933 77.384 82.9229 77.2478C83.6933 77.1102 84.486 77.1693 85.2274 77.4197C85.9689 77.67 86.6352 78.1035 87.1645 78.68L118.358 111.548C118.905 112.103 119.301 112.788 119.508 113.539C119.715 114.289 119.726 115.081 119.54 115.837C119.351 116.553 118.962 117.202 118.419 117.706C117.876 118.21 117.201 118.55 116.472 118.685L74.4397 126.91Z"
                    fill="white" />
                <path
                    d="M84.9578 89.7534L87.6264 89.2291C88.4881 89.09 89.3707 89.2744 90.1045 89.7468C90.8384 90.2192 91.3717 90.9462 91.6019 91.7881L92.5567 95.3645C92.5929 95.4958 92.6216 95.6291 92.6428 95.7636L94.341 107.025C94.4733 107.783 94.3009 108.563 93.8612 109.195C93.4216 109.826 92.7502 110.259 91.9932 110.398C91.1995 110.53 90.3845 110.386 89.684 109.99C88.9835 109.594 88.4396 108.97 88.1429 108.222L83.8074 97.5166C83.7548 97.3918 83.7104 97.2637 83.6744 97.1331L82.6883 93.5489C82.5601 93.1505 82.5184 92.7293 82.5659 92.3134C82.6134 91.8976 82.7491 91.4966 82.9639 91.1374C83.1787 90.7782 83.4677 90.4689 83.8115 90.2302C84.1554 89.9916 84.5462 89.829 84.9578 89.7534ZM92.5097 113.45L93.2375 113.309C94.0093 113.175 94.8032 113.336 95.4624 113.759C96.1216 114.182 96.5983 114.837 96.7983 115.594C96.9086 115.947 96.9433 116.319 96.9002 116.686C96.8571 117.053 96.7371 117.407 96.548 117.724C96.359 118.042 96.1052 118.316 95.8031 118.529C95.501 118.741 95.1575 118.888 94.7949 118.959L94.0671 119.1C93.2963 119.234 92.5034 119.075 91.8443 118.653C91.1853 118.232 90.708 117.579 90.5063 116.823C90.396 116.47 90.3613 116.097 90.4043 115.73C90.4474 115.362 90.5673 115.008 90.7562 114.69C90.9452 114.372 91.199 114.097 91.5011 113.883C91.8032 113.67 92.1468 113.522 92.5097 113.45Z"
                    fill="#0976CB" />
                <path
                    d="M277.527 89.0256L271.455 90.9977L276.338 96.7732C277.724 95.5722 278.924 94.1717 279.899 92.6176C280.151 92.1777 280.22 91.6563 280.091 91.1659C279.962 90.6755 279.646 90.2553 279.21 89.996L277.527 89.0256Z"
                    fill="#7F3E3B" />
                <path d="M268.661 95.7715L272.3 100.193L276.346 96.8045L271.454 91.0291L268.661 95.7715Z"
                    fill="#7F3E3B" />
                <path
                    d="M279.492 41.8204C280.971 45.8898 282.332 49.9593 283.624 54.0835C284.915 58.2077 286.104 62.3475 287.145 66.683L287.513 68.3499L287.685 69.2186L287.771 69.6568V69.7664V69.9307L287.818 70.2594C287.989 71.7483 287.915 73.2552 287.599 74.7201C287.1 77.0705 286.345 79.359 285.345 81.5442C283.522 85.6276 281.277 89.5094 278.647 93.1264L274.585 90.8804C276.025 86.9675 277.449 82.9685 278.498 79.0947C279.016 77.2795 279.377 75.4232 279.578 73.5462C279.643 72.8982 279.611 72.2441 279.484 71.6054L279.147 70.3846L278.709 68.9368C277.48 65.0239 276.08 60.9936 274.694 56.9868C273.309 52.98 271.924 48.9341 270.594 44.9194L279.492 41.8204Z"
                    fill="#7F3E3B" />
                <path
                    d="M245.692 17.6073C245.794 18.1003 245.622 18.5542 245.309 18.6403C244.996 18.7264 244.651 18.3821 244.526 17.8577C244.401 17.3334 244.589 16.9108 244.909 16.8326C245.23 16.7543 245.59 17.1221 245.692 17.6073Z"
                    fill="#263238" />
                <path
                    d="M245.668 18.5464C245.404 20.1854 244.925 21.7825 244.244 23.2967C244.629 23.452 245.043 23.5199 245.457 23.4955C245.871 23.4712 246.275 23.3552 246.639 23.1558L245.668 18.5464Z"
                    fill="#630F0F" />
                <path
                    d="M244.377 14.6805C244.33 14.6805 244.283 14.6691 244.241 14.6473C244.199 14.6256 244.162 14.594 244.135 14.5553C244.112 14.524 244.096 14.4886 244.088 14.4512C244.079 14.4137 244.078 14.3749 244.085 14.337C244.091 14.2991 244.105 14.2629 244.126 14.2305C244.147 14.1982 244.173 14.1702 244.205 14.1483C244.599 13.9048 245.045 13.7592 245.507 13.7238C245.968 13.6884 246.432 13.7642 246.858 13.9448C246.894 13.9594 246.926 13.9809 246.954 14.0082C246.981 14.0355 247.002 14.0679 247.017 14.1036C247.032 14.1394 247.039 14.1776 247.038 14.2162C247.038 14.2548 247.03 14.2929 247.015 14.3283C246.985 14.4005 246.928 14.4583 246.856 14.489C246.785 14.5198 246.704 14.5211 246.631 14.4927C246.298 14.3454 245.934 14.2807 245.57 14.3038C245.206 14.327 244.854 14.4374 244.542 14.6257C244.495 14.6623 244.437 14.6817 244.377 14.6805Z"
                    fill="#263238" />
                <path
                    d="M262.463 19.157C263.01 24.8385 262.204 30.34 263.472 35.2781C263.472 35.2781 262.064 39.864 253.666 39.864C244.432 39.864 249.433 35.2781 249.433 35.2781C254.519 34.0808 254.527 30.34 253.792 26.8262L262.463 19.157Z"
                    fill="#7F3E3B" />
                <path
                    d="M265.264 36.8511C265.984 35.4033 266.461 33.3451 264.975 32.8286C263.339 32.2573 254.598 32.046 251.428 33.1416C250.583 33.3649 249.859 33.9098 249.41 34.6599C248.961 35.41 248.823 36.3059 249.026 37.1563L265.264 36.8511Z"
                    fill="#263238" />
                <path
                    d="M284.132 242.952C284.708 242.803 285.265 242.588 285.791 242.31C285.82 242.296 285.843 242.275 285.86 242.249C285.877 242.223 285.885 242.192 285.885 242.161C285.885 242.129 285.874 242.098 285.854 242.072C285.834 242.047 285.807 242.029 285.776 242.02C285.502 241.926 283.076 241.12 282.434 241.668C282.364 241.729 282.312 241.808 282.283 241.896C282.254 241.984 282.25 242.078 282.27 242.169C282.282 242.333 282.336 242.49 282.426 242.627C282.517 242.764 282.641 242.876 282.786 242.952C283.222 243.102 283.696 243.102 284.132 242.952ZM285.291 242.169C284.226 242.654 283.365 242.819 282.943 242.599C282.845 242.551 282.762 242.478 282.701 242.387C282.641 242.297 282.605 242.192 282.599 242.083C282.586 242.047 282.585 242.009 282.595 241.973C282.604 241.936 282.625 241.904 282.653 241.879C282.974 241.629 284.289 241.903 285.291 242.2V242.169Z"
                    fill="#0976CB" />
                <path
                    d="M285.76 242.325C285.782 242.32 285.802 242.31 285.819 242.297C285.836 242.284 285.851 242.267 285.862 242.248C285.873 242.229 285.88 242.208 285.883 242.187C285.885 242.165 285.884 242.143 285.878 242.122C285.878 242.044 285.244 240.181 284.313 239.774C284.204 239.717 284.084 239.683 283.962 239.675C283.839 239.667 283.716 239.685 283.6 239.727C283.483 239.755 283.379 239.822 283.306 239.918C283.234 240.013 283.196 240.131 283.201 240.252C283.201 241.034 284.892 242.145 285.659 242.357L285.76 242.325ZM283.804 239.978C283.936 239.947 284.074 239.964 284.195 240.025C284.793 240.511 285.232 241.165 285.455 241.903C284.673 241.551 283.561 240.659 283.546 240.205C283.546 240.165 283.546 240.072 283.734 239.993L283.804 239.978Z"
                    fill="#0976CB" />
                <path
                    d="M241.388 247.733C241.982 247.717 242.572 247.635 243.149 247.491C243.179 247.482 243.207 247.464 243.229 247.441C243.251 247.418 243.267 247.389 243.274 247.357C243.277 247.326 243.271 247.294 243.257 247.265C243.243 247.236 243.222 247.211 243.196 247.193C242.953 247.044 240.777 245.706 240.026 246.09C239.943 246.132 239.873 246.197 239.824 246.277C239.776 246.358 239.751 246.45 239.752 246.544C239.735 246.701 239.756 246.859 239.814 247.006C239.871 247.153 239.962 247.284 240.081 247.389C240.467 247.644 240.926 247.765 241.388 247.733ZM242.687 247.264C241.544 247.491 240.66 247.451 240.292 247.138C240.21 247.068 240.146 246.978 240.108 246.877C240.07 246.776 240.058 246.666 240.073 246.559C240.073 246.434 240.128 246.395 240.175 246.371C240.558 246.176 241.803 246.747 242.687 247.264Z"
                    fill="#0976CB" />
                <path
                    d="M243.109 247.49C243.14 247.498 243.172 247.498 243.203 247.49C243.226 247.474 243.245 247.451 243.257 247.425C243.27 247.399 243.275 247.37 243.274 247.342C243.274 247.264 243.101 245.315 242.319 244.673C242.227 244.592 242.118 244.532 242.001 244.496C241.883 244.461 241.76 244.452 241.638 244.47C241.518 244.469 241.402 244.51 241.309 244.586C241.217 244.662 241.153 244.767 241.129 244.884C240.989 245.667 242.335 247.115 243.039 247.498L243.109 247.49ZM241.748 244.759C241.882 244.757 242.013 244.804 242.115 244.892C242.584 245.504 242.857 246.243 242.898 247.013C242.202 246.481 241.333 245.37 241.427 244.924C241.427 244.924 241.427 244.783 241.662 244.759H241.732H241.748Z"
                    fill="#0976CB" />
                <path d="M250.38 247.248H244.197L244.135 232.942H250.317L250.38 247.248Z" fill="#7F3E3B" />
                <path d="M293.414 241.958L287.466 243.046L283.201 228.967L289.149 227.879L293.414 241.958Z"
                    fill="#7F3E3B" />
                <path
                    d="M286.339 241.465L293.093 239.899C293.21 239.87 293.334 239.884 293.443 239.937C293.551 239.989 293.638 240.079 293.688 240.189L295.926 245.299C295.98 245.427 296.005 245.565 295.999 245.703C295.994 245.842 295.958 245.977 295.895 246.1C295.831 246.223 295.742 246.331 295.632 246.416C295.523 246.501 295.396 246.56 295.261 246.59C292.913 247.107 291.723 247.232 288.75 247.937C286.926 248.367 283.201 249.455 280.673 250.05C278.146 250.644 277.332 248.203 278.326 247.702C282.755 245.581 284.226 243.789 285.439 242.067C285.658 241.766 285.977 241.552 286.339 241.465Z"
                    fill="#263238" />
                <path
                    d="M244.268 246.528H251.013C251.134 246.528 251.251 246.57 251.344 246.647C251.437 246.724 251.5 246.832 251.522 246.95L252.532 252.429C252.556 252.564 252.55 252.703 252.514 252.836C252.478 252.968 252.414 253.091 252.325 253.196C252.236 253.301 252.124 253.385 251.999 253.441C251.874 253.498 251.738 253.526 251.6 253.524C249.167 253.485 245.661 253.344 242.601 253.344C239.024 253.344 237.749 253.54 233.554 253.54C231.018 253.54 230.424 250.973 231.488 250.746C236.356 249.682 238.484 249.572 242.765 246.997C243.213 246.706 243.733 246.544 244.268 246.528Z"
                    fill="#263238" />
                <path
                    d="M277.246 36.8745C283.013 40.9361 284.751 51.3914 284.751 51.3914L271.682 58.012C269.615 53.8798 268.014 49.5307 266.908 45.0447C265.507 38.8623 273.145 33.9946 277.246 36.8745Z"
                    fill="#EF9517" />
                <path opacity="0.2"
                    d="M270.711 43.542L275.86 55.8755L271.681 58.0119C270.386 55.3935 269.257 52.6961 268.301 49.9357L270.711 43.542Z"
                    fill="black" />
                <path
                    d="M238.813 63.9831C238.86 70.8777 239.275 79.4547 240.3 90.0978H273.333C273.732 85.2458 271.705 62.371 276.627 39.4649C276.714 39.0615 276.718 38.6446 276.638 38.2398C276.558 37.835 276.396 37.4507 276.162 37.1107C275.929 36.7707 275.628 36.482 275.279 36.2624C274.929 36.0428 274.539 35.8969 274.131 35.8337C271.539 35.4189 268.926 35.1394 266.305 34.9963C261.626 34.7262 256.936 34.7262 252.258 34.9963C250.37 35.1599 248.492 35.4212 246.631 35.7789C245.247 36.0601 243.971 36.7264 242.949 37.7011C241.927 38.6758 241.202 39.9192 240.856 41.2883C239.828 45.5089 239.222 49.8212 239.048 54.1617C238.899 56.979 238.79 60.2424 238.813 63.9831Z"
                    fill="#EF9517" />
                <path opacity="0.2"
                    d="M249.237 51.0393C247.054 52.6044 242.875 53.387 239.064 53.7079C239.126 52.6983 239.205 51.7436 239.291 50.8436C240.316 50.3037 241.302 49.8106 242.233 49.3567L249.237 51.0393Z"
                    fill="black" />
                <g opacity="0.2">
                    <path d="M244.135 232.942L244.174 240.893H250.348L250.317 232.942H244.135Z" fill="black" />
                    <path d="M289.149 227.879L283.201 228.975L285.408 236.229L291.355 235.134L289.149 227.879Z"
                        fill="black" />
                </g>
                <path
                    d="M261.594 12.5048C262.494 18.5855 263.331 22.0914 260.984 25.9261C260.238 27.1731 259.166 28.1925 257.883 28.874C256.6 29.5555 255.155 29.8731 253.704 29.7925C252.253 29.7119 250.852 29.2362 249.653 28.4168C248.453 27.5973 247.5 26.4654 246.897 25.1435C244.479 20.088 243.407 10.9866 248.705 7.14415C249.829 6.28488 251.159 5.73647 252.562 5.55402C253.964 5.37157 255.39 5.56151 256.697 6.10474C258.003 6.64797 259.143 7.52538 260.003 8.6488C260.862 9.77223 261.411 11.1021 261.594 12.5048Z"
                    fill="#7F3E3B" />
                <path
                    d="M248.947 16.95C246.654 15.9405 246.873 12.3328 247.57 10.8145C244.917 11.7223 241.082 12.2467 240.394 9.54677C239.705 6.84686 242.045 5.98602 240.613 3.67741C239.181 1.3688 243.743 -1.14329 245.872 2.11225C248 5.36778 253.259 3.91218 256.656 0.805334C259.129 -1.45633 262.744 1.4627 261.312 4.41303C261.312 4.41303 268.747 3.93566 267.268 8.98331C265.788 14.031 263.699 14.2031 264.176 17.7952C264.505 20.2212 264.787 25.1514 261.876 25.8479C258.354 26.654 249.573 23.5315 248.947 16.95Z"
                    fill="#263238" />
                <path opacity="0.2"
                    d="M248.901 23.5315C248.227 24.0167 247.531 24.5332 246.819 25.0967L246.858 25.1827C247.553 26.6492 248.667 27.8773 250.059 28.7122C250.505 28.2818 250.755 26.834 251.585 26.8575C254.066 26.9201 258.863 25.8871 259.661 23.0854L248.901 23.5315Z"
                    fill="black" />
                <path
                    d="M250.833 16.8639C251.005 18.216 250.799 19.5891 250.239 20.8316C249.534 22.2637 248.423 21.3481 247.993 19.5873C247.601 18.0222 247.5 15.3536 248.517 14.571C249.534 13.7884 250.638 15.0796 250.833 16.8639Z"
                    fill="#7F3E3B" />
                <path
                    d="M252.023 90.1213C252.023 90.1213 257.211 137.327 260.373 155.013C263.84 174.382 281.792 235.173 281.792 235.173L292.858 232.825C292.858 232.825 280.501 175.947 278.811 156.774C277.003 135.957 273.333 90.1213 273.333 90.1213H252.023Z"
                    fill="#002B45" />
                <path
                    d="M279.319 231.455C279.272 231.455 281.198 235.494 281.198 235.494L293.68 232.825L292.795 229.053L279.319 231.455Z"
                    fill="#263238" />
                <path opacity="0.2"
                    d="M255.803 106.947C263.848 108.512 261.821 138.759 260.248 154.309C258.338 143.352 255.732 122.168 253.987 107.166C254.549 106.886 255.19 106.809 255.803 106.947Z"
                    fill="black" />
                <path
                    d="M240.3 90.1213C240.3 90.1213 237.67 136.294 237.616 155.146C237.553 174.71 241.92 238.882 241.92 238.882H252.391C252.391 238.882 255.208 175.877 256.413 156.633C257.728 135.652 262.024 90.1135 262.024 90.1135L240.3 90.1213Z"
                    fill="#002B45" />
                <path
                    d="M240.073 234.727C240.026 234.727 240.495 239.07 240.495 239.07H253.22L253.713 235.157L240.073 234.727Z"
                    fill="#263238" />
                <path
                    d="M253.392 46.5628C252.14 46.6802 251.084 46.7506 249.933 46.7819C248.783 46.8133 247.687 46.8367 246.568 46.8289C244.315 46.8289 242.061 46.688 239.791 46.4376C237.488 46.2001 235.202 45.8079 232.951 45.2637C230.568 44.7035 228.253 43.8821 226.049 42.8143L225.791 42.6812C225.308 42.4453 224.876 42.116 224.52 41.7125C224.165 41.3089 223.893 40.839 223.719 40.3298C223.546 39.8206 223.476 39.2822 223.512 38.7456C223.547 38.209 223.689 37.6848 223.928 37.2032C224.037 36.9811 224.166 36.769 224.312 36.5693C225.246 35.3314 226.308 34.1955 227.481 33.1807C228.538 32.2416 229.594 31.4042 230.659 30.606C232.787 29.0017 234.963 27.5852 237.146 26.2079C239.33 24.8306 241.56 23.5549 243.806 22.295C246.052 21.035 248.314 19.9003 250.661 18.8125C251.177 18.6062 251.75 18.5896 252.277 18.7658C252.804 18.942 253.252 19.2995 253.54 19.7747C253.828 20.2499 253.939 20.8119 253.851 21.3608C253.764 21.9097 253.485 22.4099 253.064 22.7724C251.1 24.2827 249.073 25.7775 247.108 27.3113C245.144 28.8452 243.195 30.3556 241.247 31.9051C239.298 33.4546 237.443 35.0354 235.667 36.6006C234.783 37.3831 233.93 38.1657 233.171 39.0187C232.488 39.7124 231.87 40.4668 231.324 41.2726L229.594 35.0119C231.299 35.6445 233.06 36.1136 234.853 36.4128C236.773 36.7388 238.709 36.9635 240.652 37.0858C242.632 37.211 244.635 37.2814 246.647 37.2814C247.656 37.2814 248.666 37.2814 249.667 37.2814C250.669 37.2814 251.726 37.2032 252.602 37.1562H252.704C253.887 37.155 255.028 37.6008 255.897 38.4046C256.766 39.2083 257.299 40.3105 257.39 41.4907C257.481 42.6708 257.123 43.8418 256.388 44.7692C255.652 45.6967 254.594 46.3121 253.424 46.4924L253.392 46.5628Z"
                    fill="#7F3E3B" />
                <path
                    d="M257.775 17.5917L253.862 18.4603C253.862 18.4603 248.627 17.8108 247.429 21.755L250.56 24.4001L255.349 25.1827C256.107 25.3006 256.882 25.1746 257.564 24.8227L258.785 24.1888C259.062 24.046 259.299 23.8357 259.474 23.5772C259.649 23.3188 259.757 23.0205 259.786 22.7098L260.076 19.5794C260.105 19.283 260.062 18.984 259.951 18.7075C259.84 18.431 259.665 18.1851 259.44 17.9904C259.214 17.7957 258.946 17.6579 258.656 17.5885C258.366 17.5191 258.064 17.5202 257.775 17.5917Z"
                    fill="#7F3E3B" />
                <path
                    d="M250.137 51.1331C243.555 52.9643 236.051 50.6792 236.051 50.6792L238.813 35.9432C238.813 35.9432 245.348 35.3641 249.433 35.278C259.943 35.0276 262.001 47.8463 250.137 51.1331Z"
                    fill="#EF9517" />
            </svg>
            <h1 class="header">401 (Unauthorized)</h1>
            <h3 class="subHeader">Access Forbidden</h3>
            <p class="content">You have tried to access a page you did not have prior authorization for. <br>Please
                connect with Admin.</p>
            <button pButton pRipple type="button" label="Back to Login" class="cozmos-secondary-button"
                (click)="redirectToLogin()"></button>
        </div>
    </div>
</div>