
<div class="footer flex justify-content-between">
    <div class="col-6 cozmosfooter">
        <p class="footerText">{{dateTime}} &nbsp;
            {{ time }} (UTC)</p>


    </div>
    <div class="col-6 cozmosfooter">
        <p class="copyright">Copyright ©{{copyRightYear}} DFDS All Rights Reserved</p>
    </div>
</div>

