import { Component, OnInit } from '@angular/core';
import { AppComponent } from '../../../../app.component';
import { StorageService } from '../../service';
import { MenuItem } from 'primeng/api';
import { Subscription } from 'rxjs';
import { CommonService } from '../../service/common/common.service';
import { environment } from 'src/environments/environment';
@Component({
    selector: 'app-menu',
    templateUrl: './app.menu.component.html',
    styleUrls: ['./app.menu.component.scss']
})
export class AppMenuComponent implements OnInit {

    model: {}[];
    items: MenuItem[];
    priviligeList: any;
    userDetailsSubscription: Subscription;
    private commonService: CommonService;
    constructor(public app: AppComponent, public storageService: StorageService, _commonService: CommonService) {
        this.commonService = _commonService;
    }
    ngOnInit() {
        this.userDetailsSubscription = this.commonService.getPrivelegeEvent().subscribe((res) => {
            this.priviligeList = res?.privileges;
            this.items = [
                {
                    label: 'Document Tracker',
                    icon: 'fas fa-file-signature',
                    routerLink: ['document'],
                    visible: this.priviligeList?.includes('VIEW_ALL_DOC_TRACKER_LISTS'),
                    tooltipOptions: {
                        tooltipLabel: "Document Tracker"
                    },
                    routerLinkActiveOptions:{
                        exact: true 
                       }
                },
                {
                    label: 'E-Tracker',
                    icon: 'far fa-mail-bulk',
                    routerLink: ['email-tracker'],
                    visible: this.priviligeList?.includes('VIEW_ALL_E_TRACKER_EMAILS'),
                    tooltipOptions: {
                        tooltipLabel: "E-Tracker"
                    },
                    routerLinkActiveOptions:{
                        exact: true 
                       }
                },
                {
                    label: 'Dashboard',
                    icon: 'fas fa-poll',
                    routerLink:['dashboard'],
                    tooltipOptions: {
                        tooltipLabel: "Dashboard"
                    },
                    visible:true,
                    routerLinkActiveOptions:{
                        exact: true 
                       }
                },
                {
                    label: 'Master Data',
                    icon: 'fas fa-list',
                    visible: this.priviligeList?.includes('VIEW_LEGAL_ENTITY_LIST') || this.priviligeList?.includes('VIEW_VENDOR_LIST') || this.priviligeList?.includes('VIEW_CURRENCY_LIST') || this.priviligeList?.includes('VIEW_PO_LIST') || this.priviligeList?.includes('VIEW_VAT_ALIAS_LIST') || this.priviligeList?.includes('VIEW_NAME_ALIAS_LIST') || this.priviligeList?.includes('VIEW_CITY_ALIAS_LIST') || this.priviligeList?.includes('VIEW_ZIP_ALIAS_LIST') || this.priviligeList?.includes('VIEW_AP_INVOICE_LIST') || this.priviligeList?.includes('VIEW_CURRENCY_EQUIVALENT_LIST'),
                    tooltipOptions: {
                        tooltipLabel: "Master Data"
                    },
                    expanded:window.location.href.includes('master-data/le-management')||window.location.href.includes('master-data/po-management') ||
                             window.location.href.includes('master-data/vendor-management')||window.location.href.includes('master-data/ap-invoice-export') || window.location.href.includes('master-data/currency-management') 
                            ||window.location.href.includes('master-data/vat-alias')|| window.location.href.includes('master-data/name-alias')||window.location.href.includes('master-data/zipcode-alias')||window.location.href.includes('master-data/city-alias')||window.location.href.includes('master-data/currencyalias-management'),
                    id:'master',
                    target: 'self',
                    items: [
                        {
                            label: 'Legal Entities',
                            icon: 'fas fa-file-contract',
                            routerLink: ['master-data/le-management'],
                            visible: this.priviligeList?.includes('VIEW_LEGAL_ENTITY_LIST'),
                            tooltipOptions: {
                                tooltipLabel: "Legal Entities"
                            },
                            routerLinkActiveOptions:{
                                exact: true 
                               }
                        },
                        {
                            label: 'Purchase Orders',
                            icon: 'fas fa-money-check-edit-alt',
                            routerLink: ['master-data/po-management'],
                            visible: this.priviligeList?.includes('VIEW_PO_LIST'),
                            tooltipOptions: {
                                tooltipLabel: "Purchase Orders"
                            },
                            routerLinkActiveOptions:{
                                exact: true 
                               }
                        },
                        {
                            label: 'Vendors',
                            icon: 'fas fa-inventory',
                            routerLink: ['master-data/vendor-management'],
                            visible: this.priviligeList?.includes('VIEW_VENDOR_LIST'),
                            tooltipOptions: {
                                tooltipLabel: "Vendors"
                            },
                            routerLinkActiveOptions:{
                                exact: true 
                               }
                        },
                        {
                            label: 'AP Invoices',
                            icon: 'fas fa-file-invoice',
                            routerLink: ['master-data/ap-invoice-export'],
                            visible: this.priviligeList?.includes('VIEW_AP_INVOICE_LIST'),
                            tooltipOptions: {
                                tooltipLabel: "AP Invoices"
                            },
                            routerLinkActiveOptions:{
                                exact: true 
                               }
                        }  ,
                        {
                            label: 'Currencies',
                            icon: 'fal fa-rupee-sign',
                            routerLink: ['master-data/currency-management'],
                            visible: this.priviligeList?.includes('VIEW_CURRENCY_LIST'),
                            tooltipOptions: {
                                tooltipLabel: "Currencies"
                            },
                            routerLinkActiveOptions:{
                                exact: true 
                               }
                        },
                        {
                            label: 'VAT Alias',
                            icon: 'fas fa-wallet',
                            routerLink: ['master-data/vat-alias'],
                            visible: this.priviligeList?.includes('VIEW_VAT_ALIAS_LIST'),
                            tooltipOptions: {
                                tooltipLabel: "VAT Alias"
                            },
                            routerLinkActiveOptions:{
                                exact: true 
                               }
                        },
                        {
                            label: 'Company Name Alias',
                            icon: 'fas fa-id-card',
                            routerLink: ['master-data/name-alias'],
                            visible: this.priviligeList?.includes('VIEW_NAME_ALIAS_LIST'),
                            tooltipOptions: {
                                tooltipLabel: "Company Name Alias"
                            },
                            routerLinkActiveOptions:{
                                exact: true 
                               }
                        },
                        {
                            label: 'ZIP Code Alias',
                            icon: 'far fa-map-marked-alt',
                            routerLink: ['master-data/zipcode-alias'],
                            visible: this.priviligeList?.includes('VIEW_ZIP_ALIAS_LIST'),
                            tooltipOptions: {
                                tooltipLabel: "ZIP Code Alias"
                            },
                            routerLinkActiveOptions:{
                                exact: true 
                               }
                        },
                        {
                            label: 'City Alias',
                            icon: 'fas fa-city',
                            routerLink: ['master-data/city-alias'],
                            visible: this.priviligeList?.includes('VIEW_CITY_ALIAS_LIST'),
                            tooltipOptions: {
                                tooltipLabel: "City Alias"
                            },
                            routerLinkActiveOptions:{
                                exact: true 
                               }
                        },
                        {
                            label: 'Currency Alias',
                            icon: 'fas fa-sack-dollar',
                            routerLink: ['master-data/currencyalias-management'],
                            visible: this.priviligeList?.includes('VIEW_CURRENCY_EQUIVALENT_LIST'),
                            tooltipOptions: {
                                tooltipLabel: "Currency Alias"
                            },
                            routerLinkActiveOptions:{
                                exact: true 
                               }
                        } 
                    ]
                },
                {
                    label: 'Administration ',
                    icon: 'fas fa-user-shield',
                    id:'admin',
                    visible:this.priviligeList?.includes('VIEW_ALL_DOC_TRACKER_LISTS') || this.priviligeList?.includes('EMAIL_TEMPLATE_LIST_VIEW') || this.priviligeList?.includes('VIEW_APPLICATION_CONFIGURATION') || this.priviligeList?.includes('VIEW_USER_LIST') || this.priviligeList?.includes('VIEW_ANNOUNCEMENT') || this.priviligeList?.includes('VIEW_ROLE_LIST') || this.priviligeList?.includes('VIEW_PO_PREFIX_LIST') || this.priviligeList?.includes('VIEW_ERROR_CODE_LIST') || this.priviligeList?.includes('VIEW_DOCUMENT_IDENTIFIER_LIST') || this.priviligeList?.includes('VIEW_CUSTOM_PROFILE_LIST') || this.priviligeList?.includes('VIEW_ACTION_LIST') || this.priviligeList?.includes('VIEW_USER_ACTIVITY_LIST') || this.priviligeList?.includes('BLACKLIST_EMAIL_LIST_VIEW'),
                    tooltipOptions: {
                        tooltipLabel: "Administration"
                    },
                    expanded:window.location.href.includes('administration/user-management')||window.location.href.includes('administration/role-management') ||
                    window.location.href.includes('administration/poprefix-management')||window.location.href.includes('administration/docstatus-management') || window.location.href.includes('administration/errocodelist') 
                   ||window.location.href.includes('administration/languageintent-alias')||window.location.href.includes('administration/customprofile-list')||window.location.href.includes('administration/document-type')||window.location.href.includes('administration/announcement')
                   ||window.location.href.includes('administration/app-config')||window.location.href.includes('administration/email-template')||window.location.href.includes('administration/manual-email-template')||window.location.href.includes('administration/blacklist-email')
                   ||window.location.href.includes('administration/activity-log')  ||window.location.href.includes('administration/data-change-logger'),
                    items: [
                        {
                            label: 'Users',
                            icon: 'fas fa-users-cog',
                            routerLink: ['administration/user-management'],
                            visible: this.priviligeList?.includes('VIEW_USER_LIST'),
                            tooltipOptions: {
                                tooltipLabel: "Users"
                            },
                            routerLinkActiveOptions:{
                                exact: true 
                               }
                        },
                        {
                            label: 'Roles',
                            icon: 'fas fa-user-check',
                            routerLink: ['administration/role-management'],
                            visible: this.priviligeList?.includes('VIEW_ROLE_LIST'),
                            tooltipOptions: {
                                tooltipLabel: "Roles"
                            },
                            routerLinkActiveOptions:{
                                exact: true 
                               }
                        },
                        {
                            label: 'PO Prefix',
                            icon: 'fas fa-money-check',
                            routerLink: ['administration/poprefix-management'],
                            visible: this.priviligeList?.includes('VIEW_PO_PREFIX_LIST'),
                            tooltipOptions: {
                                tooltipLabel: "PO Prefix "
                            },
                            routerLinkActiveOptions:{
                                exact: true 
                               }
                        },
                        {
                            label: 'Document Actions',
                            icon: 'fas fa-file-alt',
                            routerLink: ['administration/docstatus-management'],
                            visible: this.priviligeList?.includes('VIEW_ACTION_LIST'),
                            tooltipOptions: {
                                tooltipLabel: "Document Actions "
                            },
                            routerLinkActiveOptions:{
                                exact: true 
                               }
                        },
                        {
                            label: 'Error Code List',
                            icon: 'far fa-exclamation-square',
                            routerLink: ['administration/errocodelist'],
                            visible: this.priviligeList?.includes('VIEW_ERROR_CODE_LIST'),
                            tooltipOptions: {
                                tooltipLabel: "Error Code List"
                            },
                            routerLinkActiveOptions:{
                                exact: true 
                               }
                        },
                        {
                            label: 'Language Intent',
                            icon: 'fas fa-language',
                            routerLink: ['administration/languageintent-alias'],
                            visible: this.priviligeList?.includes('VIEW_DOCUMENT_IDENTIFIER_LIST'),
                            tooltipOptions: {
                                tooltipLabel: "Language Intent"
                            },
                            routerLinkActiveOptions:{
                                exact: true 
                               }
                        },
                        {
                            label: 'Custom Profile List',
                            icon: 'fas fa-id-badge',
                            routerLink: ['administration/customprofile-list'],
                            visible: this.priviligeList?.includes('VIEW_CUSTOM_PROFILE_LIST'),
                            tooltipOptions: {
                                tooltipLabel: "Custom Profile List"
                            },
                            routerLinkActiveOptions:{
                                exact: true 
                               }
                        },
                        {
                            label: 'Document Type Management',
                            icon:'fas fa-money-check-edit',
                            routerLink: ['administration/document-type'],
                            visible:  this.priviligeList?.includes('VIEW_ALL_DOC_TRACKER_LISTS'),
                            tooltipOptions: {
                                tooltipLabel: "Document Type Management"
                            },
                            routerLinkActiveOptions:{
                                exact: true 
                               }
                        },
                        {
                            label: 'Announcement',
                            icon: 'fas fa-megaphone',
                            routerLink: ['administration/announcement'],
                            visible: this.priviligeList?.includes('VIEW_ANNOUNCEMENT'),
                            tooltipOptions: {
                                tooltipLabel: "Announcement"
                            },
                            routerLinkActiveOptions:{
                                exact: true 
                               }
                        },
                        {
                            label: 'Application Configuration',
                            icon: 'fas fa-cogs',
                            routerLink: ['administration/app-config'],
                            visible: this.priviligeList?.includes('VIEW_APPLICATION_CONFIGURATION'),
                            tooltipOptions: {
                                tooltipLabel: "Application Configuration"
                            },
                            routerLinkActiveOptions:{
                                exact: true 
                               }
                        },
                        {
                            label: 'Custom Email Template',
                            icon:'fas fa-mail-bulk',
                            title:'Custom Email Template',
                            visible: this.priviligeList?.includes('EMAIL_TEMPLATE_AUTO_LIST_VIEW') ||  this.priviligeList?.includes('EMAIL_TEMPLATE_MANUAL_LIST_VIEW'),
                            items: [
                                {
                                    label: 'Auto Email Template',
                                    icon:'far fa-envelope-square',
                                    routerLink: ['administration/email-template'],
                                    visible: this.priviligeList?.includes('EMAIL_TEMPLATE_AUTO_LIST_VIEW'),
                                    tooltipOptions: {
                                        tooltipLabel: "Auto Email Template"
                                    },
                                    routerLinkActiveOptions:{
                                        exact: true 
                                       }
                                },
                                {
                                    label: 'Manual Email Template',
                                    icon:'fas fa-envelope',
                                    routerLink: ['administration/manual-email-template'],
                                    visible: this.priviligeList?.includes('EMAIL_TEMPLATE_MANUAL_LIST_VIEW'),
                                    tooltipOptions: {
                                        tooltipLabel: "Manual Email Template"
                                    },
                                    routerLinkActiveOptions:{
                                        exact: true 
                                       }
                                },
                            ],
                            expanded:window.location.href.includes('administration/email-template')||window.location.href.includes('administration/manual-email-template'),
                        },
                        {
                            label: 'Blacklist Email Config',
                            icon:'fas fa-ban',
                            routerLink: ['administration/blacklist-email'],
                            visible: this.priviligeList?.includes('BLACKLIST_EMAIL_LIST_VIEW'),
                            tooltipOptions: {
                                tooltipLabel: "Blacklist Email Config"
                            },
                            routerLinkActiveOptions:{
                                exact: true 
                               }
                        },
                        {
                            label: 'User Activity Log',
                            icon: 'fal fa-user-clock',
                            routerLink:['administration/activity-log'],
                            routerLinkActiveOptions:{
                             exact: true 
                            },
                            visible: this.priviligeList?.includes('VIEW_USER_ACTIVITY_LIST'),
                            tooltipOptions: {
                                tooltipLabel: "User Activity Log"
                            }
                        },
                        {
                            label: 'Data Change Logger',
                            icon: 'fas fa-typewriter',
                            routerLink:['administration/data-change-logger'],
                            routerLinkActiveOptions:{
                             exact: true 
                            },
                            visible: this.priviligeList?.includes('VIEW_USER_ACTIVITY_LIST'),
                            tooltipOptions: {
                                tooltipLabel: "Data Change Logger"
                            }
                        },
                        {
                            label: 'Cron Job Logger',
                            icon: 'fal fa-archive',
                            routerLink:['administration/cron-job-logger'],
                            routerLinkActiveOptions:{
                             exact: true 
                            },
                            visible: this.priviligeList?.includes('CRONJOB_LIST_VIEW'),
                            tooltipOptions: {
                                tooltipLabel: "Cron Job Logger"
                            }
                        }
                    ]
                },
                {
                    label: 'EDI Management',
                   icon:"fas fa-file-code",
                    id:'edi',
                    tooltipOptions: {
                        tooltipLabel: "EDI Management"
                    },
                    expanded:window.location.href.includes('edi/trading-partner-setup')||window.location.href.includes('edi/code-list'),
                    visible:this.priviligeList.includes('TRADING_PARTNER_LIST_VIEW') || this.priviligeList.includes('TRADING_PARTNER_RULE_LIST_VIEW') || this.priviligeList.includes('CODELIST_LIST_VIEW') || this.priviligeList.includes('CONNECTIVITY_LIST_VIEW'),
                    items: [
                        {
                            label: 'Trading Partner Setup',
                            icon: 'far fa-handshake-alt',
                            routerLink: ['edi/trading-partner-setup'],
                            visible:this.priviligeList.includes('TRADING_PARTNER_LIST_VIEW'),
                            tooltipOptions: {
                                tooltipLabel: "Trading Partner Setup"
                            },
                            routerLinkActiveOptions:{
                                exact: true 
                               }
                        },
                        {
                            label: 'Codelist Setup',
                            icon: 'far fa-code-branch ',
                            routerLink: ['edi/code-list'],
                            visible:this.priviligeList.includes('CODELIST_LIST_VIEW'),
                            tooltipOptions: {
                                tooltipLabel: "Codelist Setup"
                            },
                            routerLinkActiveOptions:{
                                exact: true 
                               }
                        },
                        // {
                        //     label: 'Connectivity',
                        //     icon: 'fad fa-router ',
                        //     routerLink: ['edi/connectivity'],
                        //     visible:this.priviligeList.includes('CONNECTIVITY_LIST_VIEW'),
                        //     tooltipOptions: {
                        //         tooltipLabel: "Connectivity"
                        //     },
                        //     routerLinkActiveOptions:{
                        //         exact: true 
                        //        }
                        // }
                    ]
                },

                {
                    label: 'Connectivity',
                    icon:'fad fa-router',
                    routerLink: ['connectivity'],
                    visible:this.priviligeList.includes('CONNECTIVITY_LIST_VIEW'),
                    tooltipOptions: {
                        tooltipLabel: "Connectivity"
                    },
                    routerLinkActiveOptions:{
                        exact: true 
                       }
                },
                

                {
                    label: 'E-XML Management',
                    icon:'fas fa-laptop-code',
                    routerLink: ['xml'],
                    visible:this.priviligeList.includes('EINVOICE_PARTNER_LIST_VIEW') ,
                    tooltipOptions: {
                        tooltipLabel: "E-XML Management"
                    },
                    routerLinkActiveOptions:{
                        exact: true 
                       }
                },
            ]
        })
    }
    getPriviligesList() {
        const userDetails = JSON.parse(localStorage.getItem('userDetails'));
        if (userDetails) {
            return userDetails?.privileges;
        }
    }
    ngOnDestroy() {
        this.userDetailsSubscription.unsubscribe();
    }
}

