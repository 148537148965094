import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MsalService } from '@azure/msal-angular';
import { StorageService } from 'src/app/modules/common/service';
import { SubSink } from 'subsink';


@Component({
  selector: 'app-login',
  templateUrl: './app.login.component.html',
  styleUrls: ['../auth.component.scss']
})
export class AppLoginComponent {
  private subscription = new SubSink();
  userForm: FormGroup;
  loginClicked: boolean = false;
  showPassword: boolean = false;


  async ngOnInit() {
    await this.login();
  }

  constructor(
    private formBuilder: FormBuilder,
    private storageService: StorageService,
    private msalSarervice: MsalService) {
    this.storageService.removeItem('email');
    this.userForm = this.formBuilder.group({
      keepSignIn: [false]
    });
  }

  async login() {
    this.loginClicked = true;
    this.subscription.unsubscribe();
    await this.msalSarervice.loginRedirect();
  }



  ngOnDestroy() {
    this.subscription.unsubscribe();
  }


}