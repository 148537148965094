import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MenuItem, MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { AppBase } from 'src/app/app-base';
import { SubSink } from 'subsink';
import { FilterService, INavigationData, MasterdataService, NavigationService } from '../../service';
import { AppMainComponent } from '../main-panel/app.main.component';
import { Router } from '@angular/router';
import { Settings } from '../../lib/app.constant';
import { CommonService } from '../../service/common/common.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-gridconfig',
  templateUrl: './gridconfig.component.html',
  styleUrls: ['./gridconfig.component.scss']
})

export class GridconfigComponent  {
  invoiceFrom=Settings.DocumentProvider.VENDOR;
  fileType=Settings.FileType.PDF;
  fileTypeList = [];
  invoiceFromList = [{ name: 'Vendor', key: 1 }, { name: 'InterCompany', key: 2 }];
  public router : Router;
  isVelocityDocument:boolean=false;
  overlayVisible: boolean = true;
  maxFileLimit:number;
  maxFileSize:number=15000000;
  private subscription = new SubSink();
  userDetailsSubscription:Subscription;
  currentNav: INavigationData;
private messageService:MessageService;
private navigationService: NavigationService;
private filterService: FilterService;
private commonService: CommonService;
  displayErrorMessage: string;
  myFiles: any[]=[];
  displayDocuments: boolean;
  uploadedFiles: any[]=[];
  acceptCriteria: string;
  privilegeList: any;
  configList: any;
  maxFileLimitPDF: any;
  maxFileLimitEDI: any;
  masterService:MasterdataService;
  countryList: any;
  country:any;
  showCountry: boolean;
  maxFileLimitXML: any;
  disableUpload: any;
  showReportDownload: boolean;
  invoiceDateRange: any;
  constructor(_masterService:MasterdataService,_router: Router,public appMain: AppMainComponent, _navigationService: NavigationService,_filterService: FilterService,_messageService:MessageService,_commonService: CommonService) {
    this.messageService=_messageService;
    this.navigationService=_navigationService;
    this.filterService=_filterService;
    this.router = _router;
    this.commonService=_commonService;
    this.masterService=_masterService;
  }
  items: MenuItem[];
  ngOnInit() {
      this.fileTypeList=[{ name: 'PDF', key: 1 ,readonly:false}, { name: 'EDI', key: 2,readonly:false },{ name: 'XML', key: 3 ,readonly:false}]

    this.userDetailsSubscription = this.commonService.getPrivelegeEvent().subscribe((res) => {
      this.privilegeList=res?.privileges;    
      this.configList=res?.configuration;    
      })
      this.items = [
        {
        icon: 'fas fa-file-download', tooltipOptions: {
          tooltipLabel: "Export Excel"
        }, command: () => this.onClickExportExcel(),
        visible: this.isExportAllowed()
      },
      {
        icon: 'fas fa-file-upload',
        tooltipOptions: {
          tooltipLabel: "Upload Document"
        },
        visible: this.privilegeList?.includes('UPLOAD_DOC_TRACKER_FILE') && (this.router.url.includes('user/document') || this.currentNav.route.includes('user/email-tracker')),
        command: () => this.onClickUploadInvoice()
      },
      {
        icon: 'fad fa-save', tooltipOptions: {
          tooltipLabel: "IBAN Report Download"
        }, command: () => this.onCickReportDownload(),
        visible: this.privilegeList?.includes('DOWNLOAD_IBAN_REPORT_ATTACHMENT') && (this.router.url.includes('user/document'))
      },
      ];
    this.subscription.add(
      this.navigationService.$currentNav.subscribe(
        (selectedNav: INavigationData) => {
          if (selectedNav) {
            this.appMain.configActive = false;
            this.currentNav = selectedNav;
            this.getPrivilegeList();
            this.getConfiguration();
            this.onChangeUpdateAcceptCriteria(null);
            this.items = [{
              id:'export',
              icon: 'fas fa-file-download', tooltipOptions: {
                tooltipLabel: "Export Excel"
              }, command: () => this.onClickExportExcel(),
              visible: this.isExportAllowed()
            },
            {  
              id:'upload',
              icon: 'fas fa-file-upload',
              tooltipOptions: {
                tooltipLabel: "Upload Document"
              },
              visible: this.privilegeList?.includes('UPLOAD_DOC_TRACKER_FILE') && (this.currentNav.route.includes('user/document') || this.currentNav.route.includes('user/email-tracker')),
              command: () => this.onClickUploadInvoice()
            },
            {
              icon: 'fad fa-save', tooltipOptions: {
                tooltipLabel: "IBAN Report Download"
              }, command: () => this.onCickReportDownload(),
              visible: this.privilegeList?.includes('DOWNLOAD_IBAN_REPORT_ATTACHMENT') && (this.router.url.includes('user/document'))
            },
            ];
          }
        }
      ))
    this.getCountryList();
  }
  getCountryList() {
    this.masterService.getCountryList().subscribe({
    next:(res)=>{
    this.countryList=res?.results;
    },
    error:()=>{
    this.countryList=[];
    }
    })
  }
  isExportAllowed(){
    if(this.privilegeList?.includes('VIEW_ROLE_LIST') && this.router.url.includes('user/administration/role-management') ||  (this.privilegeList?.includes('VIEW_ACTION_LIST') && this.router.url.includes('user/administration/docstatus-management'))||
    this.privilegeList?.includes('VIEW_ANNOUNCEMENT') && this.router.url.includes('user/administration/announcement') || this.privilegeList?.includes('VIEW_APPLICATION_CONFIGURATION') && this.router.url.includes('user/administration/app-config') || (this.router.url.includes('user/dashboard') && true) || (this.router.url.includes('user/administration/blacklist-email') && this.privilegeList?.includes('BLACKLIST_EMAIL_LIST_VIEW')) || this.router.url.includes('user/administration/cron-job-logger') ){
     return false;
    }else{
      return true;
    }
  }
  getPrivilegeList(){
    var privilegeList = Settings.CommonFunctions.getPrivilegeList();
    if(!privilegeList){
      this.userDetailsSubscription = this.commonService.getPrivelegeEvent().subscribe((res) => {
        this.privilegeList=res?.privileges;       
        })
    }
    else{
      this.privilegeList=privilegeList;
    }
  }
  onCickReportDownload(){
    this.showReportDownload=true;
   }
   onClickDownloadReport(){
     const dates=Settings?.CommonFunctions?.getDatesFromDateRange(this.invoiceDateRange);
    var body= {
       "fileReceiveStartDate": dates?.startDate,
       "fileReceiveEndDate": dates?.endDate
     }
     this.commonService.downloadIBANReport(body).subscribe(
       {
       next: (res: any) => {
       this.showReportDownload=false;
       },
       error: (error) => {
         this.showReportDownload=false;
         if(error?.status==400){
          this.messageService.add({ severity: 'error', summary: 'Error', detail: error?.error?.message??'There is no data to Download' });
         }else{
          this.messageService.add({ severity: 'error', summary: 'Error', detail: error?.error?.message??'Failed to Download IBAN Report' });
         }
    
       }
     }
     )
   }
   onHideIBANReport(){
    this.invoiceDateRange=null;
  }
getConfiguration(){
  this.configList = Settings.CommonFunctions.getConfiguration();
  this.userDetailsSubscription = this.commonService.getPrivelegeEvent().subscribe((res) => {
    this.configList=res?.configuration;       
  })
  const newObject :any={};
  // Iterate through the array and populate the new object
  this.configList.forEach(obj => {
newObject[obj.key] = obj.value;
   });
   if(newObject){
  // Iterate through the array and populate the new object
  this.maxFileLimitPDF=newObject?.MAX_UPLOAD_PDF;
  this.maxFileLimitEDI=newObject?.MAX_UPLOAD_EDI;
  this.maxFileLimitXML=newObject?.MAX_UPLOAD_XML;
this.maxFileLimit=this.maxFileLimitPDF;
this.maxFileSize=newObject?.MAX_FILE_SIZE*1000000;
   }
   else{
    this.maxFileLimit=10;
   }
}
onUploading(event,fileUpload){
  var formData = new FormData();
  for(let file of event.files) {
    this.uploadedFiles.push(file);
}
formData.append('fileType',this.fileType.toString());
formData.append('provider',this.invoiceFrom.toString());
if(this.isVelocityDocument){
formData.append('destination',Settings.SourceSystem.Velocity.toString());
}
if(this.fileType==Settings.FileType.XML){
formData.append('countryId',this.country.toString());
}
if(this.uploadedFiles?.length > 0 && this.uploadedFiles?.length<=this.maxFileLimit){
  this.uploadedFiles.map((e, i) => {
    formData.append(`file`, this.uploadedFiles[i])
  })
this.commonService.postUploadInvoice(formData).subscribe({
  next: (res) => {
    fileUpload._files=[];
    this.uploadedFiles=[];
    event.files=[]
    this.displayDocuments=false;
    this.messageService.add({ severity: 'success', summary: 'Successfully', detail: 'Invoice  has been Uploaded successfully' });
    window.location.reload();
  },
  error: (error) => {
    this.messageService.add({ severity: 'error', summary: 'Something Went Wrong', detail: error?.error?.message});
  }
})
}
else{
this.messageService.add({ severity: 'error', summary: 'Something Went Wrong', detail: 'Please Upload '+ this.maxFileLimit+' files at a time Only'});
}
}
onChangeUpdateAcceptCriteria(fileUpload){ 
  if (this.fileType === Settings.FileType.PDF) {
    this.acceptCriteria = '.pdf'; // Set desired criteria for option 1
    this.showCountry=false;
    this.maxFileLimit=this.maxFileLimitPDF;
    if(fileUpload){
      fileUpload.clear();
    }
    this.clearFile();
    this.disableUpload=false;
  }
   else if (this.fileType === Settings.FileType.EDI) {
    this.acceptCriteria = '.edi,.dat,.txt'; // Set desired criteria for option 2
    this.showCountry=false;
    this.maxFileLimit=this.maxFileLimitEDI??1;
    if(fileUpload){
      fileUpload.clear();
    }
    this.clearFile();
    this.disableUpload=false;
   }
   else if(this.fileType === Settings.FileType.XML){
    this.acceptCriteria = '.xml';
    this.showCountry=true;
    this.maxFileLimit=this.maxFileLimitXML??1;
    if(fileUpload){
      fileUpload.clear();
    }
    this.clearFile();
    if(this.country){
      this.disableUpload=false;
    }else{
      this.disableUpload=true;
    }
   }
}
onChangeCountry(){
  if(this.country){
    this.disableUpload=false;
  }
  else{
    this.disableUpload=true;
  }
}
onClickUploadInvoice(){
  this.displayDocuments=true;
  this.fileType=Settings.FileType.PDF;
  this.acceptCriteria = '.pdf'; // Set desired criteria for option 1
  this.showCountry=false;
  this.maxFileLimit=this.maxFileLimitPDF;
}


  onClickExportExcel() {
    this.filterService.onClickSendExportFilterEvent();
  }

  onConfigButtonClick(event) {

    this.appMain.configActive = !this.appMain.configActive;
    this.appMain.configClick = true;
    event.preventDefault();
  }
  clearFile(){
  this.uploadedFiles=[]; 
  }
  onHideFileUpload(fileUpload){
    this.invoiceFrom=Settings.DocumentProvider.VENDOR;
    this.fileType=Settings.FileType.PDF;
    fileUpload.clear();
    this.uploadedFiles=[];   
    this.isVelocityDocument=false;
  }

  handleFileRemove(event: any,fileUpload) {
    const errorState = fileUpload._files.length <=this.maxFileLimit;
    if (errorState) {
      fileUpload.msgs = [];
    }
  }
  ngOnDestroy(){
    if(this.userDetailsSubscription){
      this.userDetailsSubscription.unsubscribe();
    }
  }
}
