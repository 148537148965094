import { Component, OnInit, ViewChild } from "@angular/core";
import { chatBot } from "../../lib/model/chatBot";
import { ChatGptService } from "../../service/chat-gpt/chat-gpt.service";
import { StorageService } from "src/app/modules/common/service/storage/storage.service";

@Component({
    selector: "chat-bot",
    templateUrl: "./chat-bot.component.html",
    styleUrls: ["./chat-bot.component.scss"],
})
export class ChatBotComponent implements OnInit {
    showChatWindows: boolean=false;
    chatList: chatBot[] = [];
    userInput: string;
    responseMsgLen: number;
    collapsed: boolean = true;
    showTyping: boolean = false;
    queryHistory: any[] = [];
    resultHistory: any[] = [];
    showSuggestionButton: boolean = true;
    constructor(
        private chatGptService: ChatGptService,
        private storageService: StorageService
    ) {

    }

    ngOnInit(): void {
        this.responseMsgLen = 10000;
        this.showTyping = false;
    }

    defaultChatLoad() {
        this.responseMsgLen = 10000;
        this.showTyping = false;
        setTimeout(() => {
            this.chatList = [
                {
                    type: "BOT",
                    name: "Cozmo",
                    message:
                        `Hi ${this.storageService.getItem("userDetails").firstName}, ! I'm Cozmo. Nice to meet you! 👋`,
                },
            ];
            setTimeout(() => {
                this.chatList.push({
                    type: "BOT",
                    name: "Cozmo",
                    message: `How can I help you today?`,
                });
            }, 1000);
        }, 1500);

    }

    onChatHide() {
        this.chatList = [];
        this.queryHistory = [];
        this.resultHistory = [];
        this.showSuggestionButton = true;
        this.collapsed = true;
        this.storageService.updateRefreshClick(false);
    }

    onChatShow() {
        this.storageService.updateRefreshClick(true);
        this.defaultChatLoad();
    }

    shipmentQueriesClicked(query: string) {
        this.sendUserInput(query);
    }

    manualQueriesClicked(query: string) {
        this.sendUserInput(query);
    }

    clearChat() {
        this.chatList = [];
        this.queryHistory = [];
        this.resultHistory = [];
        this.showSuggestionButton = true;
        this.defaultChatLoad();
    }

    collapseChat() {
        let bodyElem = document.querySelector(
            "#chatBotDialog .p-dialog-content"
        );
        let wrapperElem = document.querySelector(
            "#chatBotDialog .bdp-collacible-dialog"
        );
        if (bodyElem.hasAttribute("data-collapse")) {
            this.collapsed = true;
            if (bodyElem) {
                bodyElem.setAttribute("style", "display:block;");
            }
            if (wrapperElem) {
                wrapperElem.setAttribute("style", "height:600px !important;");
            }
            bodyElem.removeAttribute("data-collapse");
        } else {
            this.collapsed = false;
            if (bodyElem) {
                bodyElem.setAttribute("style", "display:none;");
            }
            if (wrapperElem) {
                wrapperElem.setAttribute("style", "height:130px !important;");
            }
            bodyElem.setAttribute("data-collapse", "true");
        }
    }

    keywordResponses: Record<string, string> = {
        "hello": `Hi {user}! I'm Cozmo. Nice to meet you! 👋`,
        "hi": `Hi {user}! I'm Cozmo. Nice to meet you! 👋`,
        "good job": "Thank you so much, {user}. Your encouragement is really meaningful to Cozmos Team really appreciate the feedback.",
        "great job": "Thank you so much, {user}. Your encouragement is really meaningful to Cozmos Team really appreciate the feedback.",
        "manual queries": "What do you want to know about Cozmos?",
        "good morning": "Good morning! How may I assist you on this lovely day?",
        "good afternoon": "Good afternoon! How can I help you?",
        "good evening": "Good evening! How can I assist you during this evening?",
        "good night": "Good night! How can I assist you during this evening?",
        "how are you": "I'm just a computer program, but I'm here and ready to assist you!",
        "what's up": "Not much, just here to help. How can I assist you today?",
        "howdy": "Howdy! What can I do for you?",
        "goodbye": "Goodbye! Have a great day!",
        "thanks": "You're welcome! If you have more questions, feel free to ask.",
        "thank you": "You're welcome! How can I assist you further?",
        "how's it going": "It's going well, thank you! How can I help you today?",
        "what can you do": "I can provide information, answer questions, and assist you with various tasks. How can I assist you right now?",
        "help": "Of course! What do you need help with?",
        "greetings": "Greetings! How may I assist you today?",
        "yo": "Yo! How can I assist you?",
        "hey there": "Hey there! How can I be of service?",
        // Add more keywords and responses as needed
    };
    findResponse(userInput: string, userName: string): string | null {
        for (const keyword in this.keywordResponses) {
            if (userInput.toLowerCase().includes(keyword)) {
                return this.keywordResponses[keyword].replace("{user}", userName);
            }
        }
        return null;
    }
    sendUserInput(userInput: string = "") {
        if (/^\s*$/.test(this.userInput)) {
            // Input is empty or contains only whitespace
            return; // Do nothing if input is empty
        }else{
        this.showTyping = true;
        if (userInput) {
            this.chatList.push({
                type: "USER",
                name: this.storageService.getItem("user"),
                message: userInput,
            });
    
            setTimeout(() => {
                const chatBody = document.querySelector("#chatBotDialog .p-dialog-content");
                if (chatBody) {
                    chatBody.scrollTop = chatBody.scrollHeight;
                }
            }, 200);
    
            this.userInput = "";
    
            const response = this.findResponse(userInput,this.storageService.getItem("userDetails")?.firstName);
    
            if (response !== null) {
                setTimeout(() => {
                    this.showTyping = false;
                    this.chatList.push({
                        type: "BOT",
                        name: "Cozmo",
                        message: response,
                    });
    
                    setTimeout(() => {
                        const chatBody = document.querySelector("#chatBotDialog .p-dialog-content");
                        if (chatBody) {
                            chatBody.scrollTop = chatBody.scrollHeight;
                        }
                    }, 200);
                }, 1500);
            } 
            else {
                            let requestBody = {
                                query: userInput,
                                queryHistory: this.queryHistory,
                                resultHistory: this.resultHistory
                            };
                            this.chatGptService
                            .chatGptResponse(requestBody).subscribe({
                                next:(res)=>{
                                    if (res.result.length > 0) {
                                                    setTimeout(() => {
                                                        this.showTyping = false;
                                                        this.chatList.push({
                                                            type: "BOT",
                                                            name: "Cozmo",
                                                            message: res.result.includes(`don't know`) ? `I apologize, but I don't have the information to answer your question at the moment. My knowledge is based on a specific set of data, and it's possible that I haven't been trained on or updated with the specific information you're looking for. Is there anything else I can assist you with?` : res.result,
                                                        });
                                                        setTimeout(() => {
                                                            const chatBody = document.querySelector("#chatBotDialog .p-dialog-content");
                                                            if (chatBody) {
                                                                chatBody.scrollTop = chatBody.scrollHeight;
                                                            }
                                                        }, 200);
                                                        this.queryHistory = [...res.queryHistory];
                                                        this.resultHistory = [...res.resultHistory]
                        
                                                    }, 1000);
                                                }
                                },
                                error:()=>{
                                    setTimeout(() => {
                                        this.showTyping = false;
                                        this.chatList.push({
                                            type: "BOT",
                                            name: "Cozmo",
                                            message: `Connection timed out. Unable to establish a connection... Please Retry Later!`,
                                        });
                                        setTimeout(() => {
                                            const chatBody = document.querySelector("#chatBotDialog .p-dialog-content");
                                            if (chatBody) {
                                                chatBody.scrollTop = chatBody.scrollHeight;
                                            }
                                        }, 200);
                                    }, 1000);
                                }
                            })
                        }
        }
    }
    }
    
    showDialog() {
        this.showChatWindows = true;
    }

    onEnter() {
        this.sendUserInput(this.userInput);
    }
}
