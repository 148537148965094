
<ng-container>
    <div *ngIf="root">
        <span class="layout-menuitem-text">{{item.label}}</span>
    </div>
    <a [attr.href]="item.url" (click)="itemClick($event)" *ngIf="!item.routerLink || item.items"
        (keydown.enter)="itemClick($event)" [attr.target]="item.target" [attr.tabindex]="0" [ngClass]="item.class"
        (mouseenter)="onMouseEnter()" pRipple>
        <i [ngClass]="item.icon" class="layout-menuitem-icon"></i>
        <span class="layout-menuitem-text">{{item.label}}</span>
        <i class="pi pi-fw pi-angle-down layout-submenu-toggler" *ngIf="item.items"></i>
    </a>
    <a (click)="itemClick($event)" *ngIf="item.routerLink && !item.items" [routerLink]="item.routerLink"
        routerLinkActive="active-menuitem-routerlink" [routerLinkActiveOptions]="{exact: true}"
        [attr.target]="item.target" [attr.tabindex]="0" [ngClass]="item.class" (mouseenter)="onMouseEnter()" pRipple>
        <i [ngClass]="item.icon" class="layout-menuitem-icon"></i>
        <span class="layout-menuitem-text">{{item.label}}</span>
        <i class="pi pi-fw pi-angle-down layout-submenu-toggler" *ngIf="item.items"></i>
    </a>
    <div class="layout-menu-tooltip">
        <div class="layout-menu-tooltip-arrow"></div>
        <div class="layout-menu-tooltip-text">{{item.label}}</div>
    </div>
    <ul *ngIf="(item.items && root) || (item.items && active)"
        [@children]="root ? 'visible' : active ? 'visibleAnimated' : 'hiddenAnimated'">
        <ng-template ngFor let-child let-i="index" [ngForOf]="item.items">
            <li app-menuitem [item]="child" [index]="i" [parentKey]="key" [class]="child.badgeClass"></li>
        </ng-template>
    </ul>
</ng-container>
