import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  private isRefreshCalled = new BehaviorSubject<any>({ flag: false, from: '' });
  public $isRefreshCalled = this.isRefreshCalled.asObservable();
  constructor() { }

  public setItem(key: any, value: any) {
    localStorage.setItem(key, JSON.stringify(value));
  }

  public getItem(key: any) {
    const item: string = localStorage.getItem(key);
    try {
      if (item) {
        return JSON.parse(item);
      }
    } catch (error) {
    }
    return null;
  }

  public removeItem(key: any) {
    localStorage.removeItem(key);
  }
  public updateRefreshClick(data: boolean, from: string = '') {
    this.isRefreshCalled.next({ flag: data, from: from });
  }


}
