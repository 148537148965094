import { Injectable } from '@angular/core';
import {
  HttpResponse,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize, map, tap } from 'rxjs/operators';
import { CommonService } from '../../../service/common/common.service';

@Injectable({
  providedIn: 'root'
})
export class ApiResponseInterceptor implements HttpInterceptor {

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          //if file is coming as response 
          if (event.body instanceof Blob) {
            const fileName = this.getFileName(event.headers);
            this.downloadFile(event.body, fileName);
            return event;
          }
          else {
            const response = event as HttpResponse<any>;
            const body = response.body;
            if (body) {
              // Convert snake_case to camelCase recursively
              const camelCaseBody = this.convertToCamelCase(body);
              // Clone the response and replace the body with the camelCase version
              return response.clone({ body: camelCaseBody });
            }
          }
        }
        return event;
      })
      ,
      finalize(()=>{
CommonService.hideLoader();
      })
    );
  }

    //convert snake case object to camel case
  convertToCamelCase(obj: any): any {
    if (obj === null || obj === undefined) {
      return obj;
    }
    if (Array.isArray(obj)) {
      return obj.map((item) => this.convertToCamelCase(item));
    }
    if (typeof obj !== 'object') {
      return obj;
    }
    const camelCaseObj: any = {};
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const value = obj[key];
        const camelCaseKey = this.snakeCaseToCamelCase(key);
        camelCaseObj[camelCaseKey] = this.convertToCamelCase(value);
      }
    }
    return camelCaseObj;
  }

  //convert snake case string to camel case
  snakeCaseToCamelCase(snakeCaseString: string): string {
    return snakeCaseString.replace(/_([a-z])/g, (match, letter) => letter.toUpperCase());
  }

  //download the file 
  downloadFile(blob: Blob, fileName: string): void {
    const downloadUrl = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = downloadUrl;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(downloadUrl);
  }

  //to get file Name from content Disposition if there is any
  getFileName(headers: any): string {
    const contentDisposition = headers.get('content-disposition');
    if (contentDisposition) {
      const fileNameMatch = contentDisposition.match(/filename=(.*)$/);
      const fileName = fileNameMatch[1].trim().replace(/['"]/g, '');
      return fileNameMatch ? fileName : 'download';
    }
    return 'Attachement';
  }

}
