import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { Settings } from '../lib/app.constant';

@Pipe({
  name: 'date'
})
export class DatePipe implements PipeTransform {

  transform(value: unknown, ...args: unknown[]): unknown {
    var momentDate = moment(value);
    var isValidDate = momentDate.isValid();
    if (isValidDate) {
      var Date = moment.utc(value).format(Settings.DateFormats.dateFormatFrontend);
      return Date;
    }
    else {
      return "";
    }
  }

}
