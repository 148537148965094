import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class MasterdataService {
  private http:HttpClient
  constructor(_http:HttpClient) {
    this.http=_http;
   }
getAPInvoiceExport(body,isExport:boolean=false){
if(!isExport){
  return this.http.post(environment.baseApiUrl+'doctracker/ap-invoice/list',body)
}
else{
  return this.http.post(environment.baseApiUrl+'doctracker/ap-invoice/list',body,{observe:'response',responseType:'blob'})
}
}
getIBANList(body,isExport:boolean=false){
  if(!isExport){
    return this.http.post(environment.baseApiUrl+'master/vendor/bank/list',body)
  }
  else{
    return this.http.post(environment.baseApiUrl+'master/vendor/bank/list',body,{observe:'response',responseType:'blob'})
  }
  }
  addIBAN(body:{}){
    return this.http.post(environment.baseApiUrl+'master/vendor/bank',body,);
   }
   updateIBANDetails(id,body:{}){
    return this.http.put(environment.baseApiUrl+'master/vendor/bank/'+id,body,);
   }
   deleteIBANDetailsById(id){
    return this.http.delete(environment.baseApiUrl+'master/vendor/bank/'+id);
   }
getCountryList(){
  return this.http.get<any>(environment.baseApiUrl+'master/active/country/list');
}
getAnnouncementList(pageNumber,pageSize,orderType,orderBy){
  return this.http.get<any>(environment.baseApiUrl+'announcement/admin/list?page='+pageNumber+'&page_size='+pageSize+'&order_type='+orderType+'&order_by='+orderBy);
}
getAppConfigurationData(){
  return this.http.get<any>(environment.baseApiUrl+'admin/app-config');
}
updateDescription(appConfigId:number,body:{}){
  return this.http.put(environment.baseApiUrl+'admin/app-config/description/'+appConfigId,body);
}
updateAppConfiguration(body:{}){  
  return this.http.post(environment.baseApiUrl+'admin/app-config/update',body);
}
postAnnoucementList(body:{}){
  return this.http.post(environment.baseApiUrl+'announcement',body);
}
getAnnouncementDetailsById(id:number){
  return this.http.get<any>(environment.baseApiUrl+'announcement/'+id);
}
deleteAnnoucementList(id:number){
  return this.http.delete(environment.baseApiUrl+'announcement/'+id);
}
}
