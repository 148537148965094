import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { toArray } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EdiService {
  baseModuleUrl="edi/";
  private http:HttpClient
    constructor(_http:HttpClient) {
      this.http=_http;
     }
 getTradingPartnerRules(id){
      return this.http.get<any>(environment.baseApiUrl+this.baseModuleUrl+'partner/rule/'+id);
 }
  getTradingPartnerSetupList(body,isExport:boolean=false){
    if(!isExport){
      return this.http.post(environment.baseApiUrl+this.baseModuleUrl+'partner/setup/list',body);
    }
   else{
    return this.http.post(environment.baseApiUrl+this.baseModuleUrl+'partner/setup/list',body,{observe:'response',responseType:'blob'});
   }
  }
  getPartnerConnectivityById(id){
    return this.http.get<any>(environment.baseApiUrl+this.baseModuleUrl+'partner/connectivity/'+id);
  }
  getEInvoiceSetupList(body,isExport:boolean=false){
    if(!isExport){
      return this.http.post(environment.baseApiUrl+'einvoice/partner/setup/list',body);
    }
   else{
    return this.http.post(environment.baseApiUrl+'einvoice/partner/setup/list',body,{observe:'response',responseType:'blob'});
   }
  }
  getEInvoiceSetupSortDropdownList(){
    return this.http.get(environment.baseApiUrl+'einvoice/partner/setup/sort-info/list');
  }
  getCodeList(body,isExport:boolean=false){
    if(!isExport){
      return this.http.post(environment.baseApiUrl+this.baseModuleUrl+'conversion/code_list/list',body);
    }
    else{
      return this.http.post(environment.baseApiUrl+this.baseModuleUrl+'conversion/code_list/list',body,{observe:'response',responseType:'blob'});
    }
  }
  getCodeListLibrary(body,isExport:boolean=false){
    if(!isExport){
      return this.http.post(environment.baseApiUrl+this.baseModuleUrl+'conversion/list_library/list',body);
    }
    else{
      return this.http.post(environment.baseApiUrl+this.baseModuleUrl+'conversion/list_library/list',body,{observe:'response',responseType:'blob'});
    }
  }
  getConnectivitySetupList(body,isExport:boolean=false){
    if(!isExport){
      return this.http.post(environment.baseApiUrl+this.baseModuleUrl+'partner/connectivity/list',body);
    }
    else{
      return this.http.post(environment.baseApiUrl+this.baseModuleUrl+'partner/connectivity/list',body,{observe:'response',responseType:'blob'});
    }
  }
  getRuleList(ruleFilterBody:{},isExport:boolean=false){
    if(!isExport){
      return this.http.post(environment.baseApiUrl+this.baseModuleUrl+'partner/rule/list',ruleFilterBody);
    }
else{
  return this.http.post(environment.baseApiUrl+this.baseModuleUrl+'partner/rule/list',ruleFilterBody,{observe:'response',responseType:'blob'});
}
   }
   getEInvoiceXMLRuleList(ruleFilterBody:{},isExport:boolean=false){
    if(!isExport){
      return this.http.post(environment.baseApiUrl+'einvoice/rule/list',ruleFilterBody);
    }
else{
  return this.http.post(environment.baseApiUrl+'einvoice/rule/list',ruleFilterBody,{observe:'response',responseType:'blob'});
}
   }
   addCodeListLibrary(codelistBody:{}){
    return this.http.post(environment.baseApiUrl+this.baseModuleUrl+'conversion/list_library',codelistBody,);
   }
   addConnectivity(connectivityBody:{}){
    return this.http.post(environment.baseApiUrl+this.baseModuleUrl+'partner/connectivity',connectivityBody,);
   }
  updateCodeListLibrary(codelistId,codelistBody:{}){
    return this.http.put(environment.baseApiUrl+this.baseModuleUrl+'conversion/list_library/'+codelistId,codelistBody,);
   }
   addTradingPartnerSetup(tradingPartnerBody:{}){
    return this.http.post(environment.baseApiUrl+this.baseModuleUrl+'partner/setup',tradingPartnerBody);
   }
   addEInvoicingXMLSetup(tradingPartnerBody:{}){
    return this.http.post(environment.baseApiUrl+'einvoice/partner/setup',tradingPartnerBody);
   }
   updateEInvoicingXMLSetup(tradingPartnerBody:{},id){
    return this.http.put(environment.baseApiUrl+'einvoice/partner/setup/'+id,tradingPartnerBody);
   }
   saveUpdateTpRules(rulesBody:[]){
    var body={"listRecords":rulesBody}
    return this.http.post(environment.baseApiUrl+this.baseModuleUrl+'partner/rule',body);
   }
   saveUpdateEInvoiceRules(rulesBody:[]){
    var body={"listRecords":rulesBody}
    return this.http.post(environment.baseApiUrl+'einvoice/rule',body);
   }
   updateTradingPartnerSetup(tradingPartnerBody:{},setupId:number){
    return this.http.put(environment.baseApiUrl+this.baseModuleUrl+'partner/setup/'+setupId,tradingPartnerBody);
   }
   updatePartialPartnerSetup(tradingPartnerBody:{},setupId:number){
    return this.http.put(environment.baseApiUrl+this.baseModuleUrl+'partner/setup/partial/'+setupId,tradingPartnerBody);
   }
   addCodelist(tradingPartnerBody:{}){
    return this.http.post(environment.baseApiUrl+this.baseModuleUrl+'conversion/code_list',tradingPartnerBody);
   }
   deleteCodeListLibraryById(id){
    return this.http.delete(environment.baseApiUrl+this.baseModuleUrl+'conversion/list_library/'+id);
   }
   deleteTradingPartnerById(id){
    return this.http.delete(environment.baseApiUrl+this.baseModuleUrl+'partner/setup/'+id);
   }
   deleteByEInvoiceId(id){
    return this.http.delete(environment.baseApiUrl+'einvoice/partner/setup/'+id);
   }
   deleteRuleSetupList(id){
    return this.http.delete(environment.baseApiUrl+this.baseModuleUrl+'partner/rule/'+id);
   }
   deleteEInvoiceRuleSetupList(id){
    return this.http.delete(environment.baseApiUrl+'einvoice/rule/'+id);
   }
   deleteCodeList(id){
    return this.http.delete(environment.baseApiUrl+this.baseModuleUrl+'conversion/code_list/'+id);
   }
   deleteConnectivity(id){
    return this.http.delete(environment.baseApiUrl+this.baseModuleUrl+'partner/connectivity/'+id);
   }
   getCodeListLibraryDropdownList(){
    return this.http.get(environment.baseApiUrl+this.baseModuleUrl+'partner/setup/sort/list');
   }
   getCodeListFilterUniqueDropdownList(){
    return this.http.get(environment.baseApiUrl+this.baseModuleUrl+'conversion/list_library/unique_list');
   }
   getConnectivityUniqueList(){
    return this.http.get(environment.baseApiUrl+this.baseModuleUrl+'partner/connectivity/unique_list');
   }
   updateCodelist(codelistBody:{},codelistId:number){
    return this.http.put(environment.baseApiUrl+this.baseModuleUrl+'conversion/code_list/'+codelistId,codelistBody);
   }
}
