import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class POManagementService {
    baseModuleUrl = "master/po";
    public http: HttpClient
    constructor(_http: HttpClient) {
        this.http = _http
    }

    getAllPOList(body,isExport:boolean) {
        if(!isExport){
        return this.http.post(environment.baseApiUrl + this.baseModuleUrl + '/list' ,body );
        }
        else{
            return this.http.post(environment.baseApiUrl + this.baseModuleUrl + '/list' ,body,{observe:'response',responseType:'blob'} );
        }
    }
    addPO(body){
        return this.http.post(environment.baseApiUrl+this.baseModuleUrl ,body)
    }
    updatePO(body,id){
        return this.http.put(environment.baseApiUrl+this.baseModuleUrl+'/'+id,body);
    }
    deletePO(id){
        return this.http.delete(environment.baseApiUrl+this.baseModuleUrl+'/'+id);
    }
    addUpdateVendor(body: {}) {
        return this.http.post(environment.baseApiUrl + this.baseModuleUrl, body);
    }
    getPoFilterData() {
        return this.http.get(environment.baseApiUrl + this.baseModuleUrl + '/filter');
    }
}