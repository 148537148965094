import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'highlight'
})
export class HighlightPipe implements PipeTransform {
  escapeRegExp(text: string) {
    return text.replace(/[-[\]{}()*+?.,&\\^$|#\s]/g, '\\$&');
  }
  transform(text:string,search:string):string {
    search = search.replace(/&/g, '&amp;').replace(/</g, '&lt;');
    var pattern=search.replace(/[\-\[\]\/\{\}\(\)\*\+\?\\\\^\$\\]/g, "\\$&");
    pattern = pattern.split(' ').filter((t) => {
    return t.length > 0;
    }).join('|');
    const escapedSearchTerm = this.escapeRegExp(search);
    const regex = new RegExp(escapedSearchTerm, 'gi');
    return search?text.replace(regex, (match) => `<mark class='bg-green-200'>${match}</mark>`): text;
  }

}
