import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { IAssignableUserModel, IPreferenceModel } from 'src/app/models/selected-filters';
import * as moment from 'moment';
import { MessageService } from 'primeng/api';

@Injectable({
  providedIn: 'root'
})
export class FilterService {
  private filterData = new Subject<any>();
  private userListJSON = new Subject<any>();
  private searchData =new Subject<string>();
  private columnSettings =new Subject<any>();
  private preferenceData=new Subject<IPreferenceModel[]>();
  private clearFilterData=new Subject<any>();
  private clearSearchTermData=new Subject<any>();
  private exportClickEvent=new Subject<true>;
  private assignUserEvent=new Subject<IAssignableUserModel[]>();
  private messageService: MessageService;

  constructor( _messageService: MessageService) {
    this.messageService = _messageService;
  }

sendFilterData(data){
  this.filterData.next(data);
}
sendUserDetailsJSON(data){
  this.userListJSON.next(data);
}
sendAssignUserList(assignableUserList){
  this.assignUserEvent.next(assignableUserList)
}
sendPreferencesData(preferenceList){
  this.preferenceData.next(preferenceList)
}
sendSearchData(searchTerm){
  this.searchData.next(searchTerm);
}
sendColumnSettings(columns){
  this.columnSettings.next(columns);
}
sendClearFilterEvent(isRouteChanged=true){
 this.clearFilterData.next(isRouteChanged);
}
onClickSendExportFilterEvent(){
  this.exportClickEvent.next(true);
}


  //local search clear
  clearSearchData(){
    this.clearSearchTermData.next(null);
     }

getFilterData(): Observable<any> {
  return this.filterData.asObservable();
}
getUserDetailsJSON(): Observable<any> {
  return this.userListJSON.asObservable();
}
getColumnSettings(){
  return this.columnSettings.asObservable();
}
getAssignUserList(): Observable<any>{
  return this.assignUserEvent.asObservable();
}
getSearchData():Observable<String>{
  return this.searchData.asObservable();
}
getPreferenceData():Observable<IPreferenceModel[]>{
return this.preferenceData.asObservable();
}
getClearFilterEvent():Observable<true>{
  return this.clearFilterData.asObservable();
 }
 getClearSearchEvent():Observable<any>{
  return this.clearSearchTermData.asObservable();
 }
 getClickExportEvent():Observable<any>{
  return this.exportClickEvent.asObservable();
 }

  //search data through table
localSearch(searchTerm: String, dataToFilter: any[], schema: any[] = null) {
  const searchKeyword = searchTerm.trim().toLowerCase().replace(/[\n\t]/g, '').replace(/\s/g, '');;
  if (searchKeyword) {
    const filteredData = dataToFilter.filter(item => {
      for (const field of schema) {
        const propValue = item[field.field]?.toString().trim().toLowerCase().replace(/[\n\t]/g, '').replace(/\s/g, '');;
        if (propValue && (propValue.includes(searchKeyword) || propValue === searchKeyword)) {
          return true;
        }
      }
      return false;
    });

    if (filteredData.length === 0) {
      this.messageService.add({
        severity: 'error',
        key: 'emptyToster',
        summary: 'No Such Data Found',
        detail: 'No data found for this Search Term ' + searchKeyword
      });
    }

    return filteredData;
  } 
  else {
    this.clearSearchData();
    return dataToFilter;
  }
}


}
