import { Component } from '@angular/core';
import { AppComponent } from '../../../../app.component';
import { Router, Event, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';
import { Subscription, timer } from 'rxjs';
import { map, share } from "rxjs/operators";
import * as moment from 'moment';

@Component({
    selector: 'app-footer',
    templateUrl: './app.footer.component.html',
    styleUrls: ['./app.footer.component.scss']
})
export class AppFooterComponent {
    date: Date = new Date();
    currentRoute: string = "";
    constructor(public app: AppComponent, private router: Router) {
        this.router.events.subscribe((event: Event) => {
            if (event instanceof NavigationEnd) {
                let url = event.url;
                this.currentRoute = url.replace('/', '');
            }
        });
    }
     currentdate = new Date();

    copyRightYear=moment.utc().format("YYYY");
    dateTime =  moment.utc().format("dddd, MMMM Do YYYY");


time =  moment.utc(new Date()).format("HH:mm:ss ");
  rxTime = new Date();
  intervalId;
  subscription: Subscription;

  ngOnInit() {
    // Using Basic Interval
    this.intervalId = setInterval(() => {
      this.time =  moment.utc(new Date()).format("HH:mm:ss");
    }, 1000);

    // Using RxJS Timer
    this.subscription = timer(0, 1000)
      .pipe(
        map(() => new Date()),
        share()
      )
      .subscribe(time => {
        this.rxTime = time;
      });
  }

  ngOnDestroy() {
    clearInterval(this.intervalId);
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
