<div class="exception-body">
    <div class="exception-bottom">
        <div class="exception-wrapper">
            <svg width="313" height="274" viewBox="0 0 313 274" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path opacity="0.2"
                    d="M148.67 30.9629C187.458 35.3014 204.481 1.6741 242.923 6.0771C277.228 10.0043 300.888 34.4466 292.88 67.3643C285.768 96.5886 251.906 105.757 239.867 133.135C227.101 162.166 217.207 193.447 178.66 203.551C102.979 223.389 66.2144 130.264 61.0614 76.7751C59.0857 56.2439 55.6342 7.15769 82.4393 0.80318C109.414 -5.59972 124.905 28.3018 148.67 30.9629Z"
                    fill="#407BFF" />
                <path opacity="0.9"
                    d="M148.67 30.9629C187.458 35.3014 204.481 1.6741 242.923 6.0771C277.228 10.0043 300.888 34.4466 292.88 67.3643C285.768 96.5886 251.906 105.757 239.867 133.135C227.101 162.166 217.207 193.447 178.66 203.551C102.979 223.389 66.2144 130.264 61.0614 76.7751C59.0857 56.2439 55.6342 7.15769 82.4393 0.80318C109.414 -5.59972 124.905 28.3018 148.67 30.9629Z"
                    fill="#D2ECFC" />
                <path
                    d="M156.355 273.257C242.707 273.257 312.71 269.17 312.71 264.128C312.71 259.087 242.707 255 156.355 255C70.0025 255 0 259.087 0 264.128C0 269.17 70.0025 273.257 156.355 273.257Z"
                    fill="#F5F5F5" />
                <path d="M38.7146 91.6525L37.9103 91.7105L38.9815 106.582L39.7859 106.525L38.7146 91.6525Z"
                    fill="#0976CB" />
                <path d="M40.3324 114.202L39.5281 114.26L39.9313 119.858L40.7356 119.8L40.3324 114.202Z"
                    fill="#0976CB" />
                <path
                    d="M227.085 182.616H54.3842C52.4618 182.582 50.6203 181.837 49.2147 180.525C47.809 179.213 46.9388 177.427 46.7717 175.512L38.2883 57.6712C38.2094 56.7624 38.3217 55.8471 38.618 54.9844C38.9144 54.1216 39.3881 53.3305 40.0088 52.662C40.6294 51.9934 41.3833 51.4623 42.2217 51.1028C43.0601 50.7434 43.9645 50.5635 44.8767 50.5748H217.577C219.499 50.6092 221.339 51.353 222.745 52.6632C224.15 53.9734 225.021 55.7572 225.19 57.6712L233.673 175.512C233.752 176.421 233.64 177.337 233.344 178.2C233.047 179.063 232.574 179.855 231.953 180.524C231.333 181.193 230.579 181.725 229.741 182.085C228.902 182.446 227.998 182.627 227.085 182.616Z"
                    fill="#0976CB" />
                <path
                    d="M227.746 182.616H55.0455C53.1231 182.582 51.2816 181.837 49.8759 180.525C48.4703 179.213 47.6 177.427 47.433 175.512L38.9495 57.6712C38.8706 56.7624 38.983 55.8471 39.2793 54.9844C39.5756 54.1216 40.0494 53.3305 40.67 52.662C41.2907 51.9934 42.0445 51.4623 42.8829 51.1028C43.7213 50.7434 44.6257 50.5635 45.5379 50.5748H218.239C220.16 50.6092 222 51.353 223.406 52.6632C224.811 53.9734 225.682 55.7572 225.851 57.6712L234.335 175.512C234.415 176.421 234.303 177.337 234.008 178.201C233.712 179.065 233.238 179.857 232.617 180.527C231.997 181.196 231.242 181.728 230.403 182.088C229.564 182.448 228.659 182.628 227.746 182.616Z"
                    fill="#0976CB" />
                <path
                    d="M218.497 54.123H45.796H45.2154C40.8043 54.4859 41.7075 61.2033 46.1589 61.2033H219.15C223.609 61.2033 223.545 54.4859 219.077 54.123H218.497Z"
                    fill="white" />
                <path
                    d="M50.2957 57.6712C50.3126 57.8791 50.2858 58.0883 50.2169 58.2851C50.148 58.482 50.0386 58.6623 49.8957 58.8143C49.7529 58.9662 49.5797 59.0866 49.3875 59.1675C49.1952 59.2484 48.9882 59.2881 48.7796 59.284C48.3413 59.2779 47.9208 59.1097 47.5992 58.8119C47.2776 58.5141 47.0776 58.1077 47.0378 57.6712C47.0209 57.464 47.0475 57.2556 47.1159 57.0593C47.1843 56.8629 47.293 56.6831 47.435 56.5312C47.577 56.3794 47.7491 56.2589 47.9404 56.1774C48.1317 56.096 48.3379 56.0555 48.5457 56.0584C48.9854 56.0626 49.4079 56.2299 49.7312 56.5279C50.0546 56.8259 50.2557 57.2333 50.2957 57.6712Z"
                    fill="#0976CB" />
                <path
                    d="M55.8358 57.6712C55.8528 57.8791 55.8259 58.0883 55.7571 58.2851C55.6882 58.482 55.5788 58.6623 55.4359 58.8143C55.293 58.9662 55.1199 59.0866 54.9276 59.1675C54.7354 59.2484 54.5283 59.2881 54.3198 59.284C53.8815 59.2779 53.461 59.1097 53.1394 58.8119C52.8177 58.5141 52.6177 58.1077 52.5779 57.6712C52.561 57.464 52.5877 57.2556 52.6561 57.0593C52.7245 56.8629 52.8331 56.6831 52.9751 56.5312C53.1171 56.3794 53.2893 56.2589 53.4806 56.1774C53.6718 56.096 53.878 56.0555 54.0859 56.0584C54.5256 56.0626 54.9481 56.2299 55.2714 56.5279C55.5947 56.8259 55.7959 57.2333 55.8358 57.6712Z"
                    fill="#0976CB" />
                <path
                    d="M61.3678 57.6712C61.3847 57.8784 61.3581 58.0869 61.2896 58.2832C61.2212 58.4795 61.1126 58.6594 60.9706 58.8112C60.8286 58.9631 60.6564 59.0836 60.4651 59.165C60.2739 59.2464 60.0677 59.287 59.8598 59.284C59.421 59.2797 58.9995 59.1121 58.6775 58.8139C58.3555 58.5158 58.156 58.1084 58.118 57.6712C58.0998 57.4637 58.1255 57.2548 58.1935 57.0579C58.2614 56.861 58.37 56.6806 58.5123 56.5285C58.6545 56.3764 58.8272 56.2559 59.0191 56.1749C59.2109 56.0939 59.4177 56.0542 59.6259 56.0584C60.0642 56.0646 60.4847 56.2328 60.8064 56.5306C61.128 56.8284 61.328 57.2347 61.3678 57.6712Z"
                    fill="#0976CB" />
                <path
                    d="M223.166 170.859H57.9728C57.275 170.847 56.6063 170.577 56.0967 170.1C55.5871 169.623 55.2728 168.974 55.2149 168.278L48.2394 71.3721C48.2084 71.0396 48.2484 70.7043 48.3569 70.3884C48.4654 70.0725 48.6398 69.7833 48.8686 69.54C49.0974 69.2967 49.3754 69.1049 49.684 68.9773C49.9926 68.8496 50.3249 68.7891 50.6587 68.7997H215.892C216.589 68.8093 217.258 69.078 217.768 69.5535C218.277 70.0291 218.592 70.6774 218.65 71.3721L225.634 168.278C225.664 168.615 225.622 168.955 225.51 169.274C225.398 169.593 225.219 169.885 224.986 170.129C224.752 170.373 224.469 170.565 224.155 170.691C223.841 170.817 223.504 170.874 223.166 170.859Z"
                    fill="white" />
                <path d="M153.557 133.942L150.863 96.5886L142.718 91.6856H120.292L123.34 133.942H153.557Z"
                    fill="white" />
                <path
                    d="M153.557 134.329H123.34C123.24 134.331 123.143 134.294 123.07 134.227C122.996 134.159 122.951 134.065 122.945 133.966L119.905 91.7098C119.901 91.6567 119.909 91.6035 119.927 91.5535C119.945 91.5034 119.973 91.4578 120.01 91.4195C120.045 91.3796 120.089 91.3476 120.138 91.3254C120.186 91.3032 120.239 91.2913 120.292 91.2904H142.718C142.789 91.2929 142.858 91.3122 142.92 91.3469L151.065 96.2499C151.12 96.2813 151.166 96.3256 151.2 96.379C151.233 96.4324 151.253 96.4933 151.258 96.5563L153.936 133.909C153.941 133.964 153.935 134.019 153.917 134.07C153.898 134.122 153.869 134.169 153.831 134.208C153.796 134.245 153.753 134.276 153.706 134.296C153.659 134.317 153.608 134.328 153.557 134.329ZM123.719 133.522H153.129L150.484 96.7901L142.646 92.0727H120.711L123.719 133.522Z"
                    fill="#0976CB" />
                <path d="M150.863 96.5886L142.719 91.6856L145.71 98.1853L150.863 96.5886Z" fill="#EBEBEB" />
                <path
                    d="M145.71 98.5723C145.636 98.5719 145.563 98.5505 145.5 98.5106C145.438 98.4706 145.387 98.4138 145.355 98.3465L142.364 91.8469C142.329 91.7705 142.319 91.6852 142.334 91.6028C142.35 91.5204 142.392 91.4451 142.452 91.3872C142.516 91.3325 142.596 91.2992 142.68 91.292C142.764 91.2847 142.848 91.3039 142.92 91.3469L151.065 96.2499C151.131 96.2893 151.184 96.3467 151.218 96.4152C151.252 96.4838 151.266 96.5607 151.258 96.6369C151.248 96.7112 151.217 96.7809 151.168 96.838C151.12 96.8951 151.056 96.9373 150.984 96.9595L145.823 98.5723H145.71ZM143.606 92.6694L145.92 97.7095L149.952 96.4757L143.606 92.6694Z"
                    fill="#0976CB" />
                <path
                    d="M132.969 110.717C132.981 110.936 132.948 111.155 132.873 111.361C132.797 111.567 132.68 111.756 132.529 111.915C132.378 112.074 132.196 112.201 131.994 112.288C131.793 112.374 131.576 112.419 131.356 112.418C130.896 112.41 130.455 112.232 130.118 111.918C129.782 111.603 129.573 111.176 129.534 110.717C129.513 110.495 129.539 110.272 129.612 110.062C129.684 109.851 129.801 109.659 129.953 109.497C130.106 109.335 130.291 109.208 130.497 109.124C130.703 109.039 130.924 108.999 131.146 109.007C131.607 109.018 132.049 109.198 132.385 109.513C132.721 109.829 132.929 110.257 132.969 110.717Z"
                    fill="#0976CB" />
                <path
                    d="M144.017 110.717C144.036 110.938 144.009 111.16 143.936 111.37C143.863 111.579 143.746 111.77 143.594 111.931C143.441 112.092 143.256 112.219 143.051 112.303C142.846 112.387 142.625 112.426 142.404 112.418C141.943 112.41 141.501 112.232 141.163 111.918C140.825 111.604 140.615 111.176 140.573 110.717C140.56 110.497 140.592 110.277 140.667 110.07C140.742 109.863 140.859 109.674 141.01 109.513C141.161 109.353 141.344 109.226 141.546 109.139C141.748 109.052 141.966 109.007 142.186 109.007C142.649 109.014 143.093 109.192 143.432 109.509C143.77 109.825 143.979 110.255 144.017 110.717Z"
                    fill="#0976CB" />
                <path
                    d="M147.146 121.797C147.048 121.795 146.954 121.757 146.882 121.69C146.811 121.623 146.767 121.532 146.759 121.434C146.557 118.684 142.251 116.45 137.162 116.45C133.783 116.45 130.751 117.458 129.251 119.071C128.943 119.368 128.702 119.728 128.543 120.125C128.385 120.523 128.313 120.95 128.332 121.378C128.336 121.431 128.329 121.484 128.312 121.534C128.295 121.584 128.269 121.631 128.234 121.671C128.164 121.752 128.064 121.802 127.957 121.809C127.85 121.817 127.745 121.781 127.664 121.711C127.583 121.641 127.533 121.541 127.526 121.434C127.502 120.899 127.59 120.366 127.784 119.867C127.979 119.369 128.275 118.916 128.655 118.539C130.268 116.773 133.55 115.668 137.138 115.668C142.727 115.668 147.283 118.176 147.509 121.378C147.512 121.428 147.505 121.479 147.489 121.528C147.472 121.576 147.446 121.62 147.413 121.659C147.379 121.697 147.338 121.728 147.292 121.75C147.246 121.773 147.197 121.786 147.146 121.789V121.797Z"
                    fill="#0976CB" />
                <path
                    d="M126.655 107.265C126.556 107.263 126.462 107.226 126.389 107.161C126.315 107.087 126.274 106.987 126.274 106.882C126.274 106.778 126.315 106.678 126.389 106.604L128.106 104.757C128.18 104.687 128.278 104.647 128.38 104.647C128.483 104.647 128.581 104.687 128.655 104.757C128.691 104.793 128.719 104.836 128.739 104.883C128.758 104.93 128.768 104.981 128.768 105.032C128.768 105.083 128.758 105.133 128.739 105.18C128.719 105.227 128.691 105.27 128.655 105.306L126.937 107.153C126.9 107.189 126.856 107.218 126.807 107.238C126.759 107.257 126.707 107.267 126.655 107.265Z"
                    fill="#0976CB" />
                <path
                    d="M146.339 107.265C146.241 107.261 146.148 107.224 146.073 107.161L144.097 105.314C144.06 105.278 144.03 105.235 144.01 105.187C143.99 105.139 143.979 105.088 143.979 105.036C143.979 104.984 143.99 104.932 144.01 104.885C144.03 104.837 144.06 104.794 144.097 104.757C144.171 104.687 144.269 104.647 144.372 104.647C144.474 104.647 144.572 104.687 144.646 104.757L146.621 106.604C146.657 106.64 146.686 106.683 146.706 106.73C146.725 106.777 146.735 106.827 146.735 106.878C146.735 106.929 146.725 106.98 146.706 107.027C146.686 107.074 146.657 107.117 146.621 107.153C146.545 107.224 146.444 107.264 146.339 107.265Z"
                    fill="#0976CB" />
                <path
                    d="M117.857 142.288H119.679L122.308 145.788L122.058 142.288H123.897L124.348 148.61H122.486L119.873 145.134L120.123 148.61H118.284L117.857 142.288Z"
                    fill="#0976CB" />
                <path
                    d="M125.171 145.449C125.109 145.02 125.138 144.582 125.258 144.165C125.377 143.748 125.584 143.361 125.864 143.03C126.171 142.728 126.538 142.496 126.941 142.347C127.344 142.198 127.774 142.137 128.203 142.167C129.112 142.118 130.004 142.421 130.695 143.014C131.327 143.643 131.694 144.492 131.719 145.384C131.786 146 131.703 146.622 131.477 147.199C131.268 147.663 130.918 148.05 130.477 148.304C129.948 148.587 129.352 148.724 128.751 148.699C128.134 148.718 127.521 148.603 126.953 148.36C126.456 148.121 126.037 147.747 125.744 147.279C125.398 146.729 125.2 146.098 125.171 145.449ZM127.131 145.449C127.123 145.944 127.282 146.427 127.582 146.82C127.708 146.959 127.863 147.068 128.036 147.14C128.208 147.213 128.395 147.247 128.582 147.239C128.761 147.252 128.94 147.221 129.104 147.149C129.269 147.078 129.413 146.967 129.526 146.828C129.757 146.387 129.834 145.882 129.743 145.392C129.747 144.918 129.587 144.457 129.292 144.086C129.163 143.95 129.006 143.843 128.832 143.772C128.658 143.701 128.471 143.668 128.284 143.675C128.108 143.667 127.934 143.701 127.774 143.774C127.614 143.847 127.474 143.957 127.364 144.094C127.128 144.51 127.046 144.995 127.131 145.465V145.449Z"
                    fill="#0976CB" />
                <path
                    d="M135.509 142.288H138.412C138.891 142.273 139.368 142.352 139.815 142.522C140.17 142.67 140.484 142.899 140.735 143.191C140.988 143.487 141.183 143.829 141.307 144.199C141.438 144.596 141.519 145.008 141.549 145.425C141.611 145.952 141.57 146.486 141.428 146.997C141.317 147.351 141.12 147.673 140.856 147.933C140.635 148.166 140.357 148.339 140.049 148.433C139.674 148.541 139.286 148.601 138.896 148.61H136.001L135.509 142.288ZM137.565 143.723L137.815 147.175H138.291C138.585 147.194 138.88 147.147 139.154 147.038C139.335 146.933 139.468 146.762 139.525 146.562C139.617 146.204 139.639 145.831 139.589 145.465C139.605 144.973 139.454 144.49 139.162 144.094C139.012 143.959 138.836 143.856 138.645 143.792C138.453 143.728 138.25 143.705 138.049 143.723H137.565Z"
                    fill="#0976CB" />
                <path
                    d="M146.234 147.562H144.017L143.783 148.61H141.791L143.71 142.288H145.872L148.702 148.61H146.654L146.234 147.562ZM145.726 146.199L144.872 143.925L144.339 146.199H145.726Z"
                    fill="#0976CB" />
                <path
                    d="M147.783 142.288H153.718L153.831 143.852H151.839L152.178 148.61H150.226L149.887 143.852H147.896L147.783 142.288Z"
                    fill="#0976CB" />
                <path
                    d="M158.266 147.562H156.048L155.814 148.61H153.823L155.742 142.288H157.871L160.693 148.61H158.653L158.266 147.562ZM157.758 146.199L156.895 143.925L156.355 146.199H157.758Z"
                    fill="#0976CB" />
                <path
                    d="M239.971 68.0417C240.689 67.6788 241.584 67.2353 242.342 66.7756C243.1 66.3159 243.955 65.8724 244.762 65.3886C246.334 64.4128 247.931 63.4532 249.439 62.3887C252.467 60.3164 255.306 57.9794 257.922 55.4052C258.253 55.0988 258.543 54.7601 258.858 54.4295L259.317 53.9375L259.543 53.6956L259.656 53.5666C259.543 53.7843 259.656 53.7198 259.656 53.5102C259.727 53.2222 259.767 52.9276 259.777 52.6312C259.758 50.9165 259.561 49.2084 259.188 47.5346C258.47 43.9219 257.382 40.1963 256.309 36.5513L259.438 35.1805C261.413 38.6776 263.062 42.3487 264.365 46.1476C265.097 48.2006 265.563 50.3389 265.752 52.5102C265.802 53.1853 265.78 53.8637 265.688 54.5343C265.568 55.4825 265.232 56.3904 264.704 57.1874L264.567 57.3729L264.462 57.5019L264.341 57.6551L264.091 57.9615L263.591 58.5663C263.261 58.9615 262.938 59.3728 262.583 59.7518C259.825 62.812 256.749 65.5694 253.406 67.9772C251.793 69.1706 250.084 70.3157 248.334 71.356C247.471 71.8802 246.592 72.3802 245.697 72.8721C244.802 73.364 243.931 73.8156 242.899 74.2994L239.971 68.0417Z"
                    fill="#FFB573" />
                <path
                    d="M232.794 257.629C233.398 257.614 233.999 257.532 234.585 257.387C234.614 257.378 234.641 257.361 234.662 257.338C234.684 257.316 234.698 257.288 234.706 257.258C234.71 257.227 234.705 257.195 234.692 257.167C234.679 257.138 234.659 257.114 234.633 257.096C234.399 256.943 232.351 255.621 231.561 255.976C231.473 256.016 231.398 256.08 231.343 256.159C231.287 256.238 231.254 256.331 231.246 256.427C231.215 256.579 231.223 256.737 231.269 256.885C231.316 257.033 231.399 257.167 231.512 257.274C231.883 257.543 232.338 257.669 232.794 257.629ZM234.125 257.161C232.956 257.395 232.069 257.355 231.706 257.04C231.633 256.966 231.58 256.874 231.552 256.774C231.524 256.674 231.521 256.569 231.544 256.467C231.547 256.425 231.562 256.384 231.586 256.348C231.61 256.313 231.643 256.284 231.682 256.266C232.109 256.08 233.294 256.677 234.125 257.161Z"
                    fill="#EF9517" />
                <path
                    d="M234.577 257.387H234.657C234.681 257.372 234.701 257.352 234.715 257.328C234.729 257.304 234.737 257.277 234.738 257.25C234.738 257.161 234.738 255.218 233.996 254.572C233.907 254.487 233.8 254.423 233.683 254.385C233.565 254.347 233.441 254.337 233.319 254.355C233.191 254.356 233.068 254.4 232.97 254.481C232.872 254.562 232.804 254.674 232.778 254.798C232.625 255.605 233.851 257.016 234.496 257.387C234.522 257.394 234.55 257.394 234.577 257.387ZM233.423 254.653C233.555 254.653 233.681 254.702 233.778 254.79C234.204 255.417 234.423 256.161 234.407 256.919C233.762 256.403 232.996 255.306 233.093 254.847C233.093 254.774 233.149 254.677 233.351 254.653H233.423Z"
                    fill="#0976CB" />
                <path
                    d="M233.947 47.8733C233.141 51.9053 231.528 59.9694 234.31 62.6709C234.31 62.6709 233.214 66.703 225.77 66.703C217.577 66.703 221.859 62.6709 221.859 62.6709C226.327 61.6065 226.206 58.2921 225.432 55.1713L233.947 47.8733Z"
                    fill="#FFB573" />
                <path
                    d="M220.287 64.2837C219.005 64.4611 220.102 61.1307 220.618 60.7839C221.827 59.9775 237.439 58.8566 237.335 60.7839C237.27 61.5903 236.883 63.2031 236.206 63.7354C235.528 64.2676 231.512 62.6064 220.287 64.2837Z"
                    fill="#002B45" />
                <path
                    d="M222.835 63.1387C221.811 63.4854 221.908 60.1307 222.255 59.7275C223.061 58.7921 235.697 55.5503 236.068 57.3809C236.214 58.1873 236.286 59.8001 235.851 60.3727C235.415 60.9452 231.706 60.0017 222.835 63.1387Z"
                    fill="#002B45" />
                <path
                    d="M218.134 40.6478C218.083 40.65 218.033 40.6402 217.986 40.6192C217.94 40.5982 217.9 40.5666 217.868 40.5269C217.622 40.2095 217.304 39.9545 216.942 39.7822C216.579 39.6099 216.181 39.5252 215.779 39.535C215.737 39.5406 215.695 39.5377 215.654 39.5264C215.613 39.5151 215.575 39.4957 215.542 39.4694C215.509 39.443 215.481 39.4103 215.461 39.373C215.441 39.3358 215.428 39.2949 215.424 39.2527C215.418 39.1697 215.443 39.0872 215.496 39.0225C215.548 38.9579 215.624 38.9161 215.707 38.906C216.214 38.8801 216.721 38.9773 217.183 39.1893C217.645 39.4013 218.048 39.7219 218.36 40.1237C218.414 40.1891 218.44 40.2732 218.432 40.3577C218.425 40.4423 218.384 40.5204 218.319 40.5753C218.265 40.6146 218.201 40.6395 218.134 40.6478Z"
                    fill="#002B45" />
                <path
                    d="M216.569 44.5912C216.204 45.8777 215.661 47.1071 214.957 48.2443C215.252 48.4143 215.582 48.5176 215.922 48.5468C216.262 48.5761 216.604 48.5306 216.924 48.4136L216.569 44.5912Z"
                    fill="#FF5652" />
                <path
                    d="M216.964 43.6155C217.021 44.1557 216.771 44.6235 216.416 44.6557C216.061 44.688 215.731 44.2848 215.682 43.7364C215.634 43.1881 215.876 42.7284 216.223 42.6961C216.569 42.6639 216.908 43.0671 216.964 43.6155Z"
                    fill="#002B45" />
                <path d="M216.384 42.7123L215.045 42.4623C215.045 42.4623 215.812 43.4138 216.384 42.7123Z"
                    fill="#002B45" />
                <path d="M242.399 257.234H235.641L236.173 241.589H242.931L242.399 257.234Z" fill="#FFB573" />
                <path
                    d="M235.109 256.451H242.697C242.828 256.448 242.955 256.493 243.055 256.578C243.155 256.663 243.22 256.781 243.237 256.911L244.1 262.911C244.117 263.062 244.102 263.216 244.055 263.361C244.009 263.507 243.932 263.641 243.83 263.754C243.728 263.868 243.603 263.958 243.463 264.02C243.323 264.082 243.172 264.113 243.02 264.112C240.375 264.072 239.101 263.911 235.762 263.911C233.706 263.911 230.706 264.128 227.875 264.128C225.045 264.128 224.884 261.322 226.069 261.072C231.359 259.927 232.198 258.363 233.98 256.863C234.297 256.599 234.696 256.454 235.109 256.451Z"
                    fill="#002B45" />
                <g opacity="0.2">
                    <path d="M242.931 241.589H236.173L235.899 249.653H242.657L242.931 241.589Z" fill="black" />
                </g>
                <path
                    d="M215.521 72.0091C211.386 70.4013 207.335 68.5846 203.385 66.5659C199.304 64.5719 195.417 62.2044 191.772 59.4936C190.799 58.731 189.877 57.9039 189.014 57.018C188.789 56.776 188.563 56.5502 188.345 56.268C188.09 55.9795 187.853 55.6752 187.635 55.3568C187.041 54.4904 186.669 53.4903 186.555 52.4456C186.466 51.4552 186.601 50.4575 186.95 49.5264C187.23 48.778 187.612 48.0723 188.087 47.4297C188.866 46.3645 189.786 45.4097 190.821 44.5912C192.648 43.1362 194.621 41.8742 196.708 40.8252C197.708 40.293 198.724 39.8172 199.756 39.3737C200.788 38.9302 201.804 38.5108 202.909 38.1318L204.336 41.2365C200.82 43.4541 197.175 45.8976 194.498 48.6232C193.899 49.2304 193.371 49.9041 192.926 50.6312C192.538 51.2683 192.546 51.744 192.611 51.7037C192.676 51.6634 192.611 51.7037 192.724 51.7844L193.046 52.1311C193.167 52.2763 193.337 52.4214 193.49 52.5746C194.173 53.2033 194.898 53.7853 195.659 54.3165C197.324 55.4951 199.058 56.5723 200.853 57.5421C202.667 58.5582 204.538 59.5178 206.441 60.4371C210.247 62.2596 214.182 64.0095 218.045 65.6062L215.521 72.0091Z"
                    fill="#FFB573" />
                <path
                    d="M206.352 39.0673L207.465 36.6481L202.369 34.9143C202.369 34.9143 200.756 39.6802 202.667 41.8736C203.466 41.7479 204.221 41.4256 204.864 40.9357C205.507 40.4459 206.019 39.8038 206.352 39.0673Z"
                    fill="#FFB573" />
                <path d="M207.441 31.4145L203.384 30.2371L202.368 34.8821L207.441 36.6481V31.4145Z" fill="#FFB573" />
                <path
                    d="M259.857 36.882L260.446 31.2371L255.221 32.4951C255.221 32.4951 255.14 37.8013 257.906 38.6399L259.857 36.882Z"
                    fill="#FFB573" />
                <path d="M257.93 27.2614L254.519 28.8501L255.221 32.4547L260.446 31.2209L257.93 27.2614Z"
                    fill="#FFB573" />
                <path
                    d="M235.012 39.9544C235.262 46.6556 235.504 49.4861 232.48 53.2359C227.924 58.8808 219.642 57.7115 217.4 51.2199C215.384 45.3815 215.319 35.4062 221.577 32.116C222.953 31.3845 224.493 31.0167 226.051 31.0474C227.609 31.0782 229.133 31.5065 230.479 32.2917C231.825 33.0769 232.947 34.193 233.741 35.5341C234.534 36.8751 234.972 38.3967 235.012 39.9544Z"
                    fill="#FFB573" />
                <path
                    d="M232.093 53.2279C238.673 50.3813 242.995 44.3574 241.173 35.0192C239.423 26.0599 233.375 25.1729 230.875 27.0276C228.375 28.8824 222.142 26.1164 218.4 29.2614C211.949 34.7208 218.045 40.5511 221.238 44.0751C223.142 47.9943 225.641 56.01 232.093 53.2279Z"
                    fill="#002B45" />
                <path
                    d="M229.359 33.4144C230.162 34.4441 231.248 35.2162 232.485 35.6355C233.721 36.0547 235.054 36.1027 236.317 35.7735C237.581 35.4442 238.72 34.7522 239.595 33.7829C240.469 32.8136 241.041 31.6093 241.239 30.3188C241.437 29.0282 241.253 27.7079 240.71 26.5208C240.166 25.3337 239.287 24.3318 238.18 23.6388C237.074 22.9457 235.788 22.5918 234.483 22.6208C233.178 22.6498 231.909 23.0604 230.835 23.8019C229.365 24.8813 228.384 26.5001 228.107 28.3026C227.83 30.1052 228.281 31.9438 229.359 33.4144Z"
                    fill="#0976CB" />
                <path
                    d="M231.069 27.6566C230.125 22.2537 235.23 18.0926 242.068 20.1005C248.907 22.1085 245.294 28.1646 243.883 33.0031C242.471 37.8416 246.165 42.4542 247.697 38.7689C249.229 35.0836 246.649 33.9305 246.649 33.9305C246.649 33.9305 253.906 35.8094 247.165 43.9945C240.423 52.1795 234.319 43.1397 235.972 37.8093C237.31 33.5273 231.964 32.7612 231.069 27.6566Z"
                    fill="#002B45" />
                <path
                    d="M224.238 29.6968C221.118 28.084 215.836 26.7534 212.828 31.8177C211.408 34.2369 211.957 37.4626 211.957 37.4626L221.142 38.0674L224.238 29.6968Z"
                    fill="#002B45" />
                <path
                    d="M210.876 35.8659C210.822 35.8618 210.771 35.8368 210.735 35.7961C210.699 35.7555 210.68 35.7025 210.683 35.6482C210.683 35.5111 210.916 32.2693 212.876 30.2372C217.61 25.3181 223.158 29.4307 224.738 30.7613C224.772 30.7973 224.792 30.8445 224.793 30.894C224.795 30.9435 224.778 30.9918 224.746 31.0297C224.714 31.0676 224.669 31.0924 224.62 31.0994C224.571 31.1064 224.521 31.0952 224.48 31.0678C222.956 29.7614 217.65 25.8503 213.191 30.5113C211.336 32.4387 211.11 35.6401 211.11 35.6724C211.109 35.7011 211.102 35.7293 211.09 35.7552C211.077 35.781 211.059 35.8039 211.037 35.8222C211.015 35.8406 210.989 35.8539 210.961 35.8615C210.934 35.869 210.904 35.8705 210.876 35.8659Z"
                    fill="#002B45" />
                <path
                    d="M222.359 43.7848C222.323 45.0535 221.854 46.2715 221.029 47.2362C219.916 48.5426 218.61 47.8975 218.344 46.4298C218.077 45.1315 218.344 42.8816 219.747 42.0994C221.15 41.3172 222.376 42.3171 222.359 43.7848Z"
                    fill="#FFB573" />
                <path
                    d="M220.908 105.169C220.908 105.169 221.343 152.062 225.408 178.189C228.698 199.293 234.77 248.097 234.77 248.097H243.987C243.987 248.097 244.882 200.978 243.181 180.108C238.963 127.289 249.858 117.41 241.068 105.169H220.908Z"
                    fill="#002B45" />
                <path opacity="0.1"
                    d="M220.908 105.169C220.908 105.169 221.343 152.062 225.408 178.189C228.698 199.293 234.77 248.097 234.77 248.097H243.987C243.987 248.097 244.882 200.978 243.181 180.108C238.963 127.289 249.858 117.41 241.068 105.169H220.908Z"
                    fill="white" />
                <path opacity="0.3"
                    d="M225.754 126.442C228.98 140.594 226.408 162.884 224.642 172.698C222.771 157.795 221.827 138.482 221.359 124.313C223.029 121.62 224.609 121.442 225.754 126.442Z"
                    fill="black" />
                <path d="M233.512 248.274H245.245L245.858 244.162L233.448 243.742L233.512 248.274Z" fill="#0976CB" />
                <path
                    d="M236.859 65.5579C237.963 63.3645 243.899 61.9855 247.14 61.9936L249.56 72.7672C249.56 72.7672 243.108 82.3554 240.415 81.3393C237.246 80.1701 234.496 70.2915 236.859 65.5579Z"
                    fill="#EF9517" />
                <path
                    d="M230.02 245.637C230.034 245.614 230.041 245.588 230.041 245.561C230.041 245.534 230.034 245.507 230.02 245.484C230.005 245.453 229.981 245.428 229.95 245.412C229.92 245.396 229.885 245.39 229.851 245.395C229.512 245.452 226.529 245.92 226.125 246.774C226.082 246.852 226.06 246.939 226.06 247.028C226.06 247.117 226.082 247.205 226.125 247.282C226.195 247.41 226.294 247.518 226.415 247.599C226.536 247.679 226.675 247.728 226.819 247.742C227.795 247.839 229.238 246.484 229.964 245.661L230.02 245.637ZM226.44 246.879C226.714 246.428 228.262 246.008 229.44 245.791C228.375 246.871 227.44 247.476 226.891 247.403C226.797 247.394 226.706 247.361 226.627 247.307C226.548 247.254 226.484 247.182 226.44 247.097C226.422 247.066 226.413 247.031 226.413 246.996C226.413 246.961 226.422 246.926 226.44 246.895C226.44 246.895 226.432 246.887 226.44 246.879Z"
                    fill="#0976CB" />
                <path
                    d="M230.02 245.637C230.034 245.614 230.041 245.588 230.041 245.561C230.041 245.534 230.034 245.507 230.02 245.484C229.972 245.428 228.778 244.049 227.674 243.976C227.514 243.963 227.354 243.982 227.203 244.034C227.052 244.085 226.913 244.167 226.795 244.275C226.464 244.573 226.496 244.831 226.577 244.992C226.924 245.686 229.069 245.928 229.932 245.742C229.954 245.733 229.974 245.719 229.989 245.701C230.005 245.683 230.015 245.661 230.02 245.637ZM226.867 244.621C226.903 244.567 226.946 244.518 226.996 244.476C227.08 244.396 227.181 244.335 227.291 244.298C227.401 244.26 227.518 244.247 227.633 244.258C228.384 244.443 229.057 244.858 229.561 245.444C228.601 245.524 227.053 245.234 226.843 244.815C226.83 244.783 226.825 244.749 226.829 244.715C226.833 244.681 226.846 244.648 226.867 244.621Z"
                    fill="#0976CB" />
                <path
                    d="M237.06 242.775L230.883 245.524L228.133 239.791L224.537 232.291L224.109 231.42L230.286 228.67L230.762 229.654L234.246 236.92L237.06 242.775Z"
                    fill="#FFB573" />
                <path opacity="0.2" d="M234.246 236.92L228.133 239.791L224.537 232.291L230.762 229.654L234.246 236.92Z"
                    fill="black" />
                <path
                    d="M213.666 105.169C213.666 105.169 193.933 154.263 199.28 177.326C204.118 198.204 225.488 237.743 225.488 237.743L233.77 233.598C233.77 233.598 220.553 185.866 219.126 175.496C216.336 155.336 234.262 126.845 234.262 105.169H213.666Z"
                    fill="#002B45" />
                <path opacity="0.1"
                    d="M213.666 105.169C213.666 105.169 193.933 154.263 199.28 177.326C204.118 198.204 225.488 237.743 225.488 237.743L233.77 233.598C233.77 233.598 220.553 185.866 219.126 175.496C216.336 155.336 234.262 126.845 234.262 105.169H213.666Z"
                    fill="white" />
                <path
                    d="M229.786 244.662L235.915 240.202C236.019 240.121 236.148 240.081 236.279 240.09C236.41 240.099 236.533 240.156 236.625 240.251L240.859 244.597C240.961 244.711 241.039 244.844 241.086 244.99C241.134 245.135 241.15 245.289 241.134 245.441C241.118 245.594 241.07 245.741 240.994 245.873C240.917 246.005 240.813 246.12 240.689 246.21C238.52 247.726 237.399 248.355 234.706 250.315C233.044 251.524 229.738 254.193 227.448 255.863C225.158 257.532 223.416 255.355 224.222 254.46C227.835 250.427 228.593 247.928 229.157 245.662C229.245 245.265 229.467 244.912 229.786 244.662Z"
                    fill="#002B45" />
                <path d="M224.956 239.493L235.625 234.614L234.262 230.267L222.835 235.477L224.956 239.493Z"
                    fill="#0976CB" />
                <path
                    d="M218.9 65.0418C218.021 62.7515 210.312 60.2033 206.441 59.3969L204.739 72.0737C204.739 72.0737 211.062 81.1538 213.828 80.4039C217.102 79.5168 220.803 69.9528 218.9 65.0418Z"
                    fill="#EF9517" />
                <path
                    d="M210.441 64.4047C210.441 64.4047 207.215 65.5337 213.666 105.169H241.084C240.625 94.008 240.608 87.1213 245.923 64.2031C242.081 63.4104 238.186 62.8984 234.27 62.6709C230.125 62.3722 225.964 62.3722 221.819 62.6709C216.505 63.1387 210.441 64.4047 210.441 64.4047Z"
                    fill="#EF9517" />
                <path
                    d="M241.512 103.564L242.746 105.983C242.842 106.177 242.617 106.37 242.302 106.37H213.529C213.279 106.37 213.078 106.249 213.062 106.088L212.812 103.669C212.812 103.499 213.013 103.354 213.279 103.354H241.028C241.12 103.34 241.215 103.352 241.3 103.389C241.386 103.426 241.459 103.487 241.512 103.564Z"
                    fill="#EF9517" />
                <path opacity="0.3"
                    d="M241.512 103.564L242.746 105.983C242.842 106.177 242.617 106.37 242.302 106.37H213.529C213.279 106.37 213.078 106.249 213.062 106.088L212.812 103.669C212.812 103.499 213.013 103.354 213.279 103.354H241.028C241.12 103.34 241.215 103.352 241.3 103.389C241.386 103.426 241.459 103.487 241.512 103.564Z"
                    fill="white" />
                <path
                    d="M237.802 106.685H238.544C238.697 106.685 238.81 106.604 238.794 106.515L238.447 103.29C238.447 103.193 238.31 103.12 238.165 103.12H237.415C237.27 103.12 237.157 103.193 237.165 103.29L237.512 106.515C237.504 106.588 237.633 106.685 237.802 106.685Z"
                    fill="#002B45" />
                <path
                    d="M219.747 106.685H220.489C220.634 106.685 220.747 106.604 220.739 106.515L220.392 103.29C220.392 103.193 220.255 103.12 220.102 103.12H219.36C219.206 103.12 219.102 103.193 219.11 103.29L219.456 106.515C219.465 106.588 219.594 106.685 219.747 106.685Z"
                    fill="#002B45" />
            </svg>
            <h1 class="header">403 (Forbidden Error)</h1>
            <h3 class="subHeader">You are not permitted to see this</h3>
            <p class="content">The page you are trying to access has restricted access, <br>please connect with
                Admin.</p>
            <button pButton pRipple type="button" label="Back to Login" class="cozmos-secondary-button"
                (click)="redirectToLogin()"></button>
        </div>
    </div>
</div>