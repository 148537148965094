import { Component } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
@Component({
  selector: 'app-confirmed',
  templateUrl: './app.unconfirmed.component.html',
  styleUrls: ['./app.unconfirmed.component.scss']
})
export class AppUnconfirmedComponent {
  constructor(private authService: MsalService) {

  }
  async redirectToLogin() {
    await this.authService.logoutRedirect();
  }
}
