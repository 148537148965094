import { ErrorHandler, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppCommonModule } from './modules';
import { AppComponent } from './app.component';
import { AppNotfoundComponent } from './pages/notfound/app.notfound.component';
import { AppErrorComponent } from './pages/error/app.error.component';
import { AppAccessdeniedComponent } from './pages/access/app.accessdenied.component';
import { AppLoginComponent } from './pages/auth/login/app.login.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { ErrorhandlerService } from './modules/common/service/appinsight/errorhandler/errorhandler.service';
import { MsalService, MSAL_INSTANCE, MsalRedirectComponent, MsalBroadcastService, MsalGuard, MSAL_GUARD_CONFIG, MsalGuardConfiguration, MSAL_INTERCEPTOR_CONFIG, MsalInterceptor, MsalInterceptorConfiguration, ProtectedResourceScopes, MsalModule } from '@azure/msal-angular';
import { IPublicClientApplication, InteractionType, PublicClientApplication } from '@azure/msal-browser';
import { loginRequest, msalConfig, protectedResources, } from './auth-config';
import { ConfirmationService } from 'primeng/api';
import { AppUnconfirmedComponent } from './pages/unconfirmed/app.unconfirmed.component';
import { ServerDownComponent } from './pages/server-down/server-down.component';
import { HighchartsChartModule } from 'highcharts-angular';

function MsalInstanceFactory(): IPublicClientApplication {
    return new PublicClientApplication(msalConfig);
}

function MsalGuardConfigFactory(): MsalGuardConfiguration {
    return {
        interactionType: InteractionType.Redirect,
        authRequest: loginRequest
    };
}

function MsalInterceptorConfigFactory(): MsalInterceptorConfiguration {
        return {
            interactionType: InteractionType.Redirect,
            protectedResourceMap: protectedResources,
        }
}

@NgModule({
    imports: [
        BrowserModule,
        AppRoutingModule,
        FormsModule,
        HttpClientModule,
        BrowserAnimationsModule,
        ReactiveFormsModule,
        AppCommonModule,
        FontAwesomeModule,
        HighchartsChartModule
    ],
    declarations: [
        AppComponent,
        AppLoginComponent,
        AppNotfoundComponent,
        AppAccessdeniedComponent,
        AppErrorComponent,
        AppUnconfirmedComponent,
        ServerDownComponent
    ],
    providers: [
        { provide: ErrorHandler, useClass: ErrorhandlerService },
        { provide: MSAL_INSTANCE, useFactory: MsalInstanceFactory },
        { provide: MSAL_GUARD_CONFIG, useFactory: MsalGuardConfigFactory },
        { provide: HTTP_INTERCEPTORS, useClass: MsalInterceptor, multi: true },
        { provide: MSAL_INTERCEPTOR_CONFIG, useFactory: MsalInterceptorConfigFactory },
        MsalService,
        MsalBroadcastService,
        MsalGuard,
        ConfirmationService
    ],
    bootstrap: [AppComponent, MsalRedirectComponent],
    exports: [FormsModule]
})
export class AppModule {
}
