import { Injectable } from '@angular/core';
import {  MessageService } from 'primeng/api';
import {
  HttpResponse,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable()
export class ApiEmptyResponseInterceptor implements HttpInterceptor {

  constructor(private messageService: MessageService) {
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(tap((event: HttpEvent<any>) => {
      if (event instanceof HttpResponse) {
        if ((event.status == 200 && event.body == null)) {
          let serviceName: any = '';
          if (event.url) {
            serviceName = event.url.split('/').pop();
            serviceName = serviceName.match(/[A-Z][a-z]+/g);
            if (serviceName) {
              serviceName = serviceName.map((word) => { return word.toLowerCase() }).join(" ");
            }
          }
          this.messageService.add({ key: 'emptyToster', severity: 'info', summary: 'Info Message', detail: 'Request has no data for ' + serviceName + ' !' });
        }

      }
    }));
  }
}
