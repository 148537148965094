<div id="layout-config" class="layout-config " [ngClass]="{'layout-config-active': appMain.configActive}"
    (click)="appMain.onConfigClick($event)" *ngIf="(isExportAllowed() || ( router.url == '/user/document' || router.url == '/user/email-tracker') && privilegeList?.includes('UPLOAD_DOC_TRACKER_FILE'))">
    <a id="gridConfigButton" class="layout-config-button" href="javascript:;" pTooltip="Additional Options" tooltipPosition="left"
        class="layout-config-button fab-button" (click)="onConfigButtonClick($event)"  aria-label="Export Grid and Upload Files">
        <i class="pi pi-ellipsis-v fab-button-icon ellip"></i>
    </a>
    <div class="layout-config-content">

        <p-panelMenu [model]="items" styleClass="w-100"></p-panelMenu>
    </div>
</div>
<p-dialog styleClass="w-7" [draggable]="false" [resizable]="false" header="Upload Documents" [modal]="true"
    [(visible)]="displayDocuments" (onHide)="onHideFileUpload(fileUpload)">
    <div class="grid">
        <div class="col-5  pb-0 flex">
            <h6 class="radio-label">File Type :</h6>
            <div *ngFor="let category of fileTypeList" class="ml-2">
                <p-radioButton [inputId]="category?.key" [(ngModel)]="fileType" [value]="category?.key"
                    [disabled]="category?.readonly" (click)="onChangeUpdateAcceptCriteria(fileUpload)">
                </p-radioButton>
                <label class="ml-2" [for]="category?.key">{{category?.name}}</label>
            </div>
        </div>
        <p-divider layout="vertical" styleClass="min-h-0"></p-divider>
        <div class="col-5 pb-0 flex">
            <h6 class="radio-label">Invoice From :</h6>
            <div *ngFor="let category of invoiceFromList" class="ml-2 ">
                <p-radioButton [inputId]="category?.key" [(ngModel)]="invoiceFrom" [value]="category?.key">
                </p-radioButton>
                <label class="ml-2" [for]="category?.key">{{category?.name}}</label>
            </div>
        </div>
        <div class="col-5" *ngIf="showCountry">
            <h6 class="radio-label">Select Country :</h6>
            <p-dropdown [options]="countryList" appendTo="body" [filter]="true"
            optionLabel="countryName" optionValue="id" [(ngModel)]="country" (onChange)="onChangeCountry()"
            placeholder="Select Country" [showClear]="true">
        </p-dropdown>
        </div>
        <div class="col-12 my-1 py-1 flex align-items-center">
            <h6 class="my-0 mr-1">Is this a Velocity Document: </h6>
            <p-checkbox [(ngModel)]="isVelocityDocument" [binary]="true" inputId="binary"></p-checkbox>
        </div>
        <div class="col-12 pt-0">
            <p-fileUpload #fileUpload [fileLimit]="maxFileLimit" [disabled]="disableUpload" chooseLabel="" uploadLabel="" cancelLabel=""
                (onClear)="clearFile()" customUpload="true" (uploadHandler)="onUploading($event,fileUpload)"
                (onRemove)="handleFileRemove($event,fileUpload)" multiple="multiple" [accept]="acceptCriteria"
                [maxFileSize]="maxFileSize">
            </p-fileUpload>
        </div>
    </div>
</p-dialog>
<p-dialog styleClass="report" header="IBAN Report" [resizable]="false" [modal]="true" [draggable]="false" [(visible)]="showReportDownload" (onHide)="onHideIBANReport()">
    <b class="ml-1">Date Range<span class="requiredField"></span></b>
    <p-calendar appendTo="body" placeholder="Select Start and End Date" selectionMode="range" [(ngModel)]="invoiceDateRange" [showIcon]="true"
        showClear="true" showButtonBar="true" class="mt-1 ml-1" styleClass="w-12" >
    </p-calendar>
<div style="text-align: right;" class="mt-2">
    <button pButton pRipple type="button" label="CANCEL" (click)="showReportDownload=false"
    class=" p-button-text text-button"></button>
    <button pButton type="button" label="DOWNLOAD" (click)="onClickDownloadReport()"
        class="cozmos-primary-button ml-2"></button>
</div>
</p-dialog>

<p-toast position="top-center"></p-toast>