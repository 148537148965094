import { Component, OnInit } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';
import { LoggingService } from './modules/common/service/appinsight/logging.service';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { AuthenticationResult, EventMessage, EventType, InteractionStatus } from '@azure/msal-browser';
import { Subject, filter, takeUntil } from 'rxjs';
import { CommonService } from './modules/common/service/common/common.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    topbarTheme = 'dim';
    menuTheme = 'dim';
    layoutMode = 'light';
    menuMode = 'static';
    isRTL = false;
    inputStyle = 'outlined';
    ripple: boolean;
    private unscbscribe = new Subject<void>();
    isAuthenticated: boolean = false;
    activeUser: string | undefined = "unknown user";
    constructor(public loaderService:CommonService,private primengConfig: PrimeNGConfig, public loggingService: LoggingService, private msalSarervice: MsalService,private msalBroadcastService: MsalBroadcastService) { }

    ngOnInit() {
        this.primengConfig.ripple = true;

        this.msalBroadcastService.inProgress$
        .pipe(
            filter((status: InteractionStatus) => status === InteractionStatus.None),
            takeUntil(this.unscbscribe)
        )
        .subscribe(() => {
            //this.setAuthenticationStatus();
        });
      
        this.msalBroadcastService.msalSubject$
        .pipe(
            filter((message: EventMessage) => message.eventType === EventType.LOGIN_SUCCESS),
            takeUntil(this.unscbscribe)
        )
        .subscribe((message : EventMessage) =>{
            const authResult = message.payload as AuthenticationResult;
            this.msalSarervice.instance.setActiveAccount(authResult.account);
            this.setAuthenticationStatus();
        })
        
    }
    setAuthenticationStatus() {
        let activeAccount = this.msalSarervice.instance.getActiveAccount();

        if(!activeAccount && this.msalSarervice.instance.getAllAccounts().length > 0 )
        {
            activeAccount = this.msalSarervice.instance.getAllAccounts()[0];
        }

        this.isAuthenticated = !! activeAccount;

        if(activeAccount?.username != '')
        {
            this.activeUser = activeAccount?.username;
        }
        else
        {
            var name = this.msalSarervice.instance.getActiveAccount()?.idTokenClaims?.['given_name'];
            this.activeUser = name as string;
        }
    }
    get loaderEnabled() {
        return this.loaderService.loaderEnabled;
      }
    ngOnDestroy() {
        this.unscbscribe.next(undefined);
        this.unscbscribe.complete();
      }  
}
