import { Component, HostListener } from '@angular/core';
import { SubSink } from 'subsink';
import { AppComponent } from '../../../../app.component';
import { AppMainComponent } from '../../component/main-panel/app.main.component';
import { INavigationData, NavigationService } from '../../service';
import { MsalService } from '@azure/msal-angular';
import { CommonService } from '../../service/common/common.service';
import { debounceTime, fromEvent, interval, merge, Subscription, takeUntil, timer } from 'rxjs';
import { Settings } from '../../lib/app.constant';
import { environment } from 'src/environments/environment';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { FilterService } from '../../service/filter/filter.service';
import { MessageService } from 'primeng/api';
import { DocumentService } from '../../service/document/document.service';

@Component({
  selector: 'app-topbar',
  templateUrl: './app.topbar.component.html',
  styleUrls: ['./app.topbar.component.scss'],
  animations: [
    trigger('rotatedState', [
      state('default', style({ transform: 'rotate(0)' })),
      state('rotated', style({ transform: 'rotate(-360deg)' })),
      transition('rotated => default', animate('2000ms ease-out')),
      transition('default => rotated', animate('2000ms ease-in'))
    ])
  ]
})
export class AppTopBarComponent {
  isUnreadAnnouncement=true;
  environment = environment;
  searchForm = new FormGroup({
    searchTerm: new FormControl("", [Validators.required, Validators.pattern(Settings.RegexPattern.removeSpace)]),
  })
  showAnnouncement: boolean = false;
  timeoutId: any;
  private subscription = new SubSink();
  currentNav: INavigationData = null;
  public viewInfo: string = "Dashboard";
  public menuTitle: string = "";
  private pageMilestone: number = 0;
  public userDetails: any;
  rotateState: string = 'default';
  showReordering: boolean = false;
  private refreshService: CommonService;
  private filterService: FilterService;
  private messageService: MessageService;
  clearSearchEvent: Subscription;
  clickEventSubscription:Subscription;
  route: Router;
  logoClass: string;
  logoEnvironment: string;
  firstLetter: any;
  displaySearch: boolean = false;
  position: any;
  displayColSetting: boolean;
  schema: any[] = [];
  selectedColumns = []
  preferenceId: any;
  columnTemplateId: any;
  preferenceListColumnSettings: any;
  logoutConfirmation: boolean = false;
  filterListData: any;
  isFilter: boolean;
  announcementList: any;
  notificationList: any;
  notificationCount: any;
  queueCountObject: any={};
  emailInboxCount: any;
  documentTypeList: any;
  sftpFilesData: any=[];
  privilegeList: any;
  constructor(public appMain: AppMainComponent, public app: AppComponent,private documentService:DocumentService, private navigationService: NavigationService, private authService: MsalService, _refreshService: CommonService, _filterService: FilterService, _route: Router, _messageService: MessageService) {
    this.refreshService = _refreshService;
    this.route = _route;
    this.filterService = _filterService;
    this.messageService = _messageService;
  }
  ngOnInit() {
    this.getUserJSONDetails();
    this.subscription.add(
      this.navigationService.$currentNav.subscribe(
        (selectedNav: INavigationData) => {
          if (selectedNav) {
            this.getNotificationCount();
            this.getNotificationList();
            this.privilegeList=Settings?.CommonFunctions.getPrivilegeList();
            this.displaySearch = false;
            this.currentNav = selectedNav;
            switch (this.currentNav.routeType) {
              case 0: {
                this.viewInfo = "Dashboard ";
                this.menuTitle = "";
                this.pageMilestone = 1;
                break;
              }
              case 1: {
                this.viewInfo = "DOCUMENT TRACKER";
                this.menuTitle = "";
                this.pageMilestone = 2;
                this.getQueueCount();
                break;
              }
              case 2: {
                this.viewInfo = "DOCUMENT TRACKER";
                this.menuTitle = "";
                this.pageMilestone = 2;
                break;
              }
              case 3: {
                this.viewInfo = "USER PROFILE";
                this.menuTitle = "";
                break;
              }
              case 4: {
                this.viewInfo = "E-TRACKER";
                this.menuTitle = "";
                this.pageMilestone = 4;
                this.getInboxEmailCount();
                this.privilegeList=Settings?.CommonFunctions.getPrivilegeList();
                if(this.privilegeList?.includes('CONNECTIVITY_LIST_VIEW')){
                this.getSFTPFiles();
                }
                break;
              }
              case 70: {
                this.viewInfo = "EMAIL LOGS";
                this.menuTitle = "E-TRACKER";
                this.pageMilestone = 70;
                break;
              }
              case 5: {
                this.viewInfo = "E-TRACKER";
                this.menuTitle = "";
                this.pageMilestone = 5;
                break;
              }
              case 6: {
                this.viewInfo = "USER MANAGEMENT";
                this.menuTitle = "Administration";
                this.pageMilestone = 6;
                break;
              }
              case 7: {
                this.viewInfo = "ROLE MANAGEMENT";
                this.menuTitle = "Administration";
                this.pageMilestone = 7;
                break;
              }
              case 8: {
                this.viewInfo = "LEGAL ENTITY MANAGEMENT";
                this.menuTitle = "Master Data Management";
                this.pageMilestone = 8;
                break;
              }
              case 9: {
                this.viewInfo = "VENDOR MANAGEMENT";
                this.menuTitle = "Master Data Management";
                this.pageMilestone = 9;
                break;
              }
              case 10: {
                this.viewInfo = "VAT ALIAS";
                this.menuTitle = "Master Data Management";
                this.pageMilestone = 10;
                break;
              }
              case 11: {
                this.viewInfo = "NAME ALIAS";
                this.menuTitle = "Master Data Management";
                this.pageMilestone = 11;
                break;
              }
              case 12: {
                this.viewInfo = "CITY ALIAS";
                this.menuTitle = "Master Data Management";
                this.pageMilestone = 12;
                break;
              }
              case 13: {
                this.viewInfo = "AUTO EMAIL TEMPLATE";
                this.menuTitle = "Administration";
                this.pageMilestone = 13;
                break;
              }
              case 14: {
                this.viewInfo = "USER ACTIVITY LOG";
                this.menuTitle = "Administration";
                this.pageMilestone = 7;
                break;
              }
              case 15: {
                this.viewInfo = "AP INVOICE MANAGEMENT";
                this.menuTitle = "Master Data Management";
                this.pageMilestone = 15;
                break;
              }
              case 16: {
                this.viewInfo = "ANNOUNCEMENT";
                this.menuTitle = "Administration";
                this.pageMilestone = 16;
                break;
              }
              case 17: {
                this.viewInfo = "APPLICATION CONFIGURATION";
                this.menuTitle = "Administration";
                this.pageMilestone = 17;
                break;
              }
              case 51: {
                this.viewInfo = "PO MANAGEMENT";
                this.menuTitle = "Master Data Management";
                this.pageMilestone = 9;
                break;
              }
              case 52: {
                this.viewInfo = "CURRENCY MANAGEMENT";
                this.menuTitle = "Master Data Management";
                this.pageMilestone = 9;
                break;
              }
              case 53: {
                this.viewInfo = "CURRENCY ALIAS";
                this.menuTitle = "Master Data Management";
                this.pageMilestone = 9;
                break;
              }
              case 54: {
                this.viewInfo = "PO PREFIX MANAGEMENT";
                this.menuTitle = "Administration";
                this.pageMilestone = 9;
                break;
              }
              case 57: {
                this.viewInfo = "ZIP CODE ALIAS";
                this.menuTitle = "Master Data Management";
                this.pageMilestone = 9;
                break;
              }
              case 58: {
                this.viewInfo = "DOCUMENT ACTION MANAGEMENT";
                this.menuTitle = "Administration";
                this.pageMilestone = 9;
                break;
              }
              case 59: {
                this.viewInfo = "ERROR CODE LIST";
                this.menuTitle = "Administration";
                this.pageMilestone = 9;
                break;
              }
              case 60: {
                this.viewInfo = "LANGUAGE INTENT ALIAS";
                this.menuTitle = "Administration";
                this.pageMilestone = 9;
                break;
              }
              case 61: {
                this.viewInfo = "CUSTOM PROFILE LIST";
                this.menuTitle = "Administration";
                this.pageMilestone = 9;
                break;
              }
              case 62: {
                this.viewInfo = "TRADING PARTNER SETUP";
                this.menuTitle = "EDI Management";
                this.pageMilestone = 10;
                break;
              }
              case 63: {
                this.viewInfo = "RULES SETUP";
                this.menuTitle = "EDI Management";
                this.pageMilestone = 11;
                break;
              }
              case 64: {
                this.viewInfo = "CODELIST SETUP";
                this.menuTitle = "EDI Management";
                this.pageMilestone = 12;
                break;
              }
              // case 65: {
              //   this.viewInfo = "CONNECTIVITY";
              //   this.menuTitle = "EDI Management";
              //   this.pageMilestone = 13;
              //   break;
              // }

              case 65: {
                this.viewInfo = "CONNECTIVITY";
                this.menuTitle = "";
                this.pageMilestone = 13;
                break;
              }
              case 66: {
                this.viewInfo = "CODELIST LIBRARY";
                this.menuTitle = "EDI Management";
                this.pageMilestone = 14;
                break;
              }
              case 67: {
                this.viewInfo = "DATA CHANGE LOGGER";
                this.menuTitle = "Administration";
                this.pageMilestone = 67;
                break;
              }
              case 76: {
                this.viewInfo = "CRON JOB LOGGER";
                this.menuTitle = "Administration";
                this.pageMilestone = 76;
                break;
              }
              case 77: {
                this.viewInfo = "DMS EXCEPTION LOGGER";
                this.menuTitle = "Administration";
                this.pageMilestone = 77;
                break;
              }
              case 68: {
                this.viewInfo = "EMAIL TEMPLATE CONTROLLER";
                this.menuTitle = "Administration";
                this.pageMilestone = 68;
                break;
              }
              case 69: {
                this.viewInfo = "DOCUMENT TYPE MANAGEMENT";
                this.menuTitle = "Administration";
                this.pageMilestone = 68;
                break;
              }
              case 71: {
                this.viewInfo = "BLACKLIST EMAIL CONFIG";
                this.menuTitle = "Administration";
                this.pageMilestone = 71;
                break;
              }
              case 72: {
                this.viewInfo = "E-XML MANAGEMENT";
                this.menuTitle = "";
                this.pageMilestone = 72;
                break;
              }
              case 73: {
                this.viewInfo = "RULES SETUP";
                this.menuTitle = "E-XML Management";
                this.pageMilestone = 73;
                break;
              }
              case 74: {
                this.viewInfo = "MANUAL EMAIL TEMPLATE";
                this.menuTitle = "Administration";
                this.pageMilestone = 74;
                break;
              }
              case 75: {
                this.viewInfo = "EMAIL TEMPLATE USAGE";
                this.menuTitle = "Administration";
                this.pageMilestone = 74;
                break;
              }
            }
            this.getPreferenceId();
          }
        }
      ),
    );
    this.getDocumentType();
    this.getProfileDetails();
    this.clearSearchEvent = this.filterService.getClearSearchEvent().subscribe(() => {
      this.searchForm.reset();
      this.searchForm.controls['searchTerm'].setValue("");
    })
    this.getFilterEvent();
    this.schema = Object.assign(this.schema, Settings.GridConfiguration.docTrackerGridSchema)
    this.logoClass = Settings.EnvironmentType[environment.environmentType].toLowerCase();
    this.logoEnvironment = Settings.EnvironmentType[environment.environmentType];
    this.initTimer();
  }
  onClickShowMails(action){
    this.route.navigate([]).then(result => { window.open(`user/email-tracker/email-log/${action}`, '_blank'); });
  }
  getFirstLetter(first, last) {
    const str = first + " " + last
    const firstLetters = str
      .split(' ')
      .map(word => word[0])
      .join('');
    return firstLetters;
  }

  onClickNavigateToProfilePage() {
    this.route.navigate(['user/profile/overview']);
  }
  refreshGrid() {
    // Code to refresh the grid goes here
    this.sendRefreshClickEvent();
    this.resetTimer();
  }
  resetTimer() {
    clearTimeout(this.timeoutId);
    this.timeoutId = setTimeout(() => this.refreshGrid(), Settings.AutoRefreshTime.refreshTimeInMilliSeconds); // 30 seconds
  }
  displayColSettingDialog() {
    this.displayColSetting = true;
  }
  displaySearchDialog(pos) {
    this.position = pos;
    this.displaySearch = true;
  }
  savecolumnsettings() {
    var templateBody = {
      "preferenceType": this.preferenceId,
      "isShared": false,
      "id": this.columnTemplateId,
      "userPreferenceSetting": this.schema
    }
    this.refreshService.createFilterTemplate(templateBody).subscribe({
      next: (res: any) => {
        // this.columnTemplateId=res?.id;
        this.refreshService.getColumnSettings(this.preferenceId).subscribe({
          next: (res: any) => {
            this.columnTemplateId = res?.results?.[0]?.id;
          }
        })
        this.selectedColumns = []
        this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Column Settings Updated Successfully' });
        this.displayColSetting = false;
        for (let i = 0; i < this.schema?.length; i++) {
          if (this.schema[i]?.checked == true) {
            this.selectedColumns.push(this.schema[i])
          }
        }
        this.filterService.sendColumnSettings(this.selectedColumns);
      },
      error: () => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Failed to Update Column Settings' });
      }
    })
  }
  resetColumnSettings() {
    this.schema = [];
    this.schema = Object.assign(this.schema, Settings.GridConfiguration.docTrackerGridSchema)
    for (let i = 0; i < this.schema?.length; i++) {
      this.schema[i].checked = true
    }
  }
  cancelColumnSettingChanges() {
    this.displayColSetting = false;
  }
  @HostListener('window:keydown', ['$event'])
  @HostListener('window:mousemove', ['$event'])
  @HostListener('window:mousedown', ['$event'])
  @HostListener('window:touchstart', ['$event'])
  @HostListener('window:touchmove', ['$event'])
  @HostListener('window:visibilitychange')
  onActivity() {
    this.resetTimer();
  }

  initTimer() {
    this.onActivity();
  }

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    // Check if the shortcut key combination is pressed
    if (event.ctrlKey && event.key === 'r') {
      event.preventDefault(); // Prevent default browser behavior (refreshing the page)
      this.sendRefreshClickEvent(); // Call your function
    }
  }
  getNotificationCount(){
    this.refreshService.getNotificationCount().subscribe({
      next:(res)=>{
        this.notificationCount=res?.count;
      },
      error:()=>{
        
      }
    })
  }
  getNotificationList(){
    this.refreshService.getNotificationList().subscribe({
      next:(res)=>{
       this.notificationList=res?.results;
       this.notificationList=this.notificationList.map((el:any)=>{
        el.notificationType=Settings.NotificationType[el?.notificationTypeId];
        return el;
       })
      },
      error:()=>{
        
      }
    })
  }
  getQueueCount(){
    this.refreshService.getQueueCount().subscribe({
      next:(res)=>{
        this.queueCountObject=res;
      },
      error:()=>{
        
      }
    })
  }
  onClickNavigateToNotification(id="all",notificationType=null,action=null){
   var body={
      "keys":id,
      "clear":false
    }
    this.refreshService.postNotificationRead(body).subscribe({
      next:(res)=>{
        if(action){
          if(action.includes(",")){
           var actionArray= action?.split(",")
            if(notificationType?.includes('DOCTRACKER'))
            this.filterService.sendFilterData([this.route.url, {
              transId: actionArray,isFilter:true}])
          }
          else{
            if(notificationType?.includes('ETRACKER')){
              window.open('user/email-tracker/view/'+action);
            }
            if(notificationType?.includes('DOCTRACKER'))
        window.open('user/document/view/'+action);
      }
          }   
      this.getNotificationCount();
      },
      error:()=>{
        
      }
    })
  }
  onClickClearNotification(id="all"){
    var body={
      "keys":id,
      "clear":true
    }
    this.refreshService.postNotificationRead(body).subscribe({
      next:(res)=>{
      this.getNotificationCount();
      this.getNotificationList();
      },
      error:()=>{
        
      }
    })
  }
  rotate() {
    this.rotateState = (this.rotateState === 'default' ? 'rotated' : 'default');
  }
  sendRefreshClickEvent() {
    this.rotate();
    this.getNotificationList();
    this.getNotificationCount();
    if(this.currentNav.route=='user/document'){
      this.getQueueCount();
    }
    if(this.currentNav.route=='user/email-tracker'){
      this.getInboxEmailCount();
    }
    this.refreshService.onClickSendRefreshEvent(this.currentNav.route);
  }
  getInboxEmailCount(){
    this.refreshService.getInboxEmailCount().subscribe({
      next:(res)=>{
        this.emailInboxCount=res?.results;
      },
      error:()=>{
        
      }
    })
  }
  getSFTPFiles(){
    this.refreshService.getSFTPFiles().subscribe({
      next:(res)=>{
        this.sftpFilesData=res?.results;
        // this.emailInboxCount=res;
      },
      error:()=>{
        
      }
    })
  }
  openHandbook(){
    this.messageService.add({
      severity: 'success',
      key: 'emptyToster',
      summary: 'Please Wait',
      detail: 'Cozmos Handbook will open shortly in a new tab.'
    });
   this.refreshService.getCozmosHandbook().subscribe({
  next:(res)=>{  
  Settings.CommonFunctions.previewFile(res?.fileData,"cozmosHandbook.pdf");
  },
  error:()=>{
    this.messageService.add({
      severity: 'error',
      key: 'emptyToster',
      summary: 'No File View',
      detail: 'Due to some reason, Cozmos Handbook View Failed. Please Try Again Later.'
    });
  }
})
  }
  
  sendFilterReset(){
    this.filterService.sendClearFilterEvent(false);
  }
  async logOut() {
    this.logoutConfirmation = false
    await this.authService.logoutRedirect();
  }
  onClickShowAnnouncement() {
    this.getAnnouncementList();
  }
  getAnnouncementList(){
    this.refreshService.getAnnouncementList(1,100).subscribe({
      next: (res: any) => {
          this.announcementList = res?.results;
          this.showAnnouncement = true;
          if(!this.isUnreadAnnouncement){
            this.getProfileDetails();
       }
      },
      error: (error) => {
      }
    })
  }
  getDocumentType() {
    this.documentService.getAllDocumentTypeList({},false).subscribe({
      next: (res: any) => {
  this.documentTypeList=res?.results;
  this.refreshService.onClickSendDocumentTypeEvent(this.documentTypeList);
  localStorage.setItem('documentTypes',JSON.stringify(this.documentTypeList));
      },
      error: (error) => {
          this.documentTypeList=[]
  }})
  }
  downloadPDF(base64Data: string,fileName:string): void {
    const binaryData = atob(base64Data);
    const arrayBuffer = new ArrayBuffer(binaryData.length);
    const byteArray = new Uint8Array(arrayBuffer);
    for (let i = 0; i < binaryData.length; i++) {
      byteArray[i] = binaryData.charCodeAt(i);
    }
    const blob = new Blob([arrayBuffer], { type: 'application/pdf' });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = fileName; // Specify the desired file name here
    link.click();
    // Clean up the URL object
    window.URL.revokeObjectURL(url);
  }
  getFilterEvent() {
    // Subscribe to filter data changes and update the filter list data and paginator page if the URL includes 'user/email-tracker'
    this.clickEventSubscription = this.filterService.getFilterData().subscribe((items) => {
   if(items?.[1]?.isFilter){
    this.isFilter=true;
   }
   else{
    this.isFilter=false;
   }
    })
  }
  searchTable() {
    if (this.searchForm.value.searchTerm) {
      this.filterService.sendSearchData(this.searchForm.value.searchTerm);
    }
    else {
      this.messageService.add({
        severity: 'error',
        key: 'emptyToster',
        summary: 'No Such Data Found',
        detail: 'Please enter valid Search Key'
      });
    }

  }
  hideShowReorderColumn(e) {
    this.showReordering = !this.showReordering;
  }
  clearTable() {
    this.searchForm.reset();
    this.searchForm.controls['searchTerm'].setValue("");
    this.filterService.sendSearchData(this.searchForm.value.searchTerm);
  }
  getUserJSONDetails() {
    this.refreshService.getUserListJSONDetails().subscribe({
      next: (res) => {
       this.filterService.sendUserDetailsJSON(res?.results);
       localStorage.setItem('userNameDetails',JSON.stringify(res?.results))
      },
      error: () => {

      }
    })
  }
  getProfileDetails() {
    this.refreshService.getProfileDetails().subscribe({
      next: (res) => {
        localStorage.setItem('userDetails', JSON.stringify(res));
        this.refreshService.onClickSendPrivelegeEvent(res);
        this.userDetails = res;
        this.isUnreadAnnouncement=res?.announcementReadFlag;
        this.firstLetter = this.getFirstLetter(this.userDetails?.firstName, this.userDetails?.lastName);
        this.getPreferenceId(res);
      },
      error: () => {

      }
    })
  }
  getPreferenceId(res = null) {
    var preferenceListFilterLocal = Settings.CommonFunctions.getPreferenceList('userDetails');
    if (!preferenceListFilterLocal) {
      var preferenceListFilter = res?.preferences;
      this.preferenceListColumnSettings = preferenceListFilter.filter((data) =>
        data.userPreferenceType.includes("COLUMN")
      );
      if (this.route.url.includes('/user/email-tracker') && !this.route.url.includes('/view'))
        this.checkPreferenceID("ETRACKER")
      if (this.route.url.includes('/user/document') && !this.route.url.includes('/view'))
        this.checkPreferenceID("DOC")
    }
    else {
      this.preferenceListColumnSettings = preferenceListFilterLocal.filter((data) =>
        data.userPreferenceType.includes("COLUMN")
      );
      if (this.route.url.includes('/user/email-tracker') && !this.route.url.includes('/view'))
        this.checkPreferenceID("ETRACKER")
      if (this.route.url.includes('/user/document') && !this.route.url.includes('/view'))
        this.checkPreferenceID("DOC")
    }
  }
  checkPreferenceID(routeConf) {
    this.preferenceListColumnSettings.forEach((items: any) => {
      if (items.userPreferenceType?.includes(routeConf)) {
        this.preferenceId = items.id;
      }
    })
    this.getColumnSettings();
  }
  getColumnSettings() {
    this.selectedColumns = [];
    if (this.preferenceId == Settings.PreferenceType.DOCTRACKER_COLUMN) {
      this.refreshService.getColumnSettings(this.preferenceId).subscribe({
        next: (res: any) => {
          if (res?.results?.length <= 0) {
            this.columnTemplateId = 0;
            this.selectedColumns = Object.assign(this.selectedColumns, Settings.GridConfiguration.docTrackerGridSchema)
            var templateBody = {
              "preferenceType": this.preferenceId,
              "isShared": false,
              "id": this.columnTemplateId,
              "userPreferenceSetting": this.selectedColumns
            }
            this.refreshService.createFilterTemplate(templateBody).subscribe({
              next: (res: any) => {
                this.selectedColumns = [];
                for (let i = 0; i < this.schema?.length; i++) {
                  if (this.schema[i]?.checked == true) {
                    this.selectedColumns.push(this.schema[i])
                  }
                }
              }
            })
          }
          else {
            this.columnTemplateId = res?.results?.[0]?.id;
            this.selectedColumns = [];
            this.schema = res?.results?.[0]?.userPreferenceSetting;
            for (let i = 0; i < res?.results?.[0]?.userPreferenceSetting?.length; i++) {
              if (res?.results?.[0]?.userPreferenceSetting[i]?.checked == true) {
                this.selectedColumns.push(res?.results?.[0]?.userPreferenceSetting[i])
              }
            }
          }
          this.filterService.sendColumnSettings(this.selectedColumns);
        },
        error: (error: any) => {
          this.messageService.add({
            severity: 'error',
            life: 3000,
            summary: 'Error Occurred',
            detail: 'Something Went Wrong , Please Try Again Later'
          });
        }
      })
    }
  }

  ngOnDestroy() {
    if (this.clearSearchEvent) {
      this.clearSearchEvent.unsubscribe();
    }
    if(this.clickEventSubscription){
      this.clickEventSubscription.unsubscribe();
    }
  }

  // -------------------check/uncheck grid headers in column setting------------
  unCheckSchema(e, col) {
    if (e.checked == true) {
      for (let i = 0; i < this.schema?.length; i++) {
        if (col.header == this.schema[i].header) {
          this.schema[i].checked = true
          break
        }
      }
    } else {
      for (let i = 0; i < this.schema?.length; i++) {
        if (col.header === this.schema[i].header) {
          this.schema[i].checked = false
          break
        }
      }
    }
  }
}
