import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpResponse
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { MessageService } from 'primeng/api';
import { Router } from '@angular/router';
@Injectable()
export class ApiErrorInterceptor implements HttpInterceptor {
  private messageService: MessageService;
  private router: Router;
  constructor(_messageService: MessageService, _router: Router) {
    this.messageService = _messageService;
    this.router = _router;
  }
  once = false;
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(tap((event: HttpEvent<any>) => {
      if (event instanceof HttpResponse) {

      }
    },
      (err: any) => {
        if (err.status == 401) {
          this.router.navigateByUrl('/access')
        }
        if (err.status == 403) {
          window.location.href = '/error'
        }
        if(err.status==500 || err.status===503){
          window.location.href = '/server-down'
        }
      }));
  }
}
