<router-outlet></router-outlet>
<section  *ngIf="loaderEnabled "  class="dots-container">
    <div class="dot"></div>
    <div class="dot"></div>
    <div class="dot"></div>
    <div class="dot"></div>
     <div class="dot"></div>
    </section>
    <p-confirmDialog appendTo="body" header="Confirmation" styleClass="w-7" acceptButtonStyleClass="cozmos-primary-button" rejectButtonStyleClass="cozmos-secondary-button"></p-confirmDialog>
<p-toast position="top-center" key="emptyToster"></p-toast>
<svg xmlns="http://www.w3.org/2000/svg" style="position:absolute; top: -9999em">
    <defs>
        <marker id='head' orient='auto' markerWidth='4' markerHeight='8' refX='4' refY='4'>
            <path d='M0,0V8L4,4Z' fill='black' />
        </marker>
        <marker id='tail' orient='auto' markerWidth='4' markerHeight='8' refX='1' refY='4'>
            <path d='M4,0V8L0,4Z' fill='black' />
        </marker>
    </defs>
</svg>

