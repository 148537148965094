import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { StorageService } from '../storage/storage.service';
import { environment } from 'src/environments/environment';
import { Settings } from '../../lib/app.constant';

@Injectable({
  providedIn: 'root'
})
export class ChatGptService {
  apiPath:String='/cozBot'
  constructor(protected http: HttpClient, protected storageService: StorageService) {
  }

  chatGptResponse(body: any = {}) {
    let finalUrl=environment.cozGptUrl;
    return this.http.post<any>(finalUrl, body);
  }
}
