import { ErrorHandler, Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { LoggingService } from '../logging.service';

@Injectable({
  providedIn: 'root'
})
export class ErrorhandlerService extends ErrorHandler {

  constructor(public loggingService: LoggingService) {
    super();
}

handleError(error: Error) {
  if(environment?.appInsights?.instrumentationKey){
    this.loggingService.logException(error); // Manually log exception
  }
}
}
