import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class LegalEntityManagementService {
    baseModuleUrl = "master/legal-entity";
    public http: HttpClient
    constructor(_http: HttpClient) {
        this.http = _http
    }

    getAllLegalEntityList(body: {},isExport:boolean=false) {
        if(!isExport){
        return this.http.post(environment.baseApiUrl + this.baseModuleUrl + '/list', body);
        }
        else{
            return this.http.post(environment.baseApiUrl + this.baseModuleUrl + '/list', body,{observe:'response',responseType:'blob'});
        }
    }
    addUpdateLe(body: {}) {
        return this.http.post(environment.baseApiUrl + this.baseModuleUrl, body);
    }
}