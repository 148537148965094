import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class VendorManagementService {
    baseModuleUrl = "master/vendor";
    public http: HttpClient
    constructor(_http: HttpClient) {
        this.http = _http
    }
    getVendorLeAssociation(body:{}){
        return this.http.post(environment.baseApiUrl+this.baseModuleUrl+'-le-data/list',body);
    }
    getAllVendorList(body: {},isExport:boolean=false) {
        if(!isExport){
        return this.http.post(environment.baseApiUrl + this.baseModuleUrl + '/list', body);
        }
        else{
            return this.http.post(environment.baseApiUrl + this.baseModuleUrl + '/list', body,{observe:'response',responseType:'blob'});
        }
    }
    getVendorSearchList(body: {}) {
        return this.http.post(environment.baseApiUrl + this.baseModuleUrl + '/search/list', body);
    }
    addUpdateVendor(body: {}) {
        return this.http.post(environment.baseApiUrl + this.baseModuleUrl, body);
    }
    updateRemark(id,body: {}) {
        return this.http.put(environment.baseApiUrl + 'master/vendor/remark/'+id, body);
    }
    getVendorFilterData() {
        return this.http.get(environment.baseApiUrl + this.baseModuleUrl + '/filter');
    }
}